// Local vars

$grid-gutter: 15px;

// Main styles

.block.related-posts {
    .block-title {
        margin-bottom: 30px;
    }

    .related-post-list {
        padding: 0;
        overflow: hidden;
        margin: -1px 0 0 0;
        list-style: none;

        li {
            box-sizing: border-box;
            margin-bottom: 0;

            h5.post-title{
                margin: 0;
                font-weight: normal;
                a {
                    display: block;
                    text-decoration: none;
                    -webkit-transition: .2s;
                    -moz-transition: .2s;
                    -ms-transition: .2s;
                    -o-transition: .2s;
                    transition: .2s;
                    font-size: 14px;
                    line-height: $post-title-height;
                    color: $post-title-color;
                    border-top: solid 1px $post-title-border;

                    &:hover {
                        background: $post-title-color-hover;
                        padding-left: 15px;
                    }
                }
            }
        }

        @media (min-width: 767px) {
            display: flex;
            -webkit-display: flex;
            flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            margin-left: -$grid-gutter;

            li {
                padding-left: $grid-gutter;
                flex-basis: 50%;
                -webkit-flex-basis: 50%;
            }
        }

        @media (min-width: 1200px) {
            li {
                flex-basis: 33.333%;
                -webkit-flex-basis: 33.333%;

                h5.post-title a {
                    font-size: $post-title-size;
                }
            }
        }
    }

    .related-post-grid {
        padding: 0;
        margin: 0;

        &:not(.owl-carousel){

            @media (min-width: 768px) {
                @include make-flex;
            }

            .inner {
                width: 33.33%;
                float: left;
            }

            @media (max-width: 767px) {
                .inner:nth-child(3n+1) {
                    clear: both;
                }
            }

            @media (max-width: 479px) {
                .inner {
                    width: 50%;

                    &:nth-child(3n+1) {
                        clear: none;
                    }

                    &:nth-child(2n+1) {
                        clear: both;
                    }
                }
            }
        }

        .inner {
            padding: 0 15px;
        }

        .post-image {
            display: block;
            margin-bottom: 10px;
        }

        @media (min-width: 768px) {
            li {
                flex-basis: 20%;
                -webkit-flex-basis: 20%;
                float: none;
            }
        }
    }
}