/*
 * 	Featured Product by TemplateMonster 2016
 *	v1.0.0
 */

// import
@import '_variables.scss';
@import '_mixins.scss';

/* Main Styles */

.block.widget.featured {
    .products-grid .product-items{
        .product-item-photo {
            display: block;
            text-align: center;
            line-height: 0;
        }

        .owl-stage {
            padding-left: 5px !important;

            @media (max-width: 767px) {
                padding-right: 5px !important;
            }
        }
    }

    .block-content .products-grid .product-items {
        margin:0 -15px;

        .product-item {
            margin-left: 0;
            margin-right: 0;
            padding:0 15px;

            @media (max-width: 767px) {
                padding: 0 9px;
            }
        }
    }
    &.featured-product {
        .block-content .products-grid .product-items {
            .product-item {
                @media (max-width: $screen-xl-min - 1) {
                    width: 33.333% !important;
                    max-width: 33.333% !important;
                }
                @media (max-width: $screen-sm-min - 1) {
                    width: 50% !important;
                    max-width: 50% !important;
                }
                @media (max-width: $screen-xs-min - 1) {
                    width: 100% !important;
                    max-width: 100% !important;
                }
            }
        }
    }
    &.many-f-products {
        .block-content .products-grid .product-items {
            .product-item {
                @include flex-basis(auto);

                @media (max-width: $screen-xxl-min - 1) {
                    width: 25% !important;
                    max-width: 25% !important;
                }
                @media (max-width: $screen-xl-min - 1) {
                    width: 25% !important;
                    max-width: 25% !important;
                }
                @media (max-width: $screen-lg-min - 1) {
                    width: 33.333% !important;
                    max-width: 33.333% !important;
                }
                @media (max-width: $screen-sm-min - 1) {
                    width: 50% !important;
                    max-width: 50% !important;
                }
                @media (max-width: $screen-xs-min - 1) {
                    width: 100% !important;
                    max-width: 100% !important;
                }
            }
        }
    }
}


// Carousel and Tabs
.block.featured {

    overflow: visible;
    .block-content .products-grid {
        .product-items.owl-carousel{
            margin: 0;

            .owl-wrapper-outer {
                padding: 10px 0 0;
            }
        }
    }

    &[role="tablist"]{
        .block-title{
            display: inline-block;
            width: auto;
            margin-right: 15px;

            strong {
                margin-bottom: 0;
                cursor: pointer;
            }

            &:before {
                display: none;
            }
            &:after {

            }

            &.active {
                strong {
                    color: #ccc;
                }
            }
        }
        .block-content {
          float: right;
          margin-left: -100%;
          padding: 3rem 0 0;
          width: 100%;
        }

        @media (max-width: 767px) {
            .block-title {
                display: block;
                width: 100%;
            }
            .block-content {
                float: none;
                margin-left: 0;
            }
        }
    }


    [role="tablist"]{
        text-align: right;

        .category-title{
            display: inline-block;
            width: auto;
            padding: 0;
            margin:0 0 20px;

            strong {
                margin-bottom: 0;
                padding-left: 15px;
                cursor: pointer;
                font-size: 18px;
                font-weight: normal;
            }

            &:before {
                display: none;
            }
            &:after {

            }

            &.active {
                strong {
                    color: #ccc;
                }
            }
        }
        .category-content {
            text-align: left;
            float: right;
            margin-left: -100%;
            padding: 70px 0 0;
            width: 100%;
        }

        @media (max-width: 767px) {
            .category-title {
                display: block;
                width: 100%;

            }
            .category-content{
                float: none;
                margin-left: 0;
                padding-top: 20px;
            }
        }
    }
}
