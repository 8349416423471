@import '_variables.scss';
@import '_blog-listing.scss';
@import '_blog-widget.scss';
@import '_blog-post.scss';
@import '_blog-comments.scss';
@import '_blog-related-posts.scss';
@import '_blog-sidebar.scss';

.post-relatedproducts {
    margin: 0 0 30px;
}

.related-posts {
    .owl-item:hover {
        box-shadow: none;
    }
}

.posts-list-wrapper .toolbar-top .toolbar-posts{
    @include make-flex;
    @include justify-content(space-between);

    .limiter{
        @include make-flex;
        @include justify-content(space-between);
        margin-bottom: 0;

        @media (min-width: $screen-sm-min){
            @include order(2);
        }
    }


    .sorter{
        @include make-flex;
        @include justify-content(space-between);
        margin-bottom: 0;

        @media (min-width: $screen-sm-min){
            @include order(1);
        }

        .sorter-label{
            padding-right: $indent-small;
        }
    }

    .control{
        text-align: center;
    }
}

.tm-blog-index-category {
    .page-title-wrapper {
        margin-bottom: 10px;
    }

    .breadcrumbs {
        text-align: center;

        .items {
            background: none;
            margin: 0 0 40px 0;
            padding: 0 10px 20px 10px;
            border-bottom: 1px solid #b4b4b4;
        }

        .item:after {
            content: '/';
        }
    }
}

.block-posts-list .post-items{
    a.post-link{
        color: $default;
        text-transform: capitalize;
        &:hover{
            color: $primary;
        }
    }
    .post-data{
        @include make-flex;
        @include justify-content(space-between);
        time{
            display: inline-block;
        }

        .post-comments a{
            color: $default;
            &:hover{
                color: $primary;
            }
        }
    }
}

.post-page{
    .post-text{
        img{
            margin-bottom: 1rem;
        }
    }
    .post-data{
        @include make-flex;
        @include justify-content(space-between);
        @include flex-wrap(wrap);
        time{
            display: inline-block;
        }

        .post-comments a{
            color: $default;
            &:hover{
                color: $primary;
            }
        }
    }

    .post-comments-block{
        .post-comments-title{
            font-size: 22px;
            line-height: 26px;
            font-weight: 700;
            color: $text-light;
        }

        .comment-add .post-comments-title{
            font-size: 18px;
            line-height: 22px;
            color: $primary;
        }

        .post-comment{
            background-color: transparent;
            border: 1px solid $border-color;
            border-radius: 4px;

            @media (min-width: $screen-sm-min){
                padding: 24px 30px;
            }
        }
        .comment-info{
            @include make-flex;
            @include justify-content(flex-start);
            border-bottom: none;

            div{
                float: none;
            }
            h3{
                margin: 0 2em 0 0;
                @include order(1);
            }
            span{
                line-height: 2rem;
            }
            .post-data{
                @include order(2);
            }
        }
    }
}

.feature-home-blog {
    padding-bottom: 35px;

    .post-item {
        .post-image {
            margin-bottom: 15px; 
            overflow: hidden;

            a {
                display: block; 
                margin: 0;
                padding: 0; 

                img {
                    display: block;
                    width: 100%;
                    margin: 0;
                    padding:0;
                }
            }
        }

        .post-title {
            padding: 14px 0 0;

            h3 {
                font-size: 24px; 
                text-transform: uppercase; 
                color: #000;

                a {
                    color: #000; 
                }
            }
        }

        .post-short-content {
            word-break: break-word; 
            font-size: 20px; 
        }
    }
}

.blog-post-category {
    position: relative;

    .widget.blog-posts {
        padding-bottom: 30px; 
        overflow: hidden;

        .block-title{
            font-size: 24px; 
            text-transform: uppercase; 
            word-break: break-word; 
            margin-bottom: 10px; 
            font-weight: bold; 
        }

        .post-items  {
            display: block; 
            margin-left: 0; 
            margin-right: 0;
            width: 85%;

            .post-item  {
                width: 100%;
                display: block; 
                flex-basis: initial; 
                padding-left: 0; 
                padding-right: 0; 
                
                .item-wrapper {
                    width: 100%;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }

                .post-image {
                    margin-bottom: 10px;
                }

                .post-title {
                    h3 {
                        font-weight: normal;
                        text-align: center; 
                        font-size: 16px;

                        a {
                            display: block; 
                            line-height: 1.1;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis; 
                        }
                    }
                }
            }
        }
    }

    .owl-carousel .owl-stage-outer {
        overflow: inherit;
    }

    .owl-carousel .owl-item {
        &:hover {
            box-shadow: none; 
        }
    }

    .owl-nav {
        right: auto;
        left: 0;
        width: calc(113% + 40px);

        .owl-prev, 
        .owl-next {
            font-size: 0;
            text-indent: -99999em; 
            overflow: hidden;
            min-height: 44px; 
            width: 44px; 
            margin-left: 0;

            &.disabled{
                opacity: 0;
                visibility: hidden;
            }
    
            &:before {
                line-height: 42px; 
                border-color: #c2c2c2;
                border-radius: 0; 
                text-align: left;
                text-indent: 5px;  
                font-size: 19px; 
                width: 44px; 
            }
        }

        .owl-next {
            &:before {
                text-indent: 20px;  
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    .blog-post-category .owl-nav {
        width: calc(113% + 50px);
    }
}

@media (max-width: $screen-sm-min) {
    .feature-home-blog {
        padding-bottom: 25px;
    
        .post-item {
            .post-image {
                margin-bottom: 15px; 
                max-height: 130px;
            }
    
            .post-title {
                padding-top: 7px;  
    
                h3 {
                    font-size: 20px;
                    line-height: 1.1;
                }
            }
    
            .post-short-content {
                font-size: 16px; 
            }
        }
    }
    
    .blog-post-category {  
        .widget.blog-posts {    
            .block-title{
                font-size: 20px;
                line-height: 1.1;
            }
    
            .post-items  {
                display: block; 
                margin-left: 0; 
                margin-right: 0; 
            }
        }
    
        .owl-nav {
            width: calc(113% + 30px);

            .owl-prev, 
            .owl-next {
                width: 20px;
                min-height: 20px;  
               
                &:before {
                    width: 20px;
                    line-height: 18px; 
                    font-size: 14px; 
                    text-indent: 0; 
                }
            }
    
            .owl-next {
                &:before {
                    text-indent: 5px;
                }
            }
        }
    }
}

@media (max-width: 599px) {
    .blog-post-category .owl-nav {
        width: calc(113% + 20px);
    }
}


@media (max-width: $screen-xs-min) {
    .blog-post-category .owl-nav {
        width: calc(113% + 15px);
    }
}
