//
//  Address Book
//  _____________________________________________

.block-addresses-list {
    .block-content {
        position: relative;

        @media (min-width: $screen-mob-min) {
            height: 100%;
        }

        li.item {
            .item-info {
                position: relative;
                @include account-nav-box-shadow;
            }
        }

        .item.actions {

            .action.edit,
            .action.delete{
                display: inline-block;
                font-size: 12px;
                color: $text-light;
                text-decoration: underline;

                @media (min-width: $screen-sm-min) {
                    font-size: 14px;
                }

                &:hover{
                    color: $primary;
                }
            }

            .action.delete{
                &:before {
                    content: '';
                    width: 1px;
                    height: 14px;
                    display: inline-block;
                    vertical-align: middle;
                    background-color: $text-lightest;
                    margin: 0 8px;
                }
            }
        }
    }

    .actions-toolbar {
        margin-top: 0;

        .primary {
            .action {
                min-width: 200px;
            }
        }
    }
}

.box-address-shipping {
    .box,
    &.box{
        background-color: $third-color;
    }
}

//
//  Address Edit
//  _____________________________________________

.form-address-edit {
    .fieldset .field {
        .select2-container {
            max-width: none;
            width: 100% !important;
        }
    }
}