//
//  Cart page
//  _____________________________________________

.checkout-cart-index .page-title-wrapper .page-title {
    margin-bottom: 35px;
    text-align: center;

    @media (min-width: $screen-md-min) {
        text-align: left;
        margin-bottom: 48px;
    }
}

.checkout-cart-index .form-cart {
    margin-bottom: 24px;
}

.checkout-methods-top-wrapper {
    text-align: center;
    margin: 0 0 25px 0;

    @media (min-width: $screen-md-min) {
        display: none
    }
}

.checkout.methods .item {
    margin-bottom: 20px;

    .primary.checkout {
        display: block;
        width: 100%;
        padding: 14px 20px;
        text-align: center;
        margin: auto;

        &:before {
            display: none;
        }

        &:after {
            @include font-icon__pseudo('\e939');
            padding-left: 4px;
        }

        @media (min-width: $screen-xs-min) {
            width: auto;
            max-width: 280px;
        }
        @media (min-width: $screen-md-min) {
            padding: 6px 20px;
        }
        @media (min-width: $screen-lg-min) {
            padding: 14px 20px;
            margin: 0;
            width: 100%;
            max-width: 100%;
        }
    }

    .multicheckout {
        text-align: center;
        display: block;
        width: 100%;
        @media (min-width: $screen-md-min) {
            display: inline-block;
            width: 100%;
        }
        @media (min-width: $screen-lg-min) {
            @include button-block;
            @include font-icon('\edc3');
            display: inline-block;
            padding: 6px 20px;
            &:before {
                padding-right: 4px;
            }
        }
        @media (max-width: $screen-md-min - 1) {
            @include button-block;
            @include font-icon('\edc3');
            display: inline-block;
            padding: 6px 20px;
            &:before {
                padding-right: 4px;
            }
        }
    }

    .paypal.checkout + .paypal.checkout {
        margin-top: 8px;
    }
}

#gift-options-cart {
    margin-bottom: 16px;

    .title {
        @include button-block;
        display: block;
        cursor: pointer;
        padding: 14px 20px;
        width: 100%;
        max-width: 280px;
        margin-right: auto;
        margin-left: auto;
        text-align: center;

        @media (min-width: $screen-mob-min) {
            display: inline-block;
            padding: 6px 20px;
            width: auto;
            max-width: none;
            margin-right: 0;
            margin-left: 0;
        }
        @media (min-width: $screen-lg-min) {
            padding: 14px 20px;
        }
    }

    .gift-message {
        padding: 16px 0;
    }

    .gift-options-title {
        font-weight: 700;
        color: $primary;
    }
}

#shopping-cart-table {
    border-collapse: collapse;

    th.col.item {
        @media (max-width: $screen-sm-min - 1) {
            display: none;
        }
    }

    thead {
        border-bottom: 1px solid #e8e8e8;

        th {
            border-color: #e8e8e8;
            font-size: 16px;
            font-weight: 700;
            border-bottom: none;
        }
    }

    tr th {
        text-align: center;

        &:first-child {
            text-align: left;
        }
    }

    td {
        vertical-align: middle;
        padding: 10px;
        border-color: #e8e8e8;

        &:first-child {
            padding-left: 0;
        }

        &.price,
        &.subtotal {
            font-size: 1rem;
            line-height: 1;
            font-weight: 700;
        }

        > div {
            margin: 0;
        }

        .control.qty {
            div.mage-error {
                margin-top: 5px;
            }

            @media (max-width: $screen-sm-min - 1) {
                input.qty {
                    float: right;
                }

                div.mage-error {
                    float: left;
                    max-width: 100px;
                    margin-top: 0;
                    margin-right: 5px;
                }
            }
        }

        input.qty {
            text-align: center;
            margin: 0 auto;
            padding: 10px 5px;
            max-width: 100px;
            min-width: 3em;

            &:disabled {
                color: $unactive;
            }
        }
    }

    .cart.item {

        &:not(.message) {
            border-bottom: 1px solid #e8e8e8;
        }

        .item-info {
            background: transparent;

            @media (max-width: $screen-sm-min - 1) {
                @include make-flex;
                @include flex-direction(column);
                @include align-items(center);
                margin-bottom: 16px;

                td:not(:first-child) {
                    @include make-flex;
                    @include justify-content(space-between);
                }

                td {
                    background: transparent !important;
                    border-right: none;
                    padding-right: 0;
                    max-width: 400px;

                    &:before {
                        border-bottom: none;
                        font-size: 16px;
                    }
                }
            }
        }

        td {
            text-align: center;

            &.col.item {
                text-align: center;

                @media (min-width: $screen-md-min) {
                    text-align: left;
                }

                @media (max-width: $screen-sm-min - 1) {
                    overflow: hidden;

                    &:before {
                        display: none;
                    }
                }
            }


            .product-item-photo {
                margin: 10px 10px 10px 0;
                padding-left: 0;
                width: 140px;

                @media (min-width: $screen-sm-min) {
                    float: left;
                    width: 100px;
                }
                @media (min-width: $screen-xl-min) {
                    margin-right: 20px;
                }
            }

            .product-image-photo {
                width: 140px;
                max-width: none;
                margin: 10px 0 15px 0;

                @media (min-width: $screen-sm-min) {
                    width: 100px;
                    margin: 0;
                }
            }
        }

        .product-item-name {
            font-size: 16px;
            font-weight: 700;
            word-break: break-word;
            word-wrap: break-word;
        }

        .product-item-description {
            font-size: 14px;
        }

        .item-actions {
            td {
                padding: 15px 0;
            }
        }

        .action {
            display: inline-block;
            margin-right: 10px;
            text-decoration: underline;
            font-size: 14px;

            /*
            **  remove Move to Whislist button from cart page **
            */
            &.action-towishlist {
                display: none;
            }

        }

        .actions-toolbar {
            @include make-flex;
            @include align-items(center);
            @include justify-content(center);
            position: relative;
            margin-top: 10px;

            @media (min-width: $screen-sm-min) {
                @include justify-content(flex-start);
            }

            @media (min-width: $screen-xl-min) {
                margin-top: 35px;
            }

            .action-gift {
                @include font-icon('\ec8b');

                span {
                    display: none;
                }
            }

            .action-towishlist {
                @include font-icon('\eca6');
            }

            .gift-content {
                display: none;
                background: $white;
                border: 1px solid #e8e8e8;
                padding: $indent-small/2;
                text-align: left;
                min-width: 300px;

                &._active {
                    display: block;
                    position: absolute;
                    z-index: 50;
                    top: 100%;
                    left: 0;
                }
            }
        }

        .col.subtotal {
            font-size: 16px;
            line-height: 1;
            white-space: nowrap;
        }

        .col.price {
            font-size: 18px;
            line-height: 1;
            white-space: nowrap;
        }

        .product-item-details {
            @media (min-width: $screen-sm-min) {
                width: calc(100% - 120px);
                text-align: left;
                margin-top: 10px;
                float: left;
            }
            @media (min-width: $screen-xl-min) {
                width: 50%;
            }

            .message {
                background-color: unset;
                color: #F04544;
                margin-top: 5px;
                font-size: 14px;
            }

            dl.item-options {
                font-size: 12px;
                @include make-flex;
                @include align-items(center);
                @include flex-wrap(wrap);

                dt,
                dd {
                    @include flex-basis(50%);
                    line-height: 1;
                    margin-bottom: 3px;
                }

                dt {
                    font-weight: 600;
                    text-transform: capitalize;
                    padding-right: 5px;

                    &:after {
                        content: ':';
                    }
                }

                dd {
                    padding-left: 0;
                    text-transform: capitalize;
                }
            }
        }
    }

    .item-actions {
        padding: 10px;
    }

    .item-control {
        position: relative;

        .action-gift {
            @include font-icon('\ec8b');

            span {
                display: none;
            }
        }

        .gift-content {
            display: none;
            background: $white;
            border: 1px solid #e8e8e8;
            padding: $indent-small/2;
            text-align: left;
            min-width: 250px;

            &._active {
                display: block;
                position: absolute;
                z-index: 50;
                top: 100%;
                left: 0;
            }
        }

        .action-edit,
        .action-delete {
            span {
                display: none;
            }

            font-size: 1.5rem;
            line-height: 1;
            background-color: transparent;
        }
    }
}

table.cart {
    width: 100%;

    > caption, .qty label {
        display: none;
    }

    td.price .price,
    td.subtotal .price {
        display: inline-block;
    }
}


//Cart actions

.cart-container .main.actions {
    .action {
        &.continue {
            display: none;

            @media (min-width: $screen-md-min) {
                display: block;
            }
        }
    }
}

.cart-container .main.actions, .checkout-action-bottom-wrapper {
    margin: 40px 0 0;
    @include make-flex;
    @include flex-direction(column);
    @include align-items(flex-start);

    @media (min-width: $screen-md-min) {
        @include flex-direction(row);
        @include justify-content(space-between);
        @include align-items(center);
    }

    .action {
        @include button-block;
        margin-bottom: 12px;
        padding: 14px 20px;
        width: 100%;
        max-width: 280px;
        margin-right: auto;
        margin-left: auto;
        text-align: center;

        &:before {
            padding-right: 4px;
        }

        @media (min-width: $screen-mob-min) {
            padding: 6px 20px;
            width: auto;
            max-width: none;
            margin-right: 3px;
            margin-left: 0;
        }
        @media (min-width: $screen-lg-min) {
            padding: 14px 20px;
            margin-bottom: 0;
        }

        &.continue {
            @include button-block__white;
            @include font-icon('\e94b');
            border: none;
            padding-left: 0;
        }

        &.clear {
            @include font-icon('\ea5d');
            padding: 14px 20px;
            width: 100%;
            max-width: 280px;
            margin-right: auto;
            margin-left: auto;
            text-align: center;

            &:before {
                padding-right: 4px;
                vertical-align: text-bottom;
            }

            @media (min-width: $screen-mob-min) {
                padding: 6px 20px;
                width: auto;
                max-width: none;
                margin-right: 3px;
                margin-left: 0;
            }
            @media (min-width: $screen-lg-min) {
                padding: 14px 20px;
                margin-left: 12px;
                margin-right: auto;
            }
        }

        &.update {
            @include font-icon('\e92d');
        }

        &.continue,
        &.update {
            &:before {
                vertical-align: text-bottom;
            }
        }
    }
}

.checkout-action-bottom-wrapper {
    margin-top: 0;

    @media (min-width: $screen-md-min) {
        display: none !important;
    }

    .cart-empty + & {
        display: none;
    }
}

//
//  Cart Summary
//  ---------------------------------------------


.cart-summary {
    padding: 15px;
    margin-bottom: 48px;
    background: #F8F8F8;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1);

    @media (min-width: $screen-mob-min) {
        padding: 15px;
        margin: 0 15px 15px 15px;
    }

    input, select {
        background: #EFEFEF !important;
        font-family: $default-font-family;
        padding: 10px 13px;
    }

    .field select {
        box-sizing: border-box;

        option {
            width: 100%;
            line-break: normal;
        }
    }

    > .title {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 24px;
        font-size: 24px;
        line-height: 22px;
        font-weight: 700;
        position: relative;
        width: 100%;
        text-align: center;
    }

    #discount-coupon-form {
        .actions-toolbar {
            text-align: right;

            button.action {
                min-width: 100px;
            }
        }
    }

    .block {
        &.reward,
        &.shipping,
        &.discount {
            margin-bottom: 16px;

            .content {
                margin-bottom: 20px;
                padding-bottom: 15px;
            }
        }

        .title {
            color: $primary;
            font-size: 16px;
            line-height: 26px;
            font-weight: 700;
            margin-bottom: 0;
            padding-right: 26px;
            position: relative;
            cursor: pointer;
            width: 100%;
        }

        .title[role="tab"] {
            @include font-icon('\e929');

            &:before {
                color: $text-light;
                position: absolute;
                top: 0;
                right: 0;
                width: 26px;
                height: 26px;
                border-radius: 2px;
                text-align: center;
                font-size: 14px;
                line-height: 1;
                padding: 6px 0;
            }

            &[aria-expanded="false"]:before {
                content: '\e926';
            }
        }

        .field.note {
            margin-bottom: 0;
        }
    }

    .cart-totals {
        text-align: left;

        caption {
            display: none;
        }

        th, td {
            padding-left: 0;
        }
    }

    .legend {
        display: none;
    }

    .cart-totals {
        padding-top: 32px;
        margin-bottom: 32px;
    }

    .table.totals {
        width: 100%;
        font-size: 14px;

        tbody tr {
            background: transparent;

            &.total-rules {
                font-size: 12px;
            }
        }

        tbody th,
        tbody td {
            padding: 5px;
            line-height: 22px;
        }

        tbody th {
            color: #838383;
        }

        tbody td {
            border-left: none;
            text-align: right;
            padding-right: 0;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
        }

        .grand.totals {
            td {
                color: $secondary-color;
            }
        }

        .total-rules {
            th {
                padding-left: 10px;

                &:before {
                    content: '-';
                }
            }

            th, td {
                font-size: 14px;
                color: $text-light;
            }
        }
    }

    // Shipping block
    #block-summary {

        div[name="shippingAddress.region"] {
            display: none;
        }

        .fieldset > .field > .label,
        .item-title {
            font-weight: 700;
            color: $text-light;
        }

        .fieldset > .field > .label {
            display: inline-block;
            margin-bottom: 4px;
        }

        .items.methods {
            dt {
                color: $text-light;
            }

            dd {
                padding-left: 0;
                font-style: normal;
            }
        }
    }

    .block.discount {

        .field {
            padding-bottom: 0;
        }

        .field > .label {
            font-weight: 700;
            color: $text-light;
        }

        button.apply {
            display: inline-block;
            margin-right: auto;
            margin-left: auto;
            text-align: center;
            padding: 14px 33px;

            &:before {
                padding-right: 4px;
            }

            @media (min-width: $screen-xs-min) and (max-width: $screen-md-min - 1) {
                width: auto;
            }
        }
    }

    .block.reward {
        .content {
            border-bottom: 1px solid #e8e8e8;
            padding-bottom: 30px;
        }

        .amrewards-discount-form {
            .field {
                padding-bottom: 0;
            }

            .actions-toolbar {
                text-align: right;

                button {
                    display: inline-block;
                    margin-right: auto;
                    margin-left: auto;
                    text-align: center;
                    padding: 14px 33px;
                }
            }
        }
    }
}

.cart-empty {
    text-align: center;
    font-size: 14px;
    min-height: 150px;

    @media (min-width: $screen-md-min) {
        text-align: left;
    }

    a {
        color: $secondary-color;
    }
}