.lookbook {
    .easypin-marker {
        background: rgba(0,0,0,0.2);
        color: #fff;
        border: 2px solid transparent;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        line-height: 30px;
        opacity: 0.2;
        text-align: center;

        @media (max-width: $screen-md-min - 1) {
            transform: scale(0.6);
        }

        &:hover {
            border-color: rgba(255,255,255,0.5);
            color: #fff;

            .easypin-popover {
                opacity: 1;
                visibility: visible;
            }
        }

        &:before {
            content: '';
            width: 12px;
            height: 12px;
            background: #fff;
            border-radius: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -6px 0 0 -6px;
            box-shadow: 0 1px 3px 0 rgba(0,0,0,0.4);
        }
    }

    .easypin-popover {
        display: block !important;
        opacity: 0;
        visibility: hidden;
        text-align: left;
        width: 180px;
        left: -77px;
        background: transparent;
        z-index: 20;

        .product-pin-wrap {
            line-height: normal;
            background: #fff;
            padding: 15px;
            position: relative;
            z-index: 1;

            a {
                text-decoration: none;
            }

            &:after {
                top: 100%;
                left: 50%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(255, 255, 255, 0);
                border-top-color: #fff;
                border-width: 10px;
                margin-left: -10px;
                -webkit-filter: drop-shadow(0 1px 2px black);
                filter: drop-shadow(0 1px 1px #dfdfdf);
            }
        }
    }

    .top-title {
        text-transform: uppercase;
        color: $red-color;
        font-weight: bold;
        font-size: 13px;
        position: relative;

        &:after {
            @include font-icon__pseudo('\e5cc', $material-icons);
            color: #000;
            position: absolute;
            right: -5px;
            font-size: 25px;
            font-weight: normal;
            top: -3px;
        }
    }

    .sub-title {
        font-size: 13px;
        color: #000;
        font-weight: bold;
        text-transform: uppercase;
    }

    .image-wrap {
        margin-bottom: 15px;
        display: none;
    }

    .title-wrap {
        text-transform: uppercase;
        color: #B4B4B4;
        font-size: 13px;
        font-weight: bold;
    }

    .title-wrap {
        h5 {
            font-size: 14px;
            font-weight: 400;
            color: #B4B4B4;
            margin: 0 0 5px;

            &:focus {
                text-decoration: none;
            }
        }
    }

    .price-label {
        display: none;
    }

    .price-box {
        > * {
            display: none;

            &.price-per-sqm {
                display: block;
            }
        }
        .price {
            color: #000;
            font-size: 18px;
            font-weight: bold;
        }

        .price-sqm-label,
        .product-item-unit {
            color: #000;
            font-size: 18px;
            font-weight: 500;
        }
    }
}

.main-product-pin {
    margin-bottom: 48px;
}