//
//  Variables
//  _____________________________________________

$acct-block-title-font-size: 18px;
$acct-mobile-font-size: 12px;

@mixin account-nav-box-shadow() {
    box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.16);
}

//
//  Account main
//  _____________________________________________

body.account {
    .page-main {
        @media (max-width: $screen-md-min - 1) {
            margin-top: 1rem;
        }
    }

    .account-nav-title.top .block-title,
    .page-title-wrapper {
        font-size: 24px;

        @media (min-width: $screen-md-min) {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }
    }

    .page-title-wrapper {
        margin-bottom: $indent-large - 1;

        h1.page-title {
            font-size: 32px;
            text-align: left;
            color: $secondary-color;
        }
    }

    //Block styling
    .block-title {
        position: relative;

        .action {
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}

.modal-popup.integration-account-welcome {
    .modal-title {
        border-bottom: none;
        text-align: center;
        margin-bottom: 0;
    }

    .action-close {
        display: none;
    }

    .modal-footer {
        text-align: center;
    }

    button.action {
        margin: auto;
        min-width: 150px;
        background: #f04544;
        color: #fff;
    }

    .modal-inner-wrap {
        max-width: 480px;
        border-radius: 10px;
    }
}

.integration-account-welcome-content {
    text-align: center;
    font-size: 16px;

    p {
        margin-bottom: 0;
    }
}

.popup-list-email {
    .modal-inner-wrap {
        max-width: 800px;
        border-radius: 10px;
    }

    &.modal-popup {
        .modal-title {
            color: #f04544;
            border-bottom: none;
        }

        .action-close {
            display: none;
        }
    }

    .modal-content,
    .modal-footer {
        margin-left: 30px;
        margin-right: 30px;

        @media (max-width: $screen-sm-min) {
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    .modal-footer {
        text-align: center;

        .action {
            margin: auto;
            min-width: 200px;
            background: #f04544;
            color: #fff;

            &.disabled {
                opacity: 0.5;
            }
        }
    }

    #list-email {
        li {
            padding: 13px 18px;
            font-size: 16px;
            background: #f5f5f5;
            margin-bottom: 10px;
            cursor: pointer;

            &:last-child {
                margin-bottom: 0;
            }

            &.active {
                background: #addadd;
            }
        }
    }
}

.integration-account-preloginregister {
    .column.main {
        .loading-mask {
            .loader {
                img {
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    transform: translate(0, -50%);
                }
            }
        }
    }
}

//
//  Account Navigation
//  _____________________________________________

.sidebar .account-navigation {
    margin-bottom: $indent-base;
    @media (min-width: $screen-mob-min) {
        padding-right: $indent-base;
        margin-bottom: $indent-large;
    }

    .block-title {
        font-size: 24px;
        padding-bottom: $indent-small;
        border-bottom: 1px solid $dark-border-color;
        margin-bottom: 20px;
    }

    a {
        display: inline-block;
        vertical-align: middle;
        color: $primary;

        &:hover {
            color: $secondary-color;
        }
    }

    li {
        font-size: $acct-block-title-font-size;
        font-weight: $font-weight__bold;
        text-transform: uppercase;
        margin-bottom: $indent-base;
    }

    li.current strong {
        color: $secondary-color;
    }
}

.account-nav-title.top {
    .block-title {
        padding-bottom: $indent-small;
        border-bottom: 1px solid $dark-border-color;
        margin-bottom: 20px;
    }
}

//
//  Common
//  _____________________________________________

.block-dashboard-orders,
.block-dashboard-info,
.block-dashboard-addresses,
.block-reviews-dashboard,
.form-edit-account,
.form-address-edit,
.block-addresses-default,
.block-addresses-list,
.custome-downloadable-products__wrapper,
.orders-history__wrapper,
.form-newsletter-manage,
.my-reviews-table__wrapper,
.account-billing-agreements__wrapper {
    height: 100%;

    + .block {
        margin-top: $indent-small;
    }

    .block-title,
    .legend {
        color: $text-dark;
        font-size: $acct-block-title-font-size;
        margin-bottom: 25px;
        text-transform: uppercase;

        > strong,
        > span {
            font-weight: $font-weight__bold;
        }

        a.action {
            font-size: $default-font-size;
            line-height: 1;
            font-weight: $font-weight__bold;
            top: 50%;
            margin-top: -7px;
            padding: 0;

            &:before {
                content: '';
                display: none;
            }

            span {
                display: block;
            }
        }
    }

    .table-caption {
        display: none;
    }
}

.block-dashboard-info,
.block-dashboard-addresses,
.block-addresses-default,
.block-addresses-list,
.block-order-details-view {
    .row {
        @media (min-width: $screen-md-min) {
            > div {
                margin-bottom: 0;
            }
            @include align-items(stretch);
        }
    }

    .block-content {
        @media (min-width: $screen-md-min) {
            margin-right: -$indent-small;
        }
    }

    .box,
    &.box {
        position: relative;
        padding: $indent-small;
        margin-bottom: $indent-base;
        @include account-nav-box-shadow;

        @media (min-width: $screen-sm-min) {
            margin-bottom: $indent-base;
            padding: $indent-base;
            height: calc(100% - 2rem);
        }

        @media (min-width: $screen-md-min) {
            margin-bottom: $indent-base + 1;
            margin-right: $indent-small;
            height: calc(100% - 3rem);
        }
    }

    .box-title {
        font-size: $acct-block-title-font-size;
        font-weight: $font-weight__bold;
        color: $primary;
        margin-bottom: 1rem;
        max-width: calc(100% - 20px);
    }

    .box-content {
        line-height: 2;
    }

    .box-actions {
        .action.edit {
            position: absolute;
            top: $indent-small;
            right: $indent-small;;
            background-color: transparent;
            font-size: $acct-mobile-font-size;
            color: $text-light;
            text-decoration: underline;

            @media (min-width: $screen-sm-min) {
                font-size: $default-font-size;
                top: $indent-base;;
            }

            &:hover {
                color: $primary;
            }
        }

        a:not(.edit) {
            @include button-block;
            @include button-block__primary;
        }
    }
}

.scgId-connect-members {
    display: flex;
    justify-content: center;

    .page-title {
        margin: 2rem 0;

        .title {
            display: inline-block;
            font-size: 2rem;
            vertical-align: bottom;
            @media (min-width: $screen-sm-min) {
                margin-right: -30px;
                padding-bottom: 10px;
            }
        }

        .logo-wrapper {
            display: inline-block;
            width: 190px;
            height: 114px;
        }
    }

    .connect-member-notice {
        text-align: center;
        margin-bottom: 3rem;
        max-width: 350px;
        width: 100%;
        font-size: $default-font-size;
        margin-left: auto;
        margin-right: auto;

        p {
            margin-bottom: 0;
        }

        @media (max-width: $screen-sm-min) {
            p {
                margin-bottom: 0.5rem;
                font-size: 12px;
            }
        }
    }

    .group {
        display: flex;
        flex-direction: column;
        width: fit-content;
        min-width: 350px;
        @media (max-width: $screen-sm-min) {
            min-width: 300px;
        }
        margin-left: auto;
        margin-right: auto;
    }

    .button-connect-member {
        height: 100%;
        border: 2px solid $third-color;
        font-weight: $font-weight__bold;
        position: relative;
        cursor: pointer;
        width: 100%;
        margin: auto;
        padding: 10px;
    }

    .modal-connect {
        margin-bottom: 2rem;
        display: flex;
        align-items: center;

        .label {
            display: flex;
            font-size: 16px;
            position: relative;
            margin-left: 50px;
            margin-right: 40px;
            width: calc(100% - 50px);
            @media (max-width: $screen-sm-min) {
                font-size: 12px;
            }
        }

        &.active:before {
            color: $accent;
            content: '\e86c';
        }
    }

    .modal-connect + .actions-toolbar {
        pointer-events: none;
    }

    .modal-create {
        margin-bottom: 2rem;
        display: flex;
        align-items: center;

        .label {
            position: relative;
            font-size: 16px;
            margin-left: 50px;
            display: flex;
            width: calc(100% - 50px);
            @media (max-width: $screen-sm-min) {
                font-size: 12px;
            }
        }

        &.active:before {
            color: $accent;
            content: '\e86c';
        }
    }

    .button-connect-member:before {
        content: '\e836';
        font-size: 16px;
        font-family: $material-icons;
        position: absolute;
        left: 20px;
        color: $third-color;
    }

    .actions-toolbar {
        text-align: center;

        .confirm-primary {
            line-height: 1rem;
            pointer-events: none;
            opacity: 0.5;
        }

        .confirm-primary.active {
            pointer-events: all;
            opacity: 1;
        }

        button.action.primary {
            width: 100%;
            margin: auto;

            span {
                font-size: 1.4rem;
            }

            @media (max-width: $screen-sm-min) {
                span {
                    font-size: 14px;
                }
            }
        }
    }
}

.scgId-connect-member-success {
    padding: 5rem 0 5rem;
    display: flex;
    justify-content: center;
    @media (max-width: $screen-sm-min) {
        padding: 0;
    }

    .actions-toolbar {
        a.create {
            color: $white;
            letter-spacing: 0.04em;
            font-weight: $font-weight__bold;
            font-size: 14px;
            background-color: $secondary-color;
            padding: 12px 20px 11px;
            display: inline-block;
            text-transform: uppercase;
            max-width: 350px;
            width: 100%;

            span {
                font-size: $default-font-size;
            }
        }
    }

    .connect-members.content {
        display: inline-block;
        width: 100%;
        max-width: 350px;
        text-align: center;
        margin-bottom: 1rem;

        p {
            font-weight: $font-weight__semibold;
            font-size: 1.2rem;
            line-height: 1.5rem;
            @media (max-width: $screen-sm-min) {
                font-size: $default-font-size;
            }
        }
    }

    .connect-members.notice.content {
        display: inline-block;
        width: 100%;
        max-width: 340px;

        p {
            font-weight: $default-font-weight;
        }
    }

    .connect-members.success.content {
        font-weight: $font-weight__bold;

        p {
            font-size: 2rem;
            line-height: 2.5rem;
            @media (max-width: $screen-sm-min) {
                font-size: 20px;
                line-height: 2rem;
            }
        }
    }

    .connect-members.success.content:before {
        @media (max-width: $screen-sm-min) {
            padding-bottom: 40px;
        }

        padding-bottom: 20px;
        display: inline-block;
        content: '\e86c';
        font-family: $material-icons;
        font-size: 4rem;
        color: #41c583;
    }
}

.scgId-connect-members-login-fail {
    .connect-members {
        margin: 50px 0 100px 0;

        &.content {
            text-align: center;

            &:before {
                content: '\e001';
                display: inline-block;
                font-family: $material-icons;
                font-size: 4rem;
                color: $accent;
                padding-bottom: 50px;
                @media (min-width: $screen-xs-min){
                    padding-bottom: 0;
                }
            }
        }

        .text span {
            font-size: 2rem;
            font-weight: $font-weight__bold;
        }

        .connect-fail.notice {
            padding-top: 10px;

            p {
                margin-bottom: 0.5rem;
                font-size: $default-font-size;
                font-weight: $font-weight__semibold;
                line-height: 2.5rem;

                a {
                    &.text {
                        color: #2288d2;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

}

.integration-account-emailconfirm {
    .scgId-email-confirm {
        .content.container {
            max-width: 534px;

            .title-h3 {
                margin-bottom: 32px;
                margin-top: 15px;
                font-size: 2.85rem;
                text-align: center;
                font-weight: $font-weight__semibold;
                line-height: 3rem;
            }

            .sub-title {
                font-size: 28px;
                font-weight: $font-weight__semibold;
                text-align: center;
                margin-bottom: 48px;
                margin-top: 1rem;

                @media (max-width: $screen-sm-min) {
                  line-height: 2rem;
                }
            }

            .form.form-email-confirm {
                font-weight: $font-weight__semibold;

                .scg-input-group {
                    .email-label {
                        font-size: $default-font-size;
                        font-weight: $font-weight__semibold;
                        margin: 0 0 5px;
                        display: inline-block;
                    }

                    .email-label:after {
                        content: "*";
                        font-size: 12px;
                        margin: 0 0 0 5px;
                        color: #f55;
                    }

                    .control {
                        margin: 0 0 15px;

                        .mage-error {
                            padding-top: 10px;
                        }

                        .checkEmailAvaiable {
                            display: block;
                            font-style: italic;
                            font-size: .8rem;
                            line-height: 1.2rem;
                            color: red;
                            padding-top: 10px;
                        }
                    }

                    .scg-policy {
                        border-bottom: 1px solid $text-lightest;

                        .policy-content {
                            font-size: $default-font-size;
                            margin-bottom: 1.5rem;

                            a {
                                text-decoration: underline;
                            }
                        }
                    }

                    .field {
                        padding: 0;

                        .label {
                            font-weight: $default-font-weight;

                            span {
                                left: 30px;
                                word-wrap: break-word;
                                display: block;
                                position: relative;
                                max-width: calc(100% - 30px);
                            }
                        }

                        input[type="checkbox"] {
                            position: absolute;
                            z-index: 1;
                            opacity: 0;
                        }

                        input.required-entry.checkbox-confirm {
                            visibility: visible;
                        }

                        &.receive-content {
                            margin-top: 1rem;

                            span {
                                font-weight: $font-weight__semibold;
                                font-size: $default-font-size;
                            }
                        }

                        &.accept-cookie-content {
                            padding: 0;

                            .label {
                                span > a {
                                    text-decoration: underline;
                                }

                                span {
                                    font-weight: $font-weight__semibold;
                                    font-size: $default-font-size;
                                }
                            }
                        }
                    }

                    .field.receive-content {
                        margin-bottom: 15px;
                        position: relative;

                        .required-entry.checkbox-confirm {
                            position: absolute;
                            visibility: visible;
                            opacity: 0;
                            z-index: 1;
                            width: 20px;
                            height: 20px;
                        }
                    }

                    .required-entry.checkbox-confirm + label:before {
                        content: '';
                        font-size: 16px;
                        font-family: $material-icons;
                        border: 1px solid $third-color;
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                        vertical-align: top;
                        background: $white;
                        text-align: center;
                        position: absolute;
                        cursor: pointer;
                    }

                    .required-entry.checkbox-confirm:checked + label:before {
                        border-color: $secondary-color;
                        background: $secondary-color;
                    }

                    .required-entry.checkbox-confirm:checked + label:after {
                        content: '\e876';
                        display: inline-block;
                        font-family: $material-icons;
                        position: absolute;
                        left: 0;
                        top: 0;
                        vertical-align: top;
                        color: $white;
                        font-size: 14px;
                        width: 20px;
                        height: 20px;
                        text-align: center;
                        font-weight: bold;
                        @media (max-width: $screen-md-min){
                            top: -1px;
                        }
                    }

                    .email-consent.content {
                        padding: 20px 0 40px 0;
                        font-size: $default-font-size;
                        display: inline-block;
                    }

                    .scg-email-group {
                        text-align: center;

                        .action.confirm.primary {
                            padding: 10px 60px 11px;
                            pointer-events: none;
                            opacity: 0.5;
                        }

                        .action.confirm.primary.active {
                            pointer-events: all;
                            opacity: 1;
                        }

                        .link {
                            padding: 15px 0 40px;

                            .action.cancel.primary {
                                font-size: 14px;
                                color: $accent;
                            }
                        }
                    }
                }
            }
        }
    }

    .page-title-wrapper {
        display: none;
    }
}

.integration-account-login {
    .page-title-wrapper {
        display: none;
    }

    .scgId-connect-member-login {
        display: flex;
        justify-content: center;

        .form-login {
            .fieldset {
                @media (min-width: $screen-sm-min) {
                    display: block;
                    width: 100%;
                    max-width: 340px;
                }
            }

            .login {
                .actions-toolbar {
                    display: flex;
                    justify-content: flex-start;

                    .secondary {
                        transform: translate(0, 50%);
                    }
                }

                .title-h3 {
                    margin-bottom: 32px;
                    margin-top: 15px;
                    font-size: 2.85rem;
                    text-align: center;
                    font-weight: $font-weight__semibold;
                    line-height: 3rem;
                }

                .field.note {
                    color: $text-light;
                    font-size: 18px;
                    margin-bottom: 18px;
                    text-align: center;
                    line-height: 1.5rem;
                }

                .field .control {
                    .input-text {
                        font-size: 14px;
                    }
                }

                .section-divider-or {
                    margin: 0;
                }
            }

            .custom-label {
                label {
                  color: #b1b1b1;
                }
            }

            .fieldset {
                .actions-toolbar {
                    padding-top: 0;

                    .secondary {
                        .action.remind {
                            text-decoration: underline;
                        }
                    }

                    .primary + .secondary {
                        margin: 0 1.5rem 0 0 ;
                    }
                }
            }
        }
    }

    .btn-snetwork {
        display: flex;
        justify-content: center;
        margin: 0;

        div.modal-facebook {
            margin-bottom: 4rem;
            background-color: $white;
            background-image: none;
            float: none;
            padding: 8px;
            font-weight: bold;
            width: 100%;
            max-width: 300px;
            border-radius: 40px;
            border: 2px solid $third-color;
            box-shadow: none;
            font-size: $default-font-size;
            height: 50px;
            position: relative;
            overflow: hidden;
            background-size: cover;
            @media (max-width: $screen-sm-min) {
                margin-bottom: 3rem;
            }
        }

        div.modal-facebook span.icon {
            display: inline-block;
            vertical-align: middle;
            width: 32px;
            height: 32px;
            background-size: 70px;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 10px;
            border-radius: 50%;
            background-color: #1877f2;
            background-image: url(../../images/facebook-logo-blue-white-circle.svg);

        }

        div.modal-facebook span.label {
            position: relative;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.integration-account-prefillsignup {
    .block-customer-login {
        .block-content {
            .register-scg-id {
                display: none;
            }
        }
    }
}

.integration-account-signup {
    .form-signup-scgId {
        @media (max-width: $screen-sm-min) {
            height: 1450px;
        }

        @media (min-width: $screen-sm-min) {
            height: 1450px;
        }

        @media (min-width: $screen-lg-min) {
            height: 1250px;
        }
    }

    .page-footer {
        z-index: 9;
    }
}

//
//  Forms
//  _____________________________________________

.form-create-account,
.form-edit-account,
.form-address-edit,
.form-login,
.block-addresses-list,
.form-newsletter-manage,
.downloadable-customer-products,
.my-reviews-table__wrapper,
.account-billing-agreements__wrapper {
    .fieldset {
        margin-bottom: 1rem;
    }

    .actions-toolbar {
        @include make-flex;
        @include flex-direction(row-reverse);
        @include justify-content(flex-end);
        margin-top: $indent-base;
        margin-bottom: $indent-base;

        .primary + .secondary {
            margin: 0 $indent-base 0 0;
        }
    }
}

.form-create-account {
    @media (min-width: $screen-md-min) {
        margin-bottom: $indent-large;
    }

    .fieldset .legend {
        font-size: $acct-block-title-font-size;
        color: $secondary-color;
        padding: 10px 0;
    }

    .actions-toolbar {
        @include justify-content(center);

        .primary {
            .action {
                min-width: 200px;
            }
        }
    }

    #agreed-error {
        display: none;
        font-size: $acct-mobile-font-size;
        line-height: 1;
        font-style: italic;
        color: $denied;
        padding-top: 4px;
    }
}

.form-edit-account,
.form-create-account {
    .fieldset .field {
        #password-strength-meter-container {
            font-size: $acct-mobile-font-size;
            line-height: 1;
            padding-top: 4px;
        }
    }

    .fieldset .field {
        &.field-dob {
            .customer-dob {
                position: relative;
                max-width: 200px;

                .ui-datepicker-trigger {
                    position: absolute;
                    top: $acct-mobile-font-size;
                    right: 18px;
                }
            }
        }
    }

    .fieldset .field {
        &.gender {
            select {
                max-width: 200px;
            }
        }
    }

    .fieldset .field {
         &.scg_family {
             .red-text{
                 color: $accent;
             }
         }
    }
}

.form-edit-account,
.form-address-edit {
    padding: $indent-small;
    margin-bottom: $indent-base;
    @include account-nav-box-shadow;

    @media (min-width: $screen-sm-min) {
        padding: $indent-base;
    }

    @media (min-width: $screen-md-min) {
        margin-bottom: $indent-base + 1;
    }

    .fieldset {
        + .fieldset {
            margin-top: 2rem;
        }

        .legend {
            @media (min-width: $screen-sm-min) {
                margin-bottom: 15px;
            }
        }
    }

    .actions-toolbar {
        margin-top: $indent-small;
        margin-bottom: $indent-small;

        @media (min-width: $screen-sm-min) {
            margin-top: $indent-base;
        }

        .primary {
            .action {
                min-width: 150px;
            }
        }
    }
}

.login-container, .block-authentication {
    .form-login {
        .custom-label {
            label {
                color: #B1B1B1;
            }
        }

        .actions-toolbar {
            @include make-flex;
            @include flex-direction(row-reverse);
            @include align-items(center);
            @include justify-content(flex-start);

            .remind {
                display: block;
                text-decoration: underline;
                padding-top: 20px;
                padding-bottom: 0;
            }
        }

        .captcha-image {
            @media (max-width: $screen-xs-min - 1) {
                text-align: center;
            }

            .captcha-img,
            .captcha-reload {
                display: inline-block;
                vertical-align: middle;
                margin-top: 10px;
            }

            .captcha-img {
                margin-right: 20px;
            }
        }
    }

    .block-new-customer {
        .block-content {
            ul {
                margin-bottom: 32px;
            }

            li {
                list-style: disc inside;
            }
        }
    }
}

.login-container {
    max-width: 1290px;
    margin: 0 auto;

    .customer-login {
        padding-top: $indent-base;

        @media (min-width: $screen-sm-min) {
            border-right: 2px solid $border-color;
            padding-left: $indent-small;
            padding-right: $indent-base;
        }
    }

    .customer-register {
        @media (min-width: $screen-sm-min) {
            padding-right: $indent-small;
            padding-left: $indent-base;
        }

        .block-new-customer {
            @media (max-width: $screen-sm-min - 1) {
                border-top: 2px solid $border-color;
                padding-top: $indent-base;
            }

            .actions-toolbar {
                @media (max-width: $screen-sm-min - 1) {
                    text-align: center;
                }

                a.create {
                    @include button-block;
                    @include button-block__primary;
                    margin: 8px auto;

                    @media (max-width: $screen-sm-min - 1) {
                        min-width: 250px;
                    }
                }
            }
        }
    }

    .customer-login,
    .customer-register {
        margin-bottom: $indent-base;

        .action.login.scg-id-login {
            width: 100%;
            margin-top: 30px;
        }

        @media (min-width: $screen-sm-min) {
            padding-top: $indent-small;
        }

        @media (min-width: $screen-md-min) {
            padding-left: $indent-large;
            padding-right: $indent-large;
            margin-bottom: $indent-large;
        }

        @media (min-width: $screen-lg-min) {
            padding-left: $indent-base * 3;
            padding-right: $indent-base * 3;
        }
    }
}

.customer-account-edit,
.customer-account-create {
    #select2-gender-results {
        line-height: 1.5;
    }
}

//
//  Account Dashboard
//  _____________________________________________

.block-dashboard-info {
    .box-content {
        .info_title {
            margin-right: 5px;
        }

        .box-newsletter {
            font-weight: $font-weight__bold;
            margin: 10px 0 20px 0;
        }
    }

    .anycard-qr-image {
        text-align: center;

        .img-qr-anycard {
            width: 65%;
        }
    }

    @media (max-width: $screen-sm-min - 1) {
        .col-information {
            order: 2;
        }

        .col-anycard-qr {
            order: 1;
        }
    }
}

.block-dashboard-addresses {
    .block-title a.action {
        color: $secondary-color;
    }

    .box {
        .box-content address {
            margin-bottom: 0;
        }
    }
}

.block-reviews-dashboard {
    margin-bottom: 2rem;

    .block-title {
        @media (max-width: $screen-md-min - 1) {
            display: none;
        }
    }

    .review-details {
        @include make-flex;
        @include flex-wrap(no-wrap);
        @include flex-direction(row);
        @include justify-content(flex-start);
        @include align-items(flex-start);
        border-bottom: 1px solid $dark-border-color;
        margin-bottom: 30px;
        font-size: $default-font-size;
        padding-bottom: 20px;

        @media (max-width: $screen-sm-min - 1) {
            @include flex-wrap(wrap);
            @include justify-content(center)
        }

        .product-media {
            min-width: 210px;
        }

        .review-detail-content {
            @include flex-grow(1);

            @media (min-width: $screen-sm-min) {
                padding-left: 15px;
            }

            @media (max-width: $screen-sm-min - 1) {
                flex-basis: 100%;
                text-align: center;
            }
        }

        .review-date {
            font-weight: $font-weight__bold;
        }

        .product-name {
            font-size: 18px;
            margin: 15px 0;
        }

        .rating-label {
            color: $accent;
            text-transform: uppercase;
            font-weight: $font-weight__bold;
        }

        .rating-summary {
            @media (max-width: $screen-sm-min - 1) {
                @include justify-content(center);
            }
        }

        .review-tags {
            margin: 10px 0;
        }

        .review-title {
            font-size: 16px;
            line-height: 18px;
            font-weight: $font-weight__bold;
            margin: 3px 0 4px 0;
            word-break: break-word;
        }

        .review-content {
            word-break: break-word;
            color: #838383;
        }
    }

    .items li {
        padding: 9px 16px;
        @include make-flex;
        @include justify-content(space-between);
        @include align-items(center);

        &:nth-child(odd) {
            background: $bg-dark;
        }
    }
}

.block-dashboard-orders .table-order-items {
    td, th {
        @media (min-width: $screen-xl-min) {
            padding: 9px 20px;
        }
    }

    td.actions {
        @media (min-width: $screen-md-min) {
            .view {
                float: left;
            }
            .order {
                float: right;
            }
        }

        a {
            font-weight: $font-weight__bold;

            &.view {
                padding-right: 8px;
            }
        }
    }
}

.sales-order-history .actions-toolbar a.back {
    @include button-block__back;

}


// Email to Friend
//-----------------------------------

#add-recipient-button {
    margin-bottom: 1em;
}

// Popup Authentication
//-----------------------------------

.popup-authentication {
    .block-authentication {
        @include make-flex;
        @include flex-direction(column);
        @include justify-content(flex-start);
        @include align-items(stretch);

        @media (min-width: $screen-mob-min) {
            @include flex-direction(row);
            @include justify-content(space-between);
        }
    }

    .block[class],
    .form-login,
    .fieldset,
    .block-content {
        @include make-flex;
        @include flex-direction(column);
    }

    .block {
        @media (min-width: $screen-mob-min) {
            @include flex-basis(50%);
        }

        &:first-child {
            padding: 0 0 32px 0;
            @media (min-width: $screen-mob-min) {
                padding: 16px 32px 16px 0;
            }
        }

        & + .block {
            border-top: 1px solid $border-color;
            padding: 32px 0;

            @media (min-width: $screen-mob-min) {
                border-top: none;
                border-left: 1px solid $border-color;
                padding: 16px 0 16px 32px;
            }
        }
    }

    .block-title {
        font-size: $acct-block-title-font-size;
        font-weight: $font-weight__bold;
        color: $primary;
        margin-bottom: 8px;
    }

    .actions-toolbar {
        text-align: center;

        @media (min-width: $screen-md-min) {
            text-align: left;
        }

        .action-register {
            @include button-block;
            @include button-block__primary;
        }

        .action-login {
            @include button-block__primary;
        }
    }

    .form-login .actions-toolbar {
        margin: 0;
    }

    .block-social-login {
        padding: 0 !important;
        margin: 0 auto !important;

        ul {
            margin-top: 0;
        }
    }
}

// Address
//-----------------------------------

@import "_address.scss";


// Forgot Password
//-----------------------------------
@import "form/_forgotpassword.scss";

// Create Password
//-----------------------------------
@import "form/_createpassword.scss";
