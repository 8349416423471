.ctis-landing-row {
  font-family: $ctis-font-family;

  p {
    word-break: break-all;
  }
}

.ctis-landing-text-1 {
  background: #f7f5f1 url(../../images/ct_bg_1.jpg) no-repeat;
  background-size: 100% auto;
  background-position: top center;
  padding: 100px 240px 100px 120px;
  font-size: 36px;
  text-indent: 50px;
}

.ctis-landing-text-bg {
  background: url(../../images/ct_bg_2.jpg) no-repeat;
  background-size: 100% auto;
  background-position: top center;
  padding: 100px 160px 100px 120px;

  h3 {
    font-size: 40px;
    font-weight: bold;
    text-transform: none;
  }

  p {
    font-size: 36px;
    text-indent: 50px;
    color: #6d6e71;
  }
}

@media (max-width: $screen-md-min - 1) {
  .ctis-landing-text-1 {
    padding: 30px 17% 30px 10%;
    font-size: 20px;
    text-indent: 30px;
    line-height: 1.1;
  }

  .ctis-landing-text-bg {
    padding: 60px 12% 20px 10%;

    h3 {
      font-size: 32px;
    }

    p {
      font-size: 20px;
      text-indent: 30px;
    }
  }
}

@media (max-width: $screen-lg-min - 1) {
  .ctis-landing-text-1 {
    padding: 35px 18% 30px 10%;
    font-size: 24px;
    text-indent: 40px;
    line-height: 1.4;
  }

  .ctis-landing-text-bg {
    padding: 80px 12% 20px 10%;

    h3 {
      font-size: 28px;
    }

    p {
      font-size: 24px;
      text-indent: 40px;
    }
  }
}

@media (max-width: $screen-sm-min - 1) {
  .ctis-landing-text-1 {
    padding: 20px 20% 20px 10%;
    font-size: 16px;
    text-indent: 20px;
  }

  .ctis-landing-text-bg {
    padding: 50px 13% 20px 10%;

    h3 {
      font-size: 18px;
    }

    p {
      font-size: 16px;
      text-indent: 20px;
    }
  }
}

@media (max-width: $screen-xs-min - 1) {
  .ctis-landing-text-1 {
    padding: 20px 20% 20px 10%;
    font-size: 13px;
    text-indent: 20px;
  }

  .ctis-landing-text-bg {
    padding: 30px 12% 20px 10%;

    h3 {
      font-size: 13px;
    }

    p {
      font-size: 13px;
      text-indent: 20px;
    }
  }
}