/* 
 * 	Featured Product by TemplateMonster 2016
 *	v1.0.0
 */


// Colors
$label-color: #ffffff;
$label-bg: $third-color;
$label-size: 12px;
$border-color: #dedede;

// Fonts
$fa: 'FontAwesome';

