/**
 * Copyright © 2016 TemplateMonster. All rights reserved.
 * See COPYING.txt for license details.
 */

// Local vars
$category-link-size: 24px;
$category-link-size-small: 16px;
$category-link-color: $black;
$category-link-color-hover: $accent;

$subcategory-link-size: 18px;
$subcategory-link-size-small: 14px;
$subcategory-link-color: $default;
$subcategory-link-color-hover: $accent;

$link-size: 16px;
$link-color: $primary;
$link-color-hover: $black;


// Main styles
.catalog-image-grid {
	margin-bottom: 20px;

	ul{
		list-style: none;
		padding: 0;
		margin: 0;

		img {
			max-width: 100%;
		}
	}

	/************** Category **************/
	.category-grid,
	.category-list {
		overflow: hidden;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin: 0 -15px;

		> li {
			padding: 0 15px;
			margin: 0 0 30px;
			box-sizing: border-box;

			@media (max-width: 767px) {
				-moz-flex-basis: 33.333%;
				-ms-flex-basis: 33.333%;
				flex-basis: 33.333%;
			}
			@media (max-width: 500px) {
				-moz-flex-basis: 50%;
				-ms-flex-basis: 50%;
				flex-basis: 50%;
			}
			@media (max-width: 350px) {
				-moz-flex-basis: 100%;
				-ms-flex-basis: 100%;
				flex-basis: 100%;
			}

			.grid-inner {
				box-sizing: border-box;
				text-align: center;
			}
		}

		.category-link {
			display: inline-block;
			text-decoration: none;
			font-weight: 500;
			font-size: $category-link-size;
			color: $category-link-color;
			text-align: center;

			&:hover {
				color: $category-link-color-hover;
			}

			@media (max-width: 767px) {
				font-size: $category-link-size-small;
			}

			.category-name {
				display: inline-block;
				margin-bottom: 10px;
			}
			.category-image {
				display: block;
				margin-bottom: 10px;
			}

			.category-icon {
				display: inline-block;
				font-style: normal;

				&:before {
					line-height: normal;
					margin-right: 5px;
				}
			}
		}

		.button-more {
			text-decoration: none;
			font-size: $link-size;
			color: $link-color;

			&:hover {
				color: $link-color-hover;
			}
		}

		/************** SubCategory **************/
		.subcategory-grid {

			margin-bottom: 10px;

			> li {
				padding: 0;
				margin: 0;
				line-height: 30px;
			}
			.subcategory-link {
				display: inline-block;
				text-decoration: none;
				font-weight: 500;
				font-size: $subcategory-link-size;
				color: $subcategory-link-color;

				&:hover {
					color: $subcategory-link-color-hover;
				}

				@media (max-width: 767px) {
					font-size: $subcategory-link-size-small;
				}

				.subcategory-name,
				.subcategory-image {
					display: inline-block;
				}

				.subcategory-image {
					vertical-align: middle;
					margin-right: 10px;

					img {
						vertical-align: middle;
					}
				}

				.category-icon {
					display: inline-block;
					font-style: normal;

					&:before {
						line-height: normal;
						margin-right: 5px;
					}
				}
			}
		}
	}

	.grid-wrapper {
		overflow: hidden;

		.category-image {
			float: left;
			width:33%;
			margin-right: 20px;
		}

		.subcategories {
			overflow: hidden;
			float: left;
		}
	}
	&.hide-title {
		.catalog-image-grid-title {
			display: none;
		}
	}
}
.homepage-cig.catalog-image-grid {
	margin-bottom: 37px;
	.category-grid {
		> li {
			@media (max-width: $screen-lg-min - 1) {
				-moz-flex-basis: 50%;
				-ms-flex-basis: 50%;
				flex-basis: 50%;
				max-width: 50%;
			}
			@media (max-width: $screen-sm-min - 1) {
				-moz-flex-basis: 100%;
				-ms-flex-basis: 100%;
				flex-basis: 100%;
				max-width: 100%;
			}
		}
		.grid-inner {
			.category-link {
				position: relative;
				.category-image {
					margin-bottom: 0;
				}
				.category-name {
					position: absolute;
					bottom: 0;
					width: 100%;
					background: rgba($bg-dark, .9);
					left: 0;
					text-align: center;
					color: $white;
					font-size: 38px;
					line-height: 60px;
					letter-spacing: 0.04em;
					margin-bottom: 0;
					transition: .3s;
					text-transform: uppercase;
					@media (max-width: $screen-xl-min - 1) {
						@media (min-width: $screen-lg-min) {
							line-height: 45px;
						}
					}
				}
				&:hover {
					.category-name {
						bottom: 50%;
						transform: translateY(50%);

						background: rgba($third-color, .9);

					}
				}
			}
		}
	}
}