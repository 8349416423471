
//
//  Grid
//  ---------------------------------------------

.products-grid{
    .product-items{
        @include make-flex();
        @include make-row();
        @include flex-wrap(wrap);
    }
    .product-item{
        @include make-col();
        @include flex-basis(100%);
        @include make-flex();
        @include flex-direction(column);
        @include justify-content(flex-start);

        &:hover {
            -webkit-box-shadow: 1px 1px 10px rgb(199, 199, 199);
            -moz-box-shadow: 1px 1px 10px rgb(199, 199, 199);
            box-shadow: 1px 1px 10px rgb(199, 199, 199);
            @include transition(all 0.3s);
            background-color: $white;
            z-index: 1;
        }

        @media (min-width: 320px){
            @include flex-basis(50%);
        }
        @media (min-width: 680px){
            @include flex-basis(33.33%);
        }
    }

    //Image
    .product-image-container{
        display: inline-block;
        max-width: 100%;
    }
    .product-image-wrapper{
        height: 0;
        display: block;
        position: relative;
        z-index: 1;
        overflow: hidden;
    }
    .product-image-photo{
        text-align: center;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: auto;
        max-width: 100%;
    }
    .product-item-details {
        margin-top: 4px;
        position: relative;

        .count-down-info {
            position: absolute;
            top: -55px;
            background: $red-color;
            color: #fff;
            text-transform: uppercase;
            width: 100%;
            padding: 8px 10px 5px;

            @media (max-width: 768px){
                padding: 5px 5px 0;
                top: -35px;
            }

            > div {
                display: inline-block;
                line-height: 1;
                width: calc((100% - 20px)/4);

                @media (max-width: 768px){
                    width: calc((100% - 10px)/4);
                }

                > span {
                    display: inline-block;
                    width: 100%;

                    &.label {
                        margin: 5px 0 0 0;
                    }
                }

                @media (max-width: 768px){
                    font-size: 0.7em;
                }
            }

            .value {
                font-weight: bold;
            }
        }
    }
    .product-item-footer {
        @include make-flex();
        @include flex-direction(column);
        @include flex-grow(1);
        @include justify-content(flex-end);
    }

    //Reviews
    .reviews-actions{
        @include make-flex();
        @include justify-content(space-between);
        .action.add{
            @include font-icon('\ea7a');
            font-size: 0;
            &:before{
                font-size: $default-font-size;
            }
        }
    }

    //Price
    .price-from{
        margin-bottom: 0;
    }

    .price-to{
        margin-bottom: 0;
    }

    .price-box {
        margin-top: 3px;
        @include make-flex;
        @include flex-direction(column);
        @include align-items(flex-start);
    }

    .product-item-price-per-unit {
        display: flex;
        align-items: flex-end;

        .product-item-unit {
            font-size: 13px;
            margin-bottom: 2px;
            text-transform: uppercase;
            line-height: 13px;
            font-weight: 500;

            @media (max-width: $screen-xs-min - 1) {
                font-size: 11px;
                line-height: 11px;
            }
        }
    }

    .product-item-info-box {
        &.product-item-actions,
        .product-item-actions{
            @include align-items(center);
            @include justify-content(space-between);
            width: 100%;
        }
    }
}
