// Brand Page

.category-brand-banner {
    margin: 0 auto;
    max-width: 1260px;
    width: 100%;

    img {
    	width: 100%;
    	height: auto;
    }
}


.logo-description-container {
    overflow: hidden;
    margin-bottom: 30px;
    img {
        float: left;
        margin: 0 15px 15px 0;
    }
}