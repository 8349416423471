// Main styles
.tm-blog-post-view {
    .page-title-wrapper {
        margin-bottom: 15px;

        .container {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .page-title {
        text-align: left;
        font-size: 200%;
    }
    .post-page {
        .post-data {
            display: block;
            overflow: hidden;
        }
    }
    .create-date {
        float: left;
        line-height: 16px;
        padding: 1px 10px 0 0;
        border-right: 1px solid $text-lightest;
    }
    .social-sharing {
        float: left;
        clear: none;
    }
}

.post-short-text {
    font-size: 150%;
    line-height: 1.3;
    margin-bottom: 15px;
    font-weight: 600;
}

.post-page {
    clear: both;
    font-family: $font;
    width: 100%;

    // Post text
    .post-text {
        margin-bottom: 25px;
    }

    // Post data
    .post-data {
        margin-bottom: 35px;

        .create-date,
        .post-author,
        .post-comments {
            display: inline-block;
            margin-right: 20px;

            span, a {
                display: inline-block;
            }
            a {
                color: $post-data-color;
                text-decoration: none;
                &:hover {
                    color: $color;
                }
            }
            a span:before,
            &:before {
                font-size: 18px;
                line-height: 18px;
                padding-right: 4px;
                color: $color;
                vertical-align: sub;
            }
        }
        .create-date{
            margin-right: auto;

            .tm-blog-post-view & {
                margin: 1px 10px 0 0;
            }
        }
        .create-date time {
            display: inline-block;
        }
        .post-author {
            font-weight: 700;
        }
        .post-comments {
            @include font-icon('\ea7b');
        }

        @media (max-width: 480px) {
            overflow: hidden;

            .create-date,
            .post-author,
            .post-comments{
                display: block;
                margin-bottom: 3px;
            }
        }

        .social-sharing{
            margin-top: 0;
            min-width: 50%;
        }
    }

    .post-relatedproducts{

        .block.related {
            float: none;

            .owl-carousel {
                .product-item {
                    width: 100%;
                }
            }

             .product-item{
                .product-item-info {
                    width: 100%;
                    padding: 0;
                    margin: 0;
                    box-shadow: none;
                    border: none;
                }

                .product-item-photo {
                    display: block;
                    text-align: center;
                }
            }

            .product-items:not(.owl-carousel) {
                display: block;

                .product-item {
                    width: 33.33%;
                }
            }
        }
    }

    .block.related{
        padding-top: 16px;

        .block-title{
            margin-bottom: 0;
            padding-top: 4px;
        }
    }
    .products-grid.products-related{
        margin-top: 8px;

        .product-items:not(.owl-loaded) {
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }
    }
}
