//
//  Product view
//  _____________________________________________

.catalog-product-view {
    .products-series {
        .amasty-label-image {
            display: none;
        }
    }

    .page-main {
        margin-top: 0;

        @media (min-width: $screen-sm-min) {
            margin-top: 1rem;
        }
    }

    .column.main {
        display: block;

        @media (max-width: $screen-sm-min - 1) {
            @include make-flex();
            @include make-row();
            @include flex-wrap(wrap);
        }
    }

    //Product Media
    .product.media {
        margin-bottom: $indent-small;

        @media (min-width: $screen-sm-min) {
            width: 45%;
            float: left;
        }
        @media (max-width: $screen-sm-min - 1) {
            @include order(2);
            @include make-col();
            @include flex-basis(100%);
        }

        .fotorama__nav, .fotorama__stage {
            &:after, &:before {
                display: none;
            }
        }

        .fotorama__stage {
            margin-bottom: 4px;
        }

        .fotorama__thumb {
            background-color: transparent;
        }

        .fotorama__thumb-border {
            background-image: none;
            background-color: $gray;
            opacity: 0.5;
        }

        .fotorama__arr {
            background-image: none;

            &:before {
                width: 32px;
                height: 32px;
                font-size: 14px;
                line-height: 32px;
                text-align: center;
                background: $white;
                border: 1px solid $border-color;
                color: $text-lightest;
                border-radius: 4px;
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 50%;
                margin-top: -16px;
                margin-left: -16px;
            }

            &:hover:before {
                background: $bg-dark;
                border-color: $bg-dark;
                color: $primary;
            }

            &--prev:before {
                @include font-icon__pseudo('\e927');
            }

            &--next:before {
                @include font-icon__pseudo('\e928');
            }

            .fotorama__arr__arr {
                display: none;
            }
        }
    }

    .fotorama__nav--thumbs {
        .fotorama__nav__frame {
            opacity: 1 !important;
        }

        .fotorama__thumb__arr {
            position: absolute;
            top: 50%;
            margin-top: -16px;
            width: 32px;
            height: 32px;
            background-color: transparent;
            opacity: 0.6;
            z-index: 3;

            .fotorama__thumb--icon {
                background-image: none;
                background-color: $black;
                width: 32px;
                height: 32px;

                &:before {
                    content: '';
                    position: absolute;
                    font-size: $default-font-size;
                    color: $white;
                    line-height: 32px;
                    background-color: transparent;
                    width: 32px;
                    height: 32px;
                    border: none;
                    text-align: center;
                }

                &:hover {
                    background-color: $third-color;

                    &:before {
                        color: $white;
                    }
                }
            }

            &.fotorama__thumb__arr--left .fotorama__thumb--icon {
                @include font-icon("\e927");
                border-radius: 0 2px 2px 0;
            }

            &.fotorama__thumb__arr--right .fotorama__thumb--icon {
                @include font-icon("\e928");
                border-radius: 2px 0 0 2px;
            }

            &.fotorama__arr--disabled {
                display: none !important;
            }
        }
    }


    //Product info
    .product-info-top,
    .product-info-main {

        @media (min-width: $screen-sm-min) {
            width: 50%;
            float: right;
            clear: right;
        }
        @media (max-width: $screen-sm-min - 1) {
            @include make-col();
            @include flex-basis(100%);
        }
    }

    .product-info-top {
        @media (max-width: $screen-sm-min - 1) {
            @include order(1);
        }
    }

    .product-info-main {
        @media (max-width: $screen-sm-min - 1) {
            @include order(3);
        }

        .total-sold-and-stock {
            text-align: left;

            @media (min-width: $screen-lg-min) {
                .total-sold {
                    width: 40%;
                }

                .available-stock {
                    width: 60%;
                }

                .total-sold,
                .available-stock {
                    margin-top: 5px;
                }
            }

            @media (max-width: $screen-md-min) {
                .total-sold {
                    text-align: center;
                }
            }
        }
    }

    .product-info-details {

        @media (min-width: $screen-sm-min) {
            padding-top: 2rem;
            clear: both;
        }

        @media (max-width: $screen-sm-min - 1) {
            @include make-col();
            @include flex-basis(100%);
            @include order(5);
        }
    }

    // Page Title
    .page-title-wrapper {
        margin-top: 0;
        margin-bottom: 1rem;
        line-height: 1;

        .container,
        h1 {
            margin: 0;
            padding: 0;
            text-align: left;
            min-width: 280px;
            font-size: 24px;
            line-height: 1.2;
        }
    }

    // Stock, SKU
    .product-info-stock-sku {
        @include flex-basis(100%);
        width: 100%;
        margin-bottom: $indent-small;

        .stock {
            background-color: $third-color;
            font-size: $default-font-size;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.04em;
            min-width: 178px;
            padding: 10px 15px;
            display: inline-block;
            backface-visibility: hidden;
            transform: translateZ(0);

            @media (min-width: $screen-lg-min) {
                padding: 11px 20px 10px;
            }
        }

        .available {
            color: $black;
        }

        .unavailable {
            color: $denied;
        }

        @media (min-width: $screen-xs-min) {
            @include flex-basis(auto);
            width: auto;
            margin-right: $indent-base;
        }
    }

    // Review at Page Top
    .product-info-top {
        .product-reviews-summary {
            margin-bottom: 16px;
            font-size: 14px;
            line-height: 14px;

            .label {
                display: none;
            }

            .rating-summary {
                margin: 0 -4px;
            }

            .rating-result__stars:before {
                font-size: 32px;
            }

            .rating-result__stars span[class^="rating-star__"] {
                font-size: 12px;
                line-height: 14px;

                &:before {
                    font-size: 32px;
                }
            }
        }
    }

    //Price
    .product-info-price .price-box {
        @include price-box; // _mixins.scss
        font-weight: 700;
        margin-left: 4px;

        span {
            &.price {
                font-size: 22px;
                line-height: 22px;

                @media (min-width: $screen-sm-min) {
                    font-size: 24px;
                    line-height: 24px;
                }
            }
        }

        .old-price, .special-price {
            display: inline-block;
        }

        .old-price {
            span {
                &.price {
                    font-size: 16px;
                }
            }
        }
    }

    .product-info-price {
        .price-per-sqm, .product-item-unit {
            display: none;
        }
    }

    .price-attribute-persqm {

        .price {
            line-height: 22px; 
            vertical-align: top; 
            display: inline-block; 

            @media (min-width: $screen-sm-min) {
                line-height: 24px;
            }
        }

        .persqm-price-top {
            .timer-wrapper {
                margin-top: 5px;
            }
        }
        
        .value {
            .product-info-price {
                float: left;

                .price-box {
                    margin-left: 0;
                    margin-right: 4px; 
                    line-height: 22px; 

                    @media (min-width: $screen-sm-min) {
                        line-height: 24px;
                    }
                }
            }

            > .price,
            .persqm-price-top > .price {
                color: #f04544;
                font-weight: bold; 
            }

            .price {
                font-size: 22px;
                line-height: 22px;

                @media (min-width: $screen-sm-min) {
                    font-size: 24px;
                    line-height: 24px;
                }
            }
        }
    }
    // Short Description
    .overview {
        margin-bottom: 24px;
    }

    // Brand
    .product-brand {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 0;
        margin-left: 5px;

        .product-brand-name {
            margin-bottom: 0;
            line-height: $default-line-height;

            a {
                font-size: $default-font-size;
                color: $secondary-color;
            }
        }
    }

    .product-brand-logo img {
        transition: $default-transition;
    }

    .product-brand-logo a:hover img {
        opacity: .7;
    }

    //Attributes
    .additional-attributes {
        .attributes-caption {
            display: none;
        }

        .attributes-label,
        .attributes-data {
            font-size: $default-font-size;
            line-height: $default-line-height;
            font-weight: 500;
            width: 50%;
            display: inline-block;
            vertical-align: middle;
            margin-right: -4px;
            margin-bottom: 5px;
        }

        .attributes-label {
            color: $text-light;

            @media (min-width: $screen-sm-min) {
                width: 40%;
                &:after {
                    width: 100%;
                }
            }
        }
    }

    .attributes-data {
        @media (min-width: $screen-sm-min) {
            width: 60%;
        }
    }

    .attributes-row:last-child {
        .attributes-label,
        .attributes-data {
            margin-bottom: 0;
        }
    }

    // Swatches
    .swatch-opt {
        padding: 0;
        margin-bottom: 32px;
    }

    .swatch-attribute {
        position: relative;

        + .swatch-attribute {
            margin-top: 14px;
        }

        .swatch-attribute-label {
            color: $text-light;
            font-weight: 700;
            display: inline-block;
            margin-bottom: 4px;

            &:after {
                content: ':';
            }
        }

        .swatch-attribute-options {
            @include make-flex;
            @include align-items(center);
            @include flex-wrap(wrap);
        }

        .swatch-attribute-selected-option {
            display: none;
        }

        [id*='super_attribute['].mage-error {
            position: absolute;
            top: calc(100% - 5px);
            left: 0;
        }
    }

    // Custom options
    .product-options-wrapper {
        .fieldset > .field,
        .fieldset-bundle-options__wrapper > .field,
        .field.qty {
            margin-bottom: 20px;

            > .label {
                color: $text-light;
                font-weight: 700;
                display: inline-block;
                margin-bottom: 8px;

                span:after {
                    content: ':';
                }
            }
        }

        .field {
            padding: 6px 0;
            display: inline-block;
            width: 100%;
        }

        .price-notice {
            font-weight: 700;
            color: $text-light;
        }

        .select[multiple="multiple"] {
            max-width: 250px;
        }

        .date {
            .required legend {
                color: $text-light;
                font-weight: 700;
                margin-bottom: 4px;

                span:after {
                    color: $denied;
                    content: "*";
                    font-size: 12px;
                    margin: 0 0 0 5px;
                }
            }

            .select2-container {
                margin-right: 4px;

                @media (min-width: $screen-lg-min) {
                    margin-right: 12px;
                }

                &:nth-child(6) {
                    margin-right: 0;
                    @media (min-width: $screen-lg-min) {
                        margin-right: 20px;
                    }
                }
            }

            b {
                line-height: 50px;
                margin-right: 4px;
            }

            .select2-container:nth-child(8) {
                margin-right: 4px;
            }

            .select2-hidden-accessible:nth-child(7) {
                @media (max-width: $screen-lg-min - 1) {
                    width: 100% !important;
                    position: relative !important;
                    font-size: 0 !important;
                    height: 0 !important;
                    margin-bottom: 12px !important;
                }
            }
        }
    }

    // Add form
    .product-add-form {
        margin-bottom: $indent-small;
    }

    .box-tocart {
        .fieldset {
            .box-stock-qty {
                @include make-flex;
                @include flex-direction(column);
                @include align-items(center);

                @media (min-width: $screen-xs-min) {
                    @include flex-direction(row);
                    @include align-items(flex-end);
                    @include flex-wrap(wrap);
                }

                + div {
                    color: gray; 
                    max-width: 490px; 

                    .sqm_per_unit_and_qty {
                        width: 150px; 
                        text-align: center; 
                        margin-right: 15px; 
                        display: inline-block; 
                    }

                    @media (max-width: $screen-xs-min) {
                        padding-bottom: 4px; 
                    }
                }
            }
        }

        .field.qty {
            @include flex-basis(100%);
            padding: 0;
            width: 100%;
            margin-bottom: $indent-small;

            @media (min-width: $screen-xs-min) {
                @include flex-basis(auto);
                width: auto;
            }

            > .label,
            > .control,
            > .product-item-unit {
                display: inline-block;
                vertical-align: middle;
                margin-top: 0;
                margin-right: 10px;
            }

            > .label,
            > .product-item-unit {
                font-size: $default-font-size;
                font-weight: 500;
                text-transform: uppercase;
            }

            > .label {
                @media (max-width: $screen-xs-min - 1) {
                    display: block;
                    margin-bottom: 5px;
                }
            }

            .control {
                input {
                    font-weight: 700;
                    text-align: center;
                    width: 10rem;
                    padding: 10px 15px;

                    @media (min-width: $screen-xs-min) {
                        margin-bottom: 0;
                        padding: 9px 18px 8px 18px;
                    }

                    &.disabled {
                        color: $unactive;
                        pointer-events: none;
                        cursor: default;
                    }
                }

                .mage-error:not(input):not(textarea) {
                    width: 100%;
                    position: absolute;
                    left: 0;
                    top: 100%;
                    text-align: left;

                    @media (min-width: $screen-xs-min) {
                        text-align: center;
                    }
                }
            }
        }

        .actions {
            @include make-flex;
            @include flex-direction(column);
            @include align-items(flex-start);

            @media (min-width: $screen-xs-min) {
                margin-top: 1rem;
                @include flex-direction(row);
                @include align-items(flex-start);
                @include flex-wrap(wrap);
            }
        }

        .added-to-wishlist,
        .add-to-wishlist {
            @include order(2);
            @include flex-basis(100%);
            margin-bottom: 2rem;
            width: 100%;
            text-align: center;
            cursor: pointer;

            @media (min-width: $screen-xs-min) {
                @include order(1);
                @include flex-basis(auto);
                width: auto;
                margin-left: $indent-base;
                margin-bottom: $indent-base;
            }

            .towishlist {
                @include button-block__white;
                display: block;
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 0.04em;
                min-width: 179px;
                padding: 12px 20px 12px !important;
                border: 0;

                span {
                    display: inline-block;
                }

                &:before {
                    @include font-icon('\f08a', 'fontAwesome');
                    margin-right: 5px;
                }

            }
               
        }

        .add-to-wishlist {
            .addTo {
                display: block;
                cursor: pointer;
            }
            .wishlist-added {
                display:none;
            }         
        }

        .added-to-wishlist {
            .addTo {
                display:none;            
            }
            .updated,
            .wishlist-added {
                display:block;
                cursor: pointer;

                &:before {                    
                    content: '\f004';
                    font-family: 'fontAwesome';
                    color: #F04544;
                }
            }
        }

        button.action, a.button-like.action {
            @include order(2);
            @include flex-basis(100%);
            font-size: 14px;
            width: 100%;
            margin-bottom: $indent-small;
            padding: 15px;
            text-align: center;

            @media (min-width: $screen-xs-min) {
                @include order(1);
                @include flex-basis(auto);
                min-width: 250px;
                width: auto;
                padding: 13px 15px;
            }

            &#product-updatecart-button {
                width: auto;
                margin-top: 7px;
            }

            &.disabled {
                background-color: $unactive;
                pointer-events: none;
                cursor: default;
            }

            &:before {
                font-size: 24px;
                vertical-align: top;
            }
        }
    }

    // Social Links
    .product-social-links {
        display: none;
        margin-bottom: 24px;

        .product-addto-links {
            @include make-flex;
            margin-bottom: 16px;
        }

        .action {
            background: transparent;
            color: $primary;
            font-size: 24px;
            line-height: 48px;
            text-align: center;
            display: block;
            margin-right: 28px;

            &:hover {
                color: $accent;
            }
        }

        .action span {
            display: none;
        }
    }


    // Attributes
    .product-info-main {
        > .product {
            &.attribute {
                font-size: $default-font-size;
                line-height: $default-line-height;
                margin-bottom: 5px;

                .type, .value {
                    font-weight: 500;
                    width: 50%;
                    display: inline-block;
                    vertical-align: top;
                    margin-right: -4px;
                }

                .type {
                    color: $text-light;


                    @media (min-width: $screen-sm-min) {
                        width: 40%;
                    }
                }

                .value {
                    @media (min-width: $screen-sm-min) {
                        width: 60%;
                    }
                }

                &.brand_id {
                    .product-brand + .value {
                        color: $secondary-color;
                        display: none;
                    }
                }

                &.price {
                    margin-top: 20px;
                    margin-bottom: 20px;
                }

                &.price {
                    .type {
                        line-height: 24px;
                        font-size: 24px;
                    }

                    > .type {
                        font-size: 15px;
                    }

                    > .value {
                        span.price {
                            font-size: 18px;
                            color: #F0B4B4;
                        }

                        .old-price {
                            text-decoration-color: #bebebe;

                            span.price {
                                font-size: 16px;
                                color: #bebebe;
                            }
                        }
                    }
                }
            }
        }
    }

    // Product Details
    .product-info-details {
        .detail-tab {
            @include make-flex;
            @include justify-content(flex-start);
            @include flex-direction(row);
            @include flex-wrap(wrap);
            position: relative;
            margin: 0 auto;
            padding: 0;
            border-bottom: 1px solid $dark-border-color;

            .detail-tab-item {
                position: relative;
                margin: 0;
                text-align: center;
                border: 1px solid $dark-border-color;
                border-bottom: none;
                border-radius: 5px 5px 0 0;

                &:not(:last-child) {
                    margin-right: 5px;
                }

                .detail-tab-link {
                    display: block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    font-size: 16px;
                    color: $primary;
                    background: rgba(40, 44, 42, 0.1);
                    padding: 15px 25px;
                    min-width: 100px;
                    position: relative;

                    &:hover {
                        background: rgba(40, 44, 42, 0.05);
                    }

                    &:after {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        display: block;
                        width: 0;
                        height: 4px;
                        background-color: $accent;
                        border-radius: 2px;
                        margin: auto;
                        content: '';
                        transition: width 0.4s;
                    }
                }

                &.tab-current {
                    border-bottom: 1px solid $white;
                    margin-bottom: -1px;

                    .detail-tab-link {
                        background: none;

                        &:after {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    // Product Info: Description
    .product.info.detailed {

        @media (min-width: $screen-sm-min) {
            padding: 30px 10px;
        }

        .data.items {
            position: relative;

            @media (min-width: $screen-sm-min) {
                display: table;
                margin-left: -$indent-small;
                margin-right: -$indent-small;
                border-spacing: $indent-small 0;
                border-collapse: separate;

                > .item {
                    width: 50%;
                    margin: 0 $indent-small / 2;
                    display: table-cell;
                    vertical-align: top;
                    border: 1px solid $dark-border-color;
                    padding: $indent-base $indent-base $indent-small $indent-base;
                    text-align: left;
                }
            }

            @media (min-width: $screen-md-min) {
                margin: 0 auto;
            }

            @media (max-width: $screen-sm-min - 1) {
                border-bottom: 1px solid $dark-border-color;

                > .item:first-child .title {
                    border-top: none;
                }
            }

            .title {
                border-top: 1px solid $dark-border-color;
                display: block;
                line-height: 1;
                font-weight: 700;
                margin-right: 0;
                margin-bottom: 0;
                padding-top: $indent-small;
                padding-bottom: $indent-small;
                padding-right: $gutter-width / 2;
                padding-left: $gutter-width / 2;
                text-transform: uppercase;

                h2 {
                    font-size: 20px;
                    color: $secondary-color;
                    margin-bottom: 0;

                    @media (min-width: $screen-sm-min) {
                        font-size: 24px;
                    }
                }

                @media (min-width: $screen-sm-min) {
                    display: inline-block;
                    border-top: none;
                    padding: 0;
                    margin-bottom: $indent-small;
                    pointer-events: none;
                    cursor: default;
                }

                &:before {
                    position: absolute;
                    top: 16px;
                    right: 15px;
                    width: 16px;
                    height: 16px;
                    text-align: center;
                    font-size: 16px;
                    line-height: 1;
                    @media (min-width: $screen-sm-min) {
                        display: none;
                    }
                }

                &[aria-expanded="false"]:before {
                    content: '\ea5a';
                }

                &[aria-expanded="true"]:before {
                    content: '\ea5b';
                }
            }

            .content {
                font-size: $default-font-size;
                line-height: $default-line-height;
                padding-right: $gutter-width / 2;
                padding-left: $gutter-width / 2;

                @media (min-width: $screen-sm-min) {
                    display: block !important;
                    width: 100%;
                    box-sizing: border-box;
                    margin-bottom: 0;
                    padding: 0;
                }

                &.active {
                    display: block;
                }

                &[role="tabpanel"] {
                    display: block;
                }

                .table-caption {
                    display: none;
                    text-align: left;
                    @media (min-width: $screen-sm-min) {
                        display: table-caption;
                    }
                }

                .description p {
                    text-indent: 0;
                }
            }

        }
    }

    // Product Info: Review
    .product.info.reviews {
        padding: 20px 0;

        .more-review {
            text-transform: uppercase;
            margin: 20px 0;
            text-decoration: underline;
            cursor: pointer;
            font-size: $default-font-size;

            &:hover {
                color: $accent;
            }
        }
    }

    // Tile Calculation
    .tile-calculation {
        padding: 10px 5px;
        margin-bottom: $indent-base;
        border: 1px solid $dark-border-color;
        width: 100%;

        @media (min-width: $screen-xs-min) {
            min-width: 490px;
            padding: 10px;
            display: inline-block;
            vertical-align: middle;
        }

        @media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
            min-width: 0;
            width: 100%;
        }

        .tile-calc-title {
            width: 100%;
            color: $secondary-color;
            font-size: 18px;
            font-weight: 600;
            padding-left: 5px;
            padding-right: 35px;
            position: relative;
            cursor: pointer;

            &[role="tab"] {
                margin-bottom: 0;

                &:before {
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    font-size: 14px;
                    margin-top: -10px;
                    color: $black;
                }

                &[aria-expanded="true"]:before {
                    content: '\ea5b';
                }

                &[aria-expanded="false"]:before {
                    content: '\ea5a';
                }
            }
        }

        .tile-calc-content {

            &[role="tabpanel"] {
                margin: 10px 0 0;
            }

            .calc-row {
                @include make-flex;
                @include align-items(center);
                @include flex-wrap(wrap);
                margin-bottom: 5px;

                label, > div {
                    padding: 5px;
                }

                label, span {
                    font-weight: 500;
                    font-size: $default-font-size;
                }

                label {

                    display: inline-block;

                    @media (min-width: $screen-xs-min) {
                        min-width: 125px;
                    }
                }

                label, .label {
                    color: $text-light;
                }

                > div span {
                    display: inline-block;

                    @media (min-width: $screen-sm-min) {
                        min-width: 3em;
                    }
                }

                .control {

                    @media (max-width: 767px) {
                        width: 30%;
                    }                
                }
                .unit,
                .label {

                    @media (max-width: 767px) {
                        width: 15%;
                        padding-left: 5px !important;
                        text-align: center;
                    }
                }

                input.input-text {
                    font-weight: 700;
                    width: 6rem;
                    max-width: 100%;
                    min-height: 0;
                    line-height: 1;
                    padding: 0 5px;
                    vertical-align: baseline;
                    background: $white;
                    border: 1px solid $dark-border-color;

                    @media (min-width: $screen-xs-min) {
                        padding: 0 10px;
                    }
                }

                span.count {
                    font-size: 16px;
                    font-weight: 700;
                    color: $secondary-color;
                    padding: 0 10px;
                }

                &.total-calc-qty {
                    margin: 10px 0;

                    span.count {
                        min-width: 6rem;

                        @media (max-width: $screen-xs-min - 1) {
                            text-align: center;
                        }
                    }
                }

                div.unit + div {
                    padding-left: 2em;
                }

                &.basic-area, &.extra-area {
                    @media (max-width: $screen-xs-min - 1) {
                        label {
                            width: 100%;
                            padding-bottom: 0;
                        }
                    }

                    @media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
                        label {
                            width: 100%;
                            padding-bottom: 0;
                        }
                    }

                    span.count {
                        text-align: center;
                    }
                }
            }
        }
    }

}


@media (min-width: 1024px) {
    .catalog-product-view .box-tocart .fieldset .box-stock-qty + div {
        padding-left: 250px; 
        &:lang(th) {
            padding-left: 260px; 
        }
    }
}

@media (max-width: 992px) {
    .product-info-main {
        .price {
            .timer-wrapper {
                max-width: none;
            }
        }
    }

    .catalog-product-view {
        .product-add-form {
            text-align: right;

            form {
                width: 60%;
                display: inline-block;
                text-align: initial;
            }
        }

        .box-tocart {
            .fieldset {
                > * {
                    margin-left: -4px;
                    margin-right: 4px;
                }

                .actions {
                    margin-left: -67%;
                    margin-right: 0;
                }

                .field.qty {
                    display: table;
                    width: 100%;

                    > .label {
                        display: none;
                    }

                    div.control,
                    span.product-item-unit {
                        display: table-cell;
                        vertical-align: middle;
                        width: 50%;
                    }

                    div.control {
                        input {
                            width: 100%;
                        }
                    }

                    span.product-item-unit {
                        padding-left: 10px;
                    }
                }

                .box-stock-qty {
                    + div .sqm_per_unit_and_qty {
                        width: 50%;
                        margin-right: 10px;
                    }
                }
            }

            .box-stock-qty {
                .product-info-stock-sku {
                    width: 100%;
                    margin-right: 0;

                    .stock {
                        width: 100%;
                        min-width: auto;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .catalog-product-view {
        .box-tocart {
            button.action,
            a.button-like.action {
                min-width: auto;
                width: 45%;
                padding: 13px 8px;
            }

            .add-to-wishlist {
                margin-left: 0;
                width: 55%;
            }
        }
    }
}

@media (max-width: 767px) {
    .catalog-product-view {
        .product-add-form {
            form {
                width: 50%;
            }
        }

        .box-tocart {
            .fieldset {
                .actions {
                    margin-left: -100%;
                }
            }
        }
    }

    .catalog-product-view {
        .price-attribute-persqm {
            .value {
                .price {
                    font-size: 20px;
                }
            }
        }

        .product-info-main {
            > .product.attribute.price {
                .price-attribute-persqm {
                    .type {
                        font-size: 20px;
                    }
                }

                > .value {
                    span.price {
                        font-size: 16px;
                    }

                    .old-price {
                        span.price {
                            font-size: 14px;
                        }
                    }
                }
            }

            .product-info-price {
                .price-box {
                    span.price {
                        display: inline-block;
                    }

                    .old-price {
                        span.price {
                            text-decoration: line-through;
                            text-decoration-color: #f04544;
                        }
                    }
                }
            }
        }

        .product-info-price {
            .price-box {
                .old-price {
                    span.price {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media (max-width: 479px) {
    .product-info-main {
        .persqm-price-top {
            @include make-flex();
            @include flex-wrap(wrap);

            .product-info-price {
                @include order(-1);
            }
        }
    }

    .price-attribute-persqm {
        margin: 0 0 10px;
    }

    .catalog-product-view {
        .product-info-main {
            > .product.attribute.price {
                > .type,
                .type {
                    line-height: 22px;
                }

                > .value {
                    .timer-wrapper {
                        margin: 10px 0 0 0;
                    }
                }
            }
        }

        .product-info-price {
            .price-box {
                line-height: 22px;
            }
        }
    }
}