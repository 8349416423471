//
// Sidebar reorder
//-----------------------------------
#reorder-validate-detail{
    strong.subtitle{
        display: none;
    }
    .no-display{
        display: block;
    }

    .actions-toolbar .primary{
        margin-bottom: 4px;
    }
    .actions-toolbar .secondary a{
        font-weight: 700;
    }
    .action.tocart{
        width: 100%;
        text-align: center;
        padding: 14px 33px;

        @media (min-width: $screen-xs-min) and (max-width: $screen-md-min - 1){
            width: auto;
        }
    }
}

#cart-sidebar-reorder{
    margin-bottom: 8px;

    .product-item{
        padding: 8px 0;
        line-height: 23px;
        @include make-flex;
        &:first-child{
            padding-top: 0;
        }
    }
    .field{
        padding: 0;
    }
    .item.choice label span{
        display: none;
    }
    .checkbox{
        margin: 0;
        vertical-align: middle;
    }
    .product-item-name{
        padding: 0 4px 0 12px;
        font-size: 14px;
        line-height: 18px;
    }
    .product-item-link{
        color: $text-dark;
        &:hover{
            color: $primary;
        }
    }
}

#cart-sidebar-reorder {
    .control input[type=checkbox]{
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    .control .label {
        &:before{
            display: inline-block;
            font-size: 16px;
            line-height: 1rem;
            width: 18px;
            height: 18px;
            font-style: normal;
            vertical-align: baseline;
            color: $primary;
            text-align: center;
            padding-top: 2px;
        }
    }

    input[type=checkbox] + .label{
        &:before{
            @include font-icon__pseudo('\e835', $material-icons);
        }
    }
    input[type=checkbox]:checked + .label:before{
        @include font-icon__pseudo('\e834', $material-icons);
    }
    input[type=checkbox]:disabled:checked + .label:before{
        @include font-icon__pseudo('\e834', $material-icons);
        pointer-events: none;
        opacity: .6;
    }
}


// Order Histroy
//-----------------------------------

@import "order/_history.scss";

// Order Histroy
//-----------------------------------

@import "order/_view.scss";
