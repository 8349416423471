//
//  Series
//  ---------------------------------------------

.block.series{
    @include flex-basis(100%);
    margin-top: 12px;
    margin-bottom: 5px;
    overflow: hidden;

    .block-title,
    .block-content {
        font-size: 16px;
        font-weight: 500;
        width: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-right: -4px;
    }
    .block-title {
        color: $text-light;
        text-transform: none;
        margin-bottom: 0;
        
        @media (min-width: $screen-sm-min){
            width: 40%;
        }
    }

    .block-content {
        @media (min-width: $screen-sm-min){
            width: 60%;
        }
    }

    .block-actions .select{
        padding: 0;
        background: transparent;
        color: $primary;
        &:hover{
            color: $text-light;
        }
    }

    .product-item-details {
        display: none;
    }
}

.products-grid.products-series{
    padding: 0;

    .product-items {
        position: relative;
        max-width: 200px;
        padding: 0;
        margin: 0 -5px 0 0;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;

        > .product-item {
            @include flex-basis(33.3333%);
            padding-right: 10px;
        }
    }
    .product-item {
        @include flex-basis(100%);
        padding: 0;
        margin-top: 0;
        margin-right: 0;
        padding-right: 10px;
        text-align: left;

        &:hover {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            -ms-transform: none;
            -webkit-transform: none;
            transform: none;
        }

        .product-item-photo {
            position: relative;
            padding: 0;
            height: 100%;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
            }
            &:hover {
                &:before {
                    border: 1px solid $black;
                }
            }

            .image {
                vertical-align: middle;
            }
        }

        .price-box {
            text-align: left;
        }
    }

    .owl-stage {
        margin-bottom: 0;
    }

    .owl-item {
        margin-top: 0;
    }

    .owl-item:hover {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    .owl-nav {
        position: static;

        .owl-prev,
        .owl-next{
            position: absolute;
            width: 24px;
            height: 24px;
            top: 50%;
            margin-top: -12px;
            background-color: $black;
            opacity: 0.6;
            z-index: 3;

            @media (min-width: $screen-xs-min){
                width: 32px;
                height: 32px;
                margin-top: -16px;
            }

            &:before {
                position: absolute;
                font-size: $default-font-size;
                line-height: 24px;
                background-color: transparent;
                width: 24px;
                height: 24px;
                border: none;

                @media (min-width: $screen-xs-min){
                    width: 32px;
                    height: 32px;
                    line-height: 32px;
                }
            }
            &:hover {
                background-color: $third-color;

                &:before{
                    color: $white;
                }
            }
        }

        .owl-prev {
            left: 1px;
            border-radius: 0 4px 4px 0;

            &:before {
                left: 0;
            }
        }

        .owl-next {
            right: 11px;
            border-radius: 4px 0 0 4px;

            &:before {
                right: 0;
            }
        }

        .owl-prev.disabled,
        .owl-next.disabled {
            display: none;
        }
    }
}