#notice-cookie-block {
    position: fixed;
    bottom: 0;
    display: block;
    color: $sm-header-text-color;
    background: $white;
    box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, .25);
    z-index: 13;
    width: 100%;

    .content {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

#btn-cookie-allow {
    float: right;
    margin-left: 15px;

    > span {
        color: $black;

        &:hover {
            color: $secondary-color;
        }

        &:after {
            font-family: $default-icon-family;
            content: "\ea5c";
            @include transform(rotate(0deg) scale(1));
            opacity: 1;
            visibility: visible;
        }
    }
}