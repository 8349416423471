.rd-parallax {
    position: relative;

    .background-video {
        background-position: top center;
        background-repeat: no-repeat;
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: fixed;
        right: 0;
        top: 0;
    }

    video,
    source {
        bottom: 0;
        left: 0;
        min-height: 100%;
        min-width: 100%;
        position: absolute;
    }

    .loaded .ytplayer-container {
        display: block;
    }

    .loaded .placeholder-image {
        opacity: 0;
    }

    .ytplayer-container {
        bottom: 0;
        height: 100%;
        left: 0;
        min-width: 100%;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 3;
    }

    .placeholder-image {
        height: 100%;
        left: 0;
        min-height: 100%;
        min-width: 100%;
        position: fixed;
        top: 0;
        z-index: 1;
    }

    .ytplayer-shield {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
    }

    .ytplayer-player {
        position: absolute;
    }
}


.rd-parallax-inner {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    clip: rect(0, auto, auto, 0); }
.rd-parallax-layer[data-type="media"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%; }
.rd-parallax-layer[data-type="media"] iframe {
    width: 100%;
    height: 100%; }
.rd-parallax-layer[data-url] {
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center; }
.rd-parallax-swiper {
    z-index: 1 !important; }
.static-video{
    height: 100%;
}
.rd-parallax-inner .static-video-layer {
    position: absolute !important;
}
.parallax-container{
    position: relative;
}
