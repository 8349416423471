//
// Customer account order view
//-----------------------------------
.order-details-items{
    margin-bottom: $indent-base;

    .order-title {
        display: none;
    }

    .order-info {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: $indent-small;

        > div {
            padding-bottom: $indent-small;
        }
    }

    table:not(.totals).table:not(#product-comparison).table-order-items{
        caption{
            display: none;
        }

        thead th {
            font-size: 16px;
            border-top: solid 1px $dark-border-color;
            border-bottom: solid 1px $dark-border-color;
        }

        tfoot {
            border-top: solid 1px $dark-border-color;
            border-bottom: solid 1px $dark-border-color;

            tr {
                &:not(.grand_total) th,
                &:not(.grand_total) td:before {
                    color: $text-light;
                }

                &.grand_total,
                &.grand_total td:before {
                    font-size: $default-font-size;
                    font-weight: 700;
                    @media (min-width: $screen-sm-min) {
                        font-size: 16px;
                    }
                }

                + tr td,
                + tr th {
                    padding-top: 0;
                }
            }
        }

        @media (min-width: $screen-sm-min){
            th, td {
                border-left: solid 1px $dark-border-color;

                &:first-child {
                    border-left: solid 1px $dark-border-color;
                }

                &:last-child {
                    border-right: solid 1px $dark-border-color;
                }
            }

            tbody + tbody tr td {
                padding-top: 0;
            }
        }
        
        @media (max-width: $screen-sm-min - 1){
            tbody tr {
                border-top: solid 1px $dark-border-color;
            }
            
            td {
                width: 100%;
                display: block;
                text-align: right;
                background: transparent;

                &:before{
                    content: attr(data-th);
                    font-size: $default-font-size;
                    float: left;
                    border-bottom: 0;
                }

                + td {
                    padding-top: 0;
                }
            }

            td.name:before,
            td.subtotal {
                display: none;
            }
        }

        .product-item-name {
            display: block;
            width: 100%;
            font-size: $default-font-size;
            font-weight: 700;
            text-align: left;
        }

        .item-options{
            display: block;
            dt, dd{
                display: inline;
                font-size: 12px;
            }
            dt:after{
                content: ':';
            }
            dd{
                padding: 0 8px 0 4px;
                &:after{
                    content: ';';
                }
            }
        }
    }

    .block-order-details-view {
        margin-top: 0;
        padding-top: $indent-base;

        .block-title {
            text-transform: none;
            margin-bottom: $indent-base;
        }

        .box-order-shipping-address,
        .box-order-billing-address {
            .box-title {
                text-transform: uppercase;
            }
        }
        @media (min-width: $screen-sm-min){
            margin-top: $indent-base + 1;
            border-top: 1px solid $border-color;

            .box-order-shipping-address {
                @include order(1);
            }
            .box-order-shipping-method {
                @include order(2);
            }
            .box-order-billing-address {
                @include order(3);
            }
            .box-order-billing-method {
                @include order(4);
            }
        }

        .box-order-billing-method {
            table:not(.totals).table:not(#product-comparison) {
                caption {
                    display: none;
                }

                tbody tr {
                    background: transparent;

                    th, td {
                        width: auto;
                        display: table-cell;
                        vertical-align: top;
                        padding: 0 10px 0 0;
                        border-left: 0;
                    }

                    @media (max-width: $screen-sm-min - 1){
                        td:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}