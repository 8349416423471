//
// Forgot Password
//-----------------------------------

.form.password.forget {
    max-width: 800px;
    margin: 0 auto $indent-base auto;

    .field {
        &.note {
            padding: 0;
            margin-bottom: $indent-small;
        }

        &.captcha {
            .captcha-image {
                margin-top: 10px;

                .captcha-img,
                .captcha-reload {
                    display: inline-block;
                    vertical-align: middle;
                    margin-bottom: $indent-small;
                }

                .captcha-img {
                    margin-right: $indent-base;
                }
            }
        }
    }

    .actions-toolbar{
        @include make-flex;
        @include flex-direction(row-reverse);
        @include justify-content(flex-end);
        margin-top: $indent-small;
        margin-bottom: $indent-base;

        @media (min-width: $screen-sm-min) {
            margin-bottom: $indent-large;
        }

        .primary + .secondary {
            margin-right: $indent-base;
        }

        .action {
            &.back {
                padding: 9px 15px;

                @media (min-width: $screen-lg-min){
                    padding: 11px 15px 10px;
                }

                &:before {
                    display: none;
                }
            }
        }
    }
}