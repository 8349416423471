.block.crosssell{
    margin-top: 50px;

    .owl-controls{
        top: -30px;
    }
}
.products-grid.products-crosssell{
    margin: 2rem 0 0;
    padding: 0;

    .product-items{
        margin: 0 -15px;
        padding: 0;
    }
    .product-item{
        @include flex-basis(100%);
        padding: 0;
        margin-top: 0;
        .price-box {
            text-align: center;
        }
    }

    .owl-item{
        padding: 0 15px;
    }
}
