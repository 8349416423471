//
//    Compare Products Page
//--------------------------------------

body.catalog-product-compare-index {
    .action.print {
        float: right;
        margin: $indent-small 0;
    }
    .table-caption{
        display: none;
    }
}

.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
    .table-comparison > tbody > tr{
        border-right: 1px solid $border-color;
        > th,
        > td {
            border-top: 0;
        }
    }
    td{
        @media (max-width: $screen-sm-min){
            border-bottom: 0;
            display: table-cell;
            padding: 0;

            &.product:before{
                display: none;
            }
        }
    }
}

table#product-comparison {
    table-layout: fixed;

    thead tr th,
    thead tr td{
        border-top: none;
        border-bottom: none;
        border-left: none;
        border-right: 1px solid $border-color;
        span {
            display: none;
        }
    }

    tbody tr:nth-child(odd){
        background: transparent;
    }
    tbody tr{
        border-bottom: 1px solid $border-color;
        &:last-child{
            border-bottom: none;
        }

        th.label{
            font-size: 14px;
            line-height: 16px;
            font-weight: 700;
            color: $text-light;
        }
    }

    .cell {
        width: 200px;
        padding: $indent-small;
        .attibute.value {
            width: 100%;
            overflow: hidden;
        }

        &.product.info,
        &.product.label {
            border-bottom: 1px solid $border-color;
        }
        &.label.product span{
            display: none;
        }
        &.label {
            width: 120px;
            .attribute.label {
                display: block;
                width: 100%;
                word-wrap: break-word;
            }
        }
        &.attribute {
            img {
                max-width: 100%;
                height: auto;
            }
        }
        &.remove {
            padding-top: 0;
            padding-bottom: 0;
            text-align: right;
            .action.delete {

            }
        }
    }

    .product-item-photo {
        display: block;
        margin: 0 auto $indent-small;
    }
    .product-image-photo {
        margin-left: 0;
    }

    .product-item-name a{
        font-size: 14px;
        line-height: 18px;
        color: $text-light;
        &:hover{
            color: $primary;
        }
    }

    .product-reviews-summary{
        @include make-flex;
        @include align-items(center);
        margin-bottom: 4px;

        .label{
            display: none;
        }
        .rating-summary{
            padding-right: 8px;
            line-height: 18px;
        }
        .reviews-actions{
            font-size: 12px;
            line-height: 18px;
        }
    }

    .price-box{
        font-size: 20px;
        line-height: 24px;
        color: $text-light;
        font-weight: 700;
        margin-bottom: 12px;
    }

    .product-item-actions{
        @include make-flex;

        .actions-primary{
            padding-right: 8px;
        }

        .tocart{
            width: 50px;
            height: 50px;
            border-radius: 50px;
            padding: 4px;
            text-align: center;
            margin-bottom: 0;
            vertical-align: text-bottom;
        }
        .tocart span{
            display: none;
        }

        .towishlist{
            background-color: transparent;
            font-size: 14px;
            line-height: 48px;
            color: $white;
            display: block;
            width: 50px;
            height: 50px;
            border-radius: 50px;
            background: $primary;
            text-align: center;
            vertical-align: text-bottom;
            &:hover{
                color: $white;
                background: $secondary-color;
            }
        }
    }

    .price-box,
    .product.rating,
    .product-item-name {
        display: block;
    }

    .product-addto-links {

        .action.toggle {
            padding: 0;
        }
    }

    .action {
        &.tocart {
            white-space: nowrap;
            margin-bottom: 15px;
        }
    }
}

.comparison.headings {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: auto;
    display: none;
}

