
.timer-wrapper {
    border: none;
    background: $secondary-color;
    color: $white;
    font-weight: 700;
    max-width: 300px;
    padding: 0;
    margin: 0;
    font-size: 22px;
    line-height: 24px;
    @include make-flex;
    @include justify-content(center);
    @include align-items(center);

    > div {
        @include make-flex;
        @include justify-content(center);
        @include align-items(flex-start);

        div {
            display: inline-block;
            position: relative;
            padding: 5px;
            font-size: 14px;
            line-height: 16px;

            span:nth-child(2) {
                display: block;
                font-weight: 400;
                font-size: 12px;
            }
        }

        b {
            font-weight: 400;
            font-size: 12px;
            margin-top: 3px;
        }
    }
}

.products-grid .product-item {
    .timer-wrapper {
        position: absolute;
        width: 100%;
        bottom: 0;
        z-index: 1;
    }
}

.product-info-main {
    .timer-wrapper {
        max-width: 200px;
    }
}