//
//  Toolbar
//  ---------------------------------------------
.amscroll-navbar {
    z-index: 9 !important;
}

.toolbar {
    @include make-flex();
    @include justify-content(space-between);
    @include flex-wrap(wrap);
    font-size: 14px;
    border-bottom: 1px solid $dark-border-color;
    padding-bottom: 20px;

    p,
    .field {
        @include make-flex();

        .label {
            margin-right: 10px;
            color: $secondary-color;
            font-weight: 600;
            font-size: 15px;
            line-height: 15px;
            text-transform: uppercase;
        }

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            .label {
                padding-top: 16px;
               line-height: 24px;                
            }
        }


    }

    // Items order
    .field.toolbar-sorter {
        padding: 0;
        @include order(3);

        select {
            font-size: 14px;
        }
    }

    p.toolbar-amount {
        @include order(2);
    }

    .field.limiter {
        @include order(4);
    }

    .modes {
        @include order(1);
    }

    @media (min-width: $screen-sm-min) {
        .field.toolbar-sorter {
            @include order(3);
        }
        p.toolbar-amount {
            @include order(1);
        }
        .field.limiter {
            @include order(5);
            @include flex-basis(50%);
            @include justify-content(flex-end);
        }
        .modes {
            @include order(2);
        }
    }
    @media (min-width: $screen-md-min) {
        .field.toolbar-sorter {
            @include order(2);
            margin: 0;
        }
        p.toolbar-amount {
            @include order(1);
            @include flex-basis(auto);
            margin-right: auto;
        }
        .field.limiter {
            @include order(3);
            @include flex-basis(auto);
            margin-left: auto;
        }
        .modes {
            @include order(4);
        }
    }
    @media (max-width: $screen-sm-min - 1) {
        p.toolbar-amount {
            display: none;
        }
    }
}

//Sorter
.field.toolbar-sorter {
    @include justify-content(flex-start);
    @include align-items(center);

    @media (min-width: $screen-md-min) {
        @include flex-basis(auto);
    }

    @media (max-width: $screen-sm-min - 1) {
        @include flex-wrap(wrap);
        @include flex-basis(100%);
        margin: 20px 0 0 0;
        @include justify-content(flex-end);
    }

    .control {

        @media (min-width: $screen-sm-min) {
            @include flex-grow(1);
            max-width: 165px
        }

        .select2-container {
            width: 155px !important;
            @media (min-width: $screen-sm-min) {
                max-width: 165px
            }
        }

        .select2-container .select2-selection--single {
            height: 40px;

            .select2-selection__rendered {
                line-height: 40px;
            }

            .select2-selection__arrow {
                height: 40px;
                right: 4px;
            }
        }
    }

    .sorter-action {
        margin-left: $indent-small;

        span {
            display: none;
        }

        &.sort-asc,
        &.sort-desc {
            @include font-icon('\ee7d');
            font-size: 14px;
            line-height: 1;
            color: $secondary-color;
        }

        &.sort-desc {
            transform: rotate(180deg);
        }
    }
}

//Amount
p.toolbar-amount {
    @include make-flex();
    @include justify-content(center);
    @include align-items(center);
    @include flex-basis(100%);
    margin: 16px 0;
    font-size: 15px;

    @media (min-width: $screen-sm-min) {
        @include flex-basis(auto);
    }

    span.toolbar-number {
        &:first-child,
        &:nth-child(2),
        &:last-child {
            margin-right: 2px;
            margin-left: 2px;
        }
    }
}

//Limiter
.field.limiter {
    @include flex-basis(100%);
    @include justify-content(flex-start);
    @include align-items(center);
    margin-bottom: 0;

    @media (min-width: $screen-md-min) {
        @include flex-basis(auto);
        @include justify-content(flex-start);
        margin-left: auto;
    }

    @media (max-width: $screen-sm-min - 1) {
        @include flex-wrap(wrap);
    }

    .label {
        @media (max-width: $screen-sm-min - 1) {
            display: block;
            @include flex-basis(100%);
            margin-bottom: 8px;
        }
    }

    .control {
        @include flex-grow(3);
        @media (min-width: $screen-sm-min) {
            @include flex-grow(0);
        }

        .select2-container {
            max-width: none;
            @media (min-width: $screen-sm-min) {
                max-width: 250px;
            }
        }
    }

    .limiter-text {
        display: none;
    }
}


// Modes
.modes {
    @include make-flex;
    @include justify-content(flex-end);
    @include align-items(center);
    @include flex-basis(130px);
    margin-bottom: 0;

    @media (min-width: $screen-md-min) {
        @include flex-basis(auto);
    }

    .modes-label {
        display: none;
    }

    .modes-mode {
        margin-left: 8px;
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 4px;

        &:before {
            font-size: 14px;
            line-height: 50px;
            text-align: center;
            width: 100%;
        }

        &:not(.active) {
            border: 1px solid $border-color;

            &:hover {
                color: $link-hover;
            }
        }

        &.active {
            color: $text-lightest;
            background: $bg-dark;
        }

        span {
            display: none;
        }
    }

    .mode-grid {
        @include font-icon('\ea88');
    }

    .mode-list {
        @include font-icon('\ea9d');
    }
}

//go to page
.gotopage {
    display: none;

}

//Pages
.pages {
    display: none;
    margin-bottom: 0;
    padding: 8px 0;

    .pages-items {
        @include make-flex;
        @include align-items(center);
        @include justify-content(center);
        @include flex-wrap(wrap);
        @include order(1);

        li {
            margin-bottom: 4px;

            + li {
                margin-left: 4px;
            }
        }
        @media (max-width: $screen-mob-min - 1){
            .item {
                &.pages-item-previous, 
                &.pages-item-next {
                    display: list-item;
                }
            }
        }
    }

    .item {
        a,
        strong {
            display: block;
            text-align: center;
            font-size: 12px;

        }

        a,
        &.current strong {
            line-height: 32px;
            font-weight: 400;
            height: 32px;
            width: 32px;
            background: #FFE0E2;
            color: $black;

            &:hover {
                background: $secondary-color;
            }
        }


        @media (max-width: $screen-mob-min - 1){
            a,
            &.current strong {            
                width: 30px;
            }
        }

        &.current strong {
            background: $secondary-color;
        }

        &.pages-item-previous,
        &.pages-item-next {
            a {
                background: #D0D0D0;
                color: $white;

                &:hover {
                    color: $default;
                }

                .label {
                    display: none;
                }
            }

            a span:not(.label) {
                @include font-icon();
            }
        }

        &.pages-item-previous a span:not(.label):before {
            content: '\e927';
        }

        &.pages-item-next a span:not(.label):before {
            content: '\e928';
        }
    }
}


.tollbar-bottom {
    margin-bottom: 15px;

    .pages {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 15px auto 0 auto;

        .gotopage {
            margin: -2px 0 0 20px;
            display: block;
            order: 2;    

            >p {
                margin: 20px auto;

                .indxof {
                    display: none;
                }


                @media (max-width: $screen-mob-min - 1){
                    .indxof {
                        padding-right: 20px;
                        display: inline-block;

                        .sep-text {
                            width: 20px;
                            line-height: 32px;
                        }
                    }
                }

                .pageNum {
                    padding: 5px 5px 5px 8px;
                    width: 60px;
                    height: 32px;
                    border: 0;
                    font-size: 14px;
                }
                
                .gobt {
                    width: 32px;
                    height: 32px;
                    background: #ccc;
                    margin-left: 4px;
                }
            }
        }

        @media (max-width: $screen-mob-min - 1){
            .gotopage {
                margin: 0 auto;
            }           
        }
    }

     @media (max-width: $screen-mob-min - 1){
        .pages {          
            flex-direction: column;
        }                    
    }

    .toolbar-amount,
    .toolbar-sorter,
    .field.limiter,
    .modes {
        display: none;
    }

    .toolbar {
        @include justify-content(center);

        @media (min-width: $screen-sm-min) {
            @include justify-content(space-between);
        }
    }

    .field.limiter {
        @media (min-width: $screen-sm-min) {
            @include make-flex;
            @include order(2);
            @include flex-basis(auto);
            margin-left: auto;
        }
    }

    .modes {
        @media (min-width: $screen-sm-min) {
            @include make-flex;
            @include order(3);
            @include flex-basis(auto);
        }
    }
}

