// Inherit styles from the parent theme
@import '../../../../scg/web/css/source/modules.scss';



// Variables and mixins for mobile theme
// e.g. @import '_mobile-variables.scss';



// Additional styling for mobile theme
// e.g. @import '../../../Magento_Theme/web/css/source/_modules.less';


