// Variables

$font: inherit;

// Colors
$color: $text-dark;
$title-color: $color;
$link-color: $primary;
$post-data-color: $default;
$data-border-color: $border-color;

// Title
$title-size: 15px;
$title-lh: 1.5;

// Comments
$comment-bg: #f4f4f4;
$comment-indent: 30px;
$comment-margin: 20px;
$comment-title-size: 24px;
$comment-title-margin: 34px;
$commetn-border: #e8e8e8;

$label-width: 90px;

// Related Post

$post-title-size: 18px;
$post-title-height: 51px;
$post-title-color: #000000;
$post-title-border: #e8e8e8;
$post-title-color-hover: $post-title-border;

// Page blog

$color-link: #fff;
$border-page-color: #cdcdcd;
$border-page-color-current: #000000;