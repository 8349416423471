// Main styles

.block-posts-list {
    clear: both;
    font-family: $font;

    .page-main {
        margin-bottom: 0;
    }

    .post-items {
        display: flex;
        -webkit-display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0 -15px;

        .post-item {
            @include flex-basis(100%);
            list-style: none;
            padding: 0 15px;
            box-sizing: border-box;
            margin: 0 0 30px;

            @media (min-width: 768px) and (max-width: $screen-md-min){
                @include flex-basis(50%);
            }
            @media (min-width: $screen-md-min){
                @include flex-basis(50%);
            }
            @media (min-width: $screen-md-min){
                @include flex-basis(33.3333%);
            }
        }
        // Image
        .post-image {
            margin-bottom: 15px;

            img {
                width: 100%;
                vertical-align: top;
            }
        }

        // Title
        .post-title {

            h3 {
                font-family: $font;
                font-size: $title-size;
                line-height: $title-lh;
                font-weight: 600;
                margin: 0;

                a {
                    color: $title-color;
                    text-decoration: none;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;  
                    overflow: hidden;
                    max-height: 44px; 
                }
            }
        }

        // Short Content
        .post-short-content {
            font-size: inherit;
            line-height: inherit;
            margin-bottom: 26px;
        }

        // Post link
        .post-link {
            color: $link-color;
            font-weight: 700;
            text-transform: none;

            &:hover {
                color: lighten($color, 40%);
            }
        }

        // Post data
        .post-data {
            @include make-flex;
            @include justify-content(space-between);

            .create-date,
            .post-author,
            .post-comments {
                a {
                    text-decoration: none;
                    &:hover {
                        color: $color;
                    }
                }
                a span:before,
                &:before {
                    font-size: 18px;
                    padding-right: 4px;
                    color: $color;
                    vertical-align: middle;
                }
            }
            .create-date {
                @include order(2);
                margin-right: auto;

                time {
                    display: inline-block;
                }
            }
            .post-author {
                @include order(1);
                font-weight: 700;
            }
            .post-comments {
                @include order(3);
                @include flex-basis(50px);
                @include font-icon('\ea7b');
                text-align: right;
            }

            @media (max-width: 1200px) {
                display: block;
                overflow: hidden;

                .create-date,
                .post-author {
                    display: block;
                }

                .post-comments {
                    float: right;
                }
            }
        }

        @media (max-width: 768px) {
            .post-item {
                flex-basis: 50%;
                -webkit-flex-basis: 50%;
            }
        }
        @media (max-width: 350px) {
            .post-item {
                flex-basis: 100%;
                -webkit-flex-basis: 100%;
            }
        }
    }
}

.posts-list-wrapper {

    /*Toolbar
    **********************************************/
    .toolbar-top {
        margin-bottom: 30px;

        .toolbar {
            margin-bottom: 0;
            text-align: left;
        }
        .sorter {
            float: left;
            margin-bottom: 5px;
        }
        .limiter {
            display: block;
            margin-bottom: 5px;
            text-align: right;
        }
        .pages{
            display: none;
        }
    }

    .pages-items {
        li + li {
            margin: 0;
        }
    }

    .pages {
        .pages-items {
            li {
                margin: 0;
                border-right: 1px solid $border-page-color;

                &.pages-item-previous {
                    border-left: 1px solid $border-page-color;

                    a {
                        color: $black;

                        &:hover {
                            color: $white;
                        }

                        span:not(.label):before {
                            content: '<<';
                            font-family: inherit;
                            font-weight: bold;
                        }
                    }
                }

                &.current {
                    border-right-color: $border-page-color-current;
                }
            }
        }

        .item {
            a {
                background: none;
                border-top: 1px solid $border-page-color;
                border-bottom: 1px solid $border-page-color;
            }

            &.current {
                strong {
                    border-top: 1px solid $border-page-color-current;
                    border-bottom: 1px solid $border-page-color-current;
                    background: $border-page-color-current;
                    color: $color-link;
                }
            }

            a:hover,
            &.current strong:hover {
                background: $border-page-color-current;
                border-color: $border-page-color-current;
                color: $color-link;
            }
        }
    }
    .toolbar-bottom {
        .toolbar {
            margin-bottom: 0;
            border: 0;

            .pages {
                text-align: center;
                width: 100%;
                margin: 0;
                display: block;

                .pages-item-next {
                    position: relative;
                }
            }
        }
        .sorter,
        .limiter {
            display: none;
            visibility: hidden;
        }
    }
}

.ie11 .block-posts-list .post-items .post-title h3 a {
    position: relative;
    height: 44px; 
    display: block; 

    &:after {
        content: "";
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 50%;
        height: 22px;
        display: inline-block; 
        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 80%);
    }
}