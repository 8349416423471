//
//  Rating summary
//  ---------------------------------------------------

.rating-summary {
    @include make-flex();

    .label {
        margin-right: $indent-small;
    }

    .rating-result__stars {
        @include make-flex;
        @include align-items(center);
        position: relative;

        &:before {
            font-family: $material-icons;
            font-size: 20px;
            content: '\e838 \e838 \e838 \e838 \e838';
            color: $text-lightest;
            line-height: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }

        span[class^="rating-star__"] {
            font-size: 14px;
            line-height: 1;
            position: relative;
            display: block;
            overflow: hidden;

            &:before {
                font-family: $material-icons;
                font-size: 20px;
                content: '\e838 \e838 \e838 \e838 \e838';
                color: $review-star;
                line-height: 1;
                white-space: nowrap;
            }
        }
    }

    .rating-result__text {
        display: none;
    }

    &.aggregated-rating .rating-result__text {
        display: inline-block;
    }
}

.product-reviews-summary.empty .rating-summary .rating-result__stars:before {
    position: relative;
}

//
//  Review Tags
//  ---------------------------------------------------

.review-tags {
    margin-bottom: 5px;

    .review-tag {
        border-radius: 15px;
        border: 1px solid $text-light;
        color: $text-light;
        padding: 3px 10px;
        font-size: 12px;
        display: inline-block;
        margin: 0 5px 5px 0;
    }
}

//
//  Review List
//  ---------------------------------------------------

.review-list {
    .block-title {
        @include make-flex();
        @include justify-content(flex-start);
        @include align-items(center);
        @include flex-wrap(wrap);
        border-bottom: 1px solid $dark-border-color;
        margin-bottom: 0;
        padding-bottom: 10px;

        .review-list-title {
            font-size: 20px;
            line-height: 1;
            font-weight: 700;
            color: $primary;
            margin-bottom: 0;
            margin-right: 15px;
        }

        .product-reviews-summary {
            @include make-flex;
            @include align-items(center);
            height: 40px;

            .rating-result__stars {
                margin-right: 5px;
            }

            .rating-result__text {
                letter-spacing: 0;
            }
        }
    }

    .block-content {
        font-size: $default-font-size;
    }

    .review-title {
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
        margin: 3px 0 4px 0;
        word-break: break-word;

        @media (max-width: $screen-md-min - 1) {
            margin-top: 15px;
        }
    }

    .review-item {
        margin: 0;
        padding: 20px 0;
        border-bottom: 1px solid $dark-border-color;
        font-size: $default-font-size;

        .review-col {
            padding: 0;

            &:first-child {
                padding-right: 15px;
            }
        }
    }

    //Rating
    .rating-summary {
        @include make-flex();

        .rating-result__stars:before, .rating-result__stars span[class^="rating-star__"]:before {
            font-size: $default-font-size;
        }

        .rating-result__text {
            font-size: $default-font-size;
            color: $text-light;
        }
    }

    .rating-label {
        margin-right: 24px;
    }

    .rating-result {
        @include make-flex();
        @include justify-content(flex-start);
        @include align-items(flex-end);

        @media (max-width: $screen-md-min - 1) {
            margin-top: 5px;
        }
    }

    .review-author {
        font-weight: 700;
        word-break: break-word;
    }

    //Details
    .review-details {
        @include make-flex;

        p + p {
            margin-left: 4px;
        }

        p > * {
            display: inline-block;
        }

        .review-author .review-details-value {
            text-transform: uppercase;
            font-weight: 700;
        }
    }

    .review-content {
        word-break: break-word;
        color: $text-light;
    }

    .review-date {
        font-size: 12px;
        color: $text-light;
        margin: 10px 0 0 0;
    }

    .review-tags {
        @media (max-width: $screen-md-min - 1) {
            margin-top: 10px;
        }
    }
}

@media (min-width: $screen-md-min) {

    .review-list {
        .block-title {
            .review-list-title {
                font-size: 24px;
            }
        }
    }

}

//
// Review CTA
// ---------------------------------------------------

.review-cta {
    margin: 20px auto;
    text-align: center;
    width: 100%;

    button.action {
        font-size: 18px;

        @media (min-width: $screen-md-min) {
            font-size: 22px;
        }
    }
}

//
//  Review Form
//  ---------------------------------------------------

.review-add {
    margin: 0 auto;
    max-width: 500px;
    padding: 25px 0 0 0;

    .block-title {
        font-size: 18px;
        line-height: 20px;
        font-weight: 700;
        color: $primary;
        text-align: center;
    }

    .review-legend {
        font-size: 16px;
        line-height: 18px;
        width: 100%;
        text-align: center;

        span {
            font-weight: 700;
            display: block;
            margin-bottom: 8px;
        }
    }

    .review-field-ratings {
        text-align: center;
        margin-bottom: 0;

        .review-field-rating {
            margin-bottom: 10px;
        }
    }

    .field[class*=review-field]:not(.review-field-ratings):not(.review-field-rating) {
        margin-bottom: 8px;
        padding: 0;

        &:last-child {
            margin-bottom: 24px;
        }
    }

    .review-field-text textarea {
        min-height: 100px;
        resize: none;
        @media (min-width: $screen-sm-min) {
            min-height: 200px;
        }
    }

    .review-form-actions {
        text-align: center;
    }

    .review-tags {
        margin-bottom: 0;

        .review-tag {
            cursor: pointer;

            &:hover {
                border-color: #505050;
                color: #505050;
            }

            &.selected {
                border-color: $accent;
                color: $accent;
            }
        }
    }
}

.review-field-ratings {
    margin-bottom: 4px;
    @include clearfix;

    //> .label,
    //> .control{
    //    float: left;
    //}
    .review-field-rating {
        .label {
            display: inline-block;
            width: 80px;
        }
    }

    .field {
        padding: 0;
    }

    > .label {
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        margin-right: 24px;
    }

    [id="Rating_rating_label"] {
        display: none;
    }
}

.control.review-control-vote {
    display: inline-block;

    label[class^='rating-'] {
        display: inline-block;
        cursor: pointer;
        margin-right: 0;
        //Star rating
        .rating-star {
            color: $text-lightest;
            display: inline-block;
            padding: 0 4px;
            @include font-icon("\ea85");
        }

        &.fill .rating-star {
            color: $review-star;
        }
    }

    .radio {
        opacity: 0;
        position: absolute;
    }
}

// Customer reviews history
//-----------------------------------
.my-reviews-table__wrapper {
    .table-title {
        @extend .heading-3;
        display: block;
        margin-bottom: 16px;
    }

    .table-reviews {
        @media (min-width: $screen-sm-min) {
            margin-bottom: 2rem;
        }

        .rating-summary {
            @include justify-content(flex-start);
        }
    }

    .pages {
        display: block;
    }

    .pager {
        @include make-flex;
        @include align-items(center);
        width: 100%;

        .field {
            padding: 0;
        }
    }

    .toolbar {
        border-bottom: none;

        &.top {
            .pager {
                @include justify-content(flex-start);
            }

            .pages, .limiter {
                display: none;
            }

            .toolbar-amount {
                display: block;
            }
        }

        &.bottom {
            .pager {
                @include justify-content(flex-end);
            }

            .pages {
                display: block;
            }

            .toolbar-amount,
            .limiter {
                display: none;
            }
        }
    }

    .table-wrapper.reviews {
        margin-bottom: 26px;

        caption {
            display: none;
        }
    }

    table:not(.totals).table:not(#product-comparison):not(#store-open-hour) {
        border-top: 0;

        thead th {
            font-size: 16px;
            border-top: solid 1px $dark-border-color;
            border-bottom: solid 1px $dark-border-color;
        }

        td {
            &.actions {
                a {
                    font-weight: 600;
                    text-decoration: underline;
                }
            }
        }

        @media (min-width: $screen-sm-min) {
            margin-bottom: $indent-base;

            tr:last-child {
                border-bottom: solid 1px $dark-border-color;
            }

            th, td {
                border-left: solid 1px $dark-border-color;

                &:first-child {
                    border-left: solid 1px $dark-border-color;
                }

                &:last-child {
                    border-right: solid 1px $dark-border-color;
                }
            }

            tbody tr {
                &:nth-child(odd) {
                    background: $third-color;

                    &:first-child:last-child {
                        background: transparent;
                    }
                }
            }

        }

        @media (max-width: $screen-sm-min - 1) {
            thead, th {
                display: none;
            }

            td {
                width: 100%;
                display: block;
                text-align: right;
                background: transparent !important;

                &:before {
                    content: attr(data-th);
                    font-size: $default-font-size;
                    float: left;
                    border-bottom: 0;
                }

                &.actions {
                    font-size: $default-font-size;

                    &:before {
                        display: none;
                    }
                }

                + td {
                    padding-top: 0;
                }

                .limit-length {
                    max-width: 65%;
                    display: inline-block;
                }
            }

            tbody {
                display: block;
                width: 100%;

                tr {
                    display: block;
                    width: 100%;
                    padding: $indent-small;
                    margin-bottom: $indent-base;
                    @include account-nav-box-shadow;
                }
            }
        }
    }
}