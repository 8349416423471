//
//  Filters
//  __________________________________________________

.sidebar .filter-wrapper .filter-subtitle {
    font-size: 18px;
    color: $primary;
    margin-bottom: 16px;
}


#layered-filter-block .modal-slide._show {
    background-color: transparent;

    a,
    a > span {
        transition: color .3s ease;
    }
}

#layered-filter-block .modal-slide .modal-inner-wrap {
    width: 320px;
}


//Current filters
.filter-current--wrapper {
    margin-bottom: 32px;
    background: $bg-light;
    padding: 24px;
    border-radius: 4px;

    [role="tablist"] .filter-current-subtitle[role="tab"] {
        @include font-icon('\e929');
        color: $text-dark;
        font-size: 16px;
        line-height: 26px;
        font-weight: 700;
        margin-bottom: 0;
        display: inline-block;
        padding-right: 26px;
        position: relative;
        cursor: pointer;
        width: 100%;

        &:before {
            position: absolute;
            top: 0;
            right: 0;
            width: 26px;
            height: 26px;
            border-radius: 2px;
            text-align: center;
            font-size: 14px;
            line-height: 1;
            padding: 6px 0;
            border: 1px solid $border-color;
        }

        &[aria-expanded="false"]:before {
            content: '\e926';
        }
    }

    .filter-current {
        .items {
            padding: 8px 0;
            display: none;
        }

        &.active .items {
            display: block;
        }

        .item {
            padding: 4px 0;
            @include make-flex;
            @include justify-content(space-between);
            @include align-items(center);
        }

        .filter-label:after {
            content: ':';
        }

        .filter-value {
            padding-left: 4px;
        }

        .remove {
            @include font-icon('\ea5d');
            margin-left: auto;
            padding-right: 5px;

            span {
                display: none;
            }
        }
    }
}


//Filter options

.filter-options[role="tablist"] {

    .filter-options-item {
        padding: 20px 0;
    }

    .filter-options-title[role="tab"] {
        @include font-icon('\ea5b');
        color: $secondary-color;
        font-size: 16px;
        line-height: 26px;
        font-weight: 600;
        margin-bottom: 0;
        padding-right: 35px;
        position: relative;
        cursor: pointer;
        width: 100%;
        word-break: break-word;
        text-transform: uppercase;

        &:before {
            position: absolute;
            top: 0;
            right: 0;
            width: 26px;
            height: 26px;
            border-radius: 2px;
            text-align: center;
            font-size: 14px;
            line-height: 1;
            padding: 6px 0;
            color: $black;
        }

        &[aria-expanded="false"]:before {
            content: '\ea5a';
        }
    }

    .filter-options-content {
        padding: 12px 0 2px;
        margin-bottom: 0;

        .item {
            @include make-flex;
            @include justify-content(space-between);
            @include align-items(center);
            @include flex-wrap(wrap);
            padding: 4px 0;

            a {
                width: 70%;
                text-transform: capitalize;
                color: $default;
                font-weight: 500;

                &:hover {
                    color: $text-dark;
                }

                &:nth-last-child(1) {
                    width: 30%;
                    text-align: right;
                }
            }
        }

        .count {
            color: $primary;
            padding-left: 4px;
        }

        a:hover .count {
            color: $text-dark;
        }
    }

    .price-slider .slider-fields a.action {
        @include button-block;
        margin-top: 8px;
    }
}

button.action.filter-toggle {
    @include order(0);
    @include flex-basis(calc(100% - 150px));
    @include button-block(); //@mixin source/_mixins.scss
    @include font-icon('\ea86');
    font-size: 14px;
    line-height: 16px;
    padding: 17px 10px;
    height: 50px;
    margin: 5px auto 0 auto;
    text-align: center;
    min-width: 200px;
    max-width: 260px;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);
    background: white;
    color: black;

    @media (min-width: $screen-sm-min) {
        margin: 5px 16px 0 0;
    }

    @media (min-width: $screen-md-min) {
        @include flex-basis(auto);
    }
}

.sm-dektop button.action.filter-toggle {
    display: none;
}

.tollbar-bottom button.action.filter-toggle {
    display: none !important;
}

// Price Slider
.sidebar .price-slider {
    .slider-fields {
        .slider-input {
            margin-top: 8px;
        }

        label {
            color: $text-light;
            font-weight: 700;
            text-transform: capitalize;
        }
    }

    .ui-slider-handle {
        background: $primary;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        margin-left: -10px;
        margin-top: -5px;
        transition: none;

        &:after {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            width: 16px;
            height: 16px;
            border-radius: 16px;
            background: $white;
        }
    }

    .ui-slider-range {
        position: absolute;
        height: 100%;
        background: $primary;
    }
}

@media (min-width: $screen-md-min) {

    .filter-options[role="tablist"] {

        .filter-options-title[role="tab"] {
            font-size: 18px;
        }
    }

}