/*
* TM_Newsletter-popup
*/

// Main Styles

.newsletter-popup.modal-popup {

    // Modal
    .modal-inner-wrap {
        display: block;
        margin-top: 7%;
        padding: 0;
        margin-right: 20px;
        margin-left: 20px;
        background: $white;

        @media screen and (max-width: $screen-sm-min - 1) {
            width: auto !important;
        }
        @media (min-width: $screen-sm-min){
            margin-right: auto;
            margin-left: auto;
        }
    }

    .actions {
        display: none;
    }

    // Header
    header.modal-header {
        padding: 0;
        background: $white;
        text-align: center;

        .action-close {
            padding: 0;
            top: 0;
            right: 0;
            width: 43px;
            height: 43px;
            text-align: center;
            border-radius: 50%;
            background: $white;
            &:before {
                display: block;
                font-size: 24px;
                line-height: 43px;
                transition: $default-transition;
                color: $text-dark;
            }
            &:hover:before {
                color: $accent;
            }
            @media (min-width: $screen-sm-min){
                top: -50px;
                right: -50px;
            }

        }

        h1.modal-title {
            font-size: 21px;
            line-height: 1;
            font-weight: 700;
            border-bottom: none;
            padding: 0;
            margin-bottom: 0;
            color: $white;
            text-transform: uppercase;
            letter-spacing: 0.04em;
        }
        .content-text{
            > span {
                display: block;
                color: $white;
                text-transform: uppercase;
                line-height: 1;
                &.size-1 {
                    font-size: 30px;
                    font-weight: 700;
                    letter-spacing: 0.04em;
                    @media (min-width: $screen-sm-min) {
                        font-size: 70px;
                    }
                }
                &.size-2 {
                    display: inline-block;
                    .banner-label {
                        margin-left: -10px;
                        font-size: 104px;
                        line-height: 1;
                        letter-spacing: -0.14em;
                        z-index: 99;
                        font-weight: 600;
                        color: $white;
                        font-family: $special-font-family;
                        text-transform: none;
                        @media (min-width: $screen-lg-min){
                            font-size: 134px;
                        }

                        i {
                            float: right;
                            text-align: left;
                            font-size: 64px;
                            letter-spacing: 0;
                            margin-top: 12px;
                            margin-left: 15px;
                            @media (min-width: $screen-lg-min){
                                font-size: 64px;
                            }
                            b {
                                display: block;
                                font-size: 21px;
                                margin-top: -7px;
                                @media (min-width: $screen-lg-min){
                                    font-size: 64px;
                                    margin-top: -20px;
                                }
                            }
                        }
                    }
                }
                &.size-3 {
                    font-size: 19px;
                    font-weight: 100;
                }
            }

            p{
                margin-bottom: 0;
            }

            img {
                display: block;
            }
        }

    }

    // Content
    .modal-content {
        padding: 3rem 2rem;
        @include make-flex;
        @include justify-content(space-between);
        @include flex-wrap(wrap);
        position: relative;

        .newsletter-popup {
            order: 1;
            @include flex-basis(100%);
            @media (min-width: 460px){
                @include flex-basis(calc(100% - 165px));
            }
        }

        .block.newsletter {
            margin: 0;
            width: 100%;

            .field{
                padding: 0;

                input {
                    padding: 12px 18px 12px 40px;
                    height: 45px;
                    @media (min-width: $screen-md-min){
                        padding: 11px 18px 10px 40px;
                        height: 50px;
                    }
                }
                .control {
                    position: relative;
                    &:before {
                        @include font-icon__pseudo('\e697', $secondary-icon-family);
                        position: absolute;
                        height: 20px;
                        line-height: 20px;
                        font-size: 20px;
                        top: 0;
                        margin-top: 12px;
                        left: 15px;
                        color: $text-light;
                    }
                    div.mage-error {
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    // Footer
    footer.modal-footer {
        padding: 0;

        .modal-social {
            background: $bg-dark;
            font-size: 1.75em;
            padding: 15px 30px 10px;

            a {
                display: inline-block;
                margin-bottom: 5px;
                padding: 7px 5px;
                transition: .2s;
                &:hover{
                    opacity: 0.7;
                }
            }

            :link,
            :hover{
                text-decoration: none;
            }
        }
    }

    // Buttons block
    .buttons-container {
        order: 2;
        @include flex-basis(100%);
        margin-top: 10px;
        @media (min-width: 460px){
            @include flex-basis(158px);
            margin-top: 0;
        }

        button {
            @include button-block;
            padding: 13px 15px;
            box-shadow: none;
            height: 45px;

            @media (min-width: $screen-md-min){
                height: 50px;
            }

            &.subscribe{
                color: $primary;
                width: 100%;
                &:hover{
                    color: $primary;
                    background: #F04544;
                }
            }
            &:not(.subscribe) {
                display: none;
                @media (min-width: 460px){
                    position: absolute;
                }
                bottom: 0;
                left: 40px;
                background: none;
                padding: 0;
                margin: 10px 0 0 0;
                color: $primary;
                text-transform: none;
                font-weight: 400;
                &:hover {
                    background-color: transparent !important;
                    text-decoration: underline;
                }
            }
        }
    }
}