// Widger Brands

.block.brands {
    float: none;

    .block-content {

        .brandlist {
            text-align: center;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;

            @media screen and (max-width: 768px) {
                margin-bottom: 0;
            }

            .inner {
                padding: 0 15px;
                position: relative;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
            }

            .brand-logo {
                display: inline-block;
                max-width: 200px;

            }

            .brand-name {
                margin-bottom: 0;
            }

            li {
                padding: 10px;
                text-align: center;
            }

            @media screen and (max-width: 768px) {
                li {
                    margin-right: 0;
                    margin-bottom: 18px;
                    flex-basis: 50% !important;
                    -ms-flex-basis: 50% !important;
                }
            }
        }

        .owl-carousel {

            // controls
            .owl-controls {
                position: absolute;
                top: -50px;
                right: 0;

                .owl-prev {
                    @include font-icon("\e927");
                    display: inline-block;
                }

                .owl-next {
                    @include font-icon("\e928");
                    display: inline-block;
                }

                .owl-prev,
                .owl-next {
                    padding: 0 7px;
                    cursor: pointer;

                    &:before {
                        font-size: 36px;
                        color: $accent;
                        @include transition(.3s ease);
                    }

                    &:hover {
                        &:before {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }

}