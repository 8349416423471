//
//  Related product
//  ---------------------------------------------

.block.related{
    @include order(5);
    @include make-col();
    @include flex-basis(100%);
    margin-top: $indent-base;
    margin-bottom: 0;

    @media (min-width: $screen-md-min) {
        margin-bottom: $indent-base;
    }

    .block-actions .select{
        padding: 0;
        background: transparent;
        color: $primary;
        &:hover{
            color: $text-light;
        }
    }
}

.products-grid.products-related{
    .product-items {
        margin: 0;
        &:not(.owl-loaded) {
            @include flex-wrap(nowrap);
        }
    }
    .product-item{
        @include flex-basis(20%);
        width: 20%;
        max-width: 20%;
        padding:0 15px 15px 15px;
        margin: 0;
        text-align: left;
        height: 100%;

        @media (max-width: $screen-sm-min - 1) {
            @include flex-basis(33.333%);
            width: 33.333%;
            max-width: 33.333%;
        }

        @media (max-width: $screen-xs-min - 1) {
            @include flex-basis(50%);
            width: 50%;
            max-width: 50%;
        }

        .product-reviews-summary {
            margin-bottom: 0;
        }
    }

    .owl-carousel {
        .owl-stage {
            @include make-flex();
            @include align-items(stretch);

            .owl-item {
                .product-item{ 
                    @include flex-basis(100%);
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}