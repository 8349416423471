//
//  Minicart block
//  ---------------------------------------------

.minicart-wrapper {
    &.active {
        background: $third-color;
    }
}


// Action showcart

.minicart-wrapper .action.showcart {
    @include make-flex;
    @include justify-content(center);
    @include align-items(center);
    @include flex-direction(column);
    position: relative;
    padding-bottom: 9px;
    text-align: center;

    span,
    strong {
        display: inline-block;
        font-weight: normal;
    }

    .loading-mask {
        display: none;
    }

    .text {
        font-family: $default-icon-family;
        position: relative;
        width: 50px;
        height: 38px;
        color: $primary;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 50px;
            height: 47px;
            font-size: 30px;
            line-height: 33px;
            transition: .3s transform ease;
            text-align: left;
            transform-origin: 16px center;
        }

        &:before {
            content: '';
            background: url(../../images/icons/minicart.svg) center center no-repeat;
            background-size: 45px;
            margin: auto;
            opacity: 1;
            visibility: visible;
        }
    }

    .qty {
        position: absolute;
        top: 50%;
        left: 35px;
        color: $white;
        font-size: 12px;
        line-height: 26px;
        width: 26px;
        height: 26px;
        margin-top: -17px;
        border-radius: 26px;
        background: $primary;
        text-align: center;

        .counter-label {
            display: none;
        }
    }
}

.minicart-wrapper {
    .showcart-label {
        display: inline;
        font-size: 14px;
        font-weight: 700;
    }
}

.block-minicart {
    display: none;
    text-align: left;
    color: $default;
    background: $white;
    -webkit-box-shadow: 0 2px 24px rgba(2, 68, 89, .22);
    -moz-box-shadow: 0 2px 24px rgba(2, 68, 89, .22);
    box-shadow: 0 2px 24px rgba(2, 68, 89, .22);
    transition: $default-transition;
    overflow: hidden;
    padding: 20px;
    width: 410px;

    &.empty {
        height: auto;
    }

    .block-title {
        display: none;
    }


    .action.close {
        position: absolute;
        z-index: 20;
        right: 0;
        top: 0;
        padding: 5px 10px;
        color: $default;
        background-color: transparent;
        @include font-icon('\e5cd');
        display: none;

        &:hover {
            color: $primary;
            background-color: transparent;
        }
    }

    .block-content {
        > .subtitle {
            padding: 0 16px;
            font-size: 16px;

            &.empty {
                font-size: 16px;
                width: 100%;
                text-align: center;
            }
        }

        .top-minicart-content {
            @include make-flex;
            @include justify-content(space-between);
            @include align-items(center);

            > .subtitle {
                font-size: 15px;
                line-height: 1;
                font-weight: 700;
                color: $text-dark;
                text-transform: uppercase;

                &.empty {
                    font-size: 16px;
                    color: $text-dark;

                    span {
                        vertical-align: baseline;
                    }
                }
            }
        }

        .minicart-items-wrapper {
            max-height: 350px;
            margin-top: 15px;
        }

        .items-total {
            font-size: 18px;
            color: $secondary-color;
            font-weight: 700;
        }

        .minicart-footer {
            border-top: 1px solid #f5f5f5;
            @include make-flex();
            @include justify-content(space-between);
            @include align-items(center);
        }

        .minicart-footer .subtotal {
            @include make-flex();
            @include flex-direction(column);
            @include justify-content(center);
            @include align-items(flex-end);

            .label {
                font-size: 15px;
                font-weight: 600;
                line-height: 1;
                color: #696969;
                text-transform: none;
                margin-bottom: 3px;
            }

            .amount {
                font-size: 18px;
                line-height: 1;
                color: $text-dark;
                font-weight: 700;
            }
        }

        #top-cart-btn-checkout {
            width: 180px;
            height: 40px;
            padding: 0;
            font-size: 17px;

            &:disabled {
                background: $unactive;
                cursor: wait;
            }
        }

        .minicart-footer .actions {

            button.primary {
                color: $white;
                background: $secondary-color;
                text-transform: uppercase;
            }

            .secondary {
                margin-bottom: 5px;
                font-size: 16px;
            }

            .viewcart {
                margin-top: 20px;
                display: inline-block;
                text-decoration: underline;
                font-size: 14px;
                text-transform: none;
            }
        }

        .paypal.checkout {
            margin-top: 8px;
        }
    }

    // .action.delete, .action.edit mixin in Magento_Theme\web\css\source\_module.scss

    //Minicart items
    .minicart-items {
        overflow-y: auto;
        height: 100%;

        > li {
            padding: 0 0 20px;
            margin: 0;

            > .product {

            }
        }

        > li > .product {
            overflow: hidden;
            position: relative;
            @include make-flex;
            @include flex-wrap(wrap);
            @include justify-content(space-between);
            @include align-items(flex-start);

            .product-item-photo {
                @include flex-basis(97px);
            }

            .product-image-container {
                display: inline-block;
                max-width: 100%;
            }

            .product-image-wrapper {
                height: auto;
                padding: 0 !important;

                .product-image-photo {
                    position: static;
                    height: auto !important;
                }
            }

            .product-item-details {
                padding-left: 15px;
                padding-top: 10px;
                @include flex-basis(calc(100% - 97px));
            }

            .product-item-name {
                font-size: 15px;
                line-height: 18px;
                display: inline-block;
                width: 100%;
                font-weight: 700;
                padding-right: 10px;
                word-break: break-word;

                a {
                    color: $black;
                    width: 100%;
                    display: inline-block;
                }

                a:hover {
                    color: $primary;
                }
            }
        }

        .product-item-description {
            font-size: 13px;
            margin-top: -4px;
            padding-bottom: 5px;
        }

        .product-item-price {
            @include make-flex();
            @include align-items(baseline);

            .price-container {
                margin-bottom: 8px;
            }

            .minicart-price {
                color: $black;
                font-size: 18px;
                font-weight: 700;
                line-height: 1;
            }

            .message-container {
                color: $secondary-color;
            }
        }

        .product-item-unit {
            font-size: 13px;
            font-weight: 500;
            line-height: 13px;
            text-transform: uppercase;
        }

        .product-item-pricing {
            margin: 5px 0 13px;
        }

        .details-qty {
            position: relative;
            @include make-flex;
            @include align-items(center);

            .label {
                font-size: 10px;
                font-weight: 700;
                margin-right: 10px;
                line-height: 20px;
            }

            .cart-item-qty {
                padding: 0;
                width: 50px;
                font-size: 14px;
                line-height: 18px;
                vertical-align: baseline;
                background: $white;
                text-align: center;
                min-height: 0;
                border: 1px solid $black;
                font-weight: 700;

                &:disabled {
                    color: $unactive;
                    border-color: $unactive;
                }
            }

            .update-cart-item {
                @include font-icon("\e92d", $icon-mini);
                font-size: 14px;
                line-height: 1;
                margin-left: 10px;
                color: $primary;

                &:hover {
                    color: $text-dark;
                }

                span {
                    display: none;
                }
            }
        }


        //Options
        .product.options[role="tablist"] {
            position: relative;
            margin-bottom: 8px;

            .toggle {
                cursor: pointer;
                color: $primary;
                font-size: 14px;
                line-height: 14px;
                font-weight: 400;
                margin-bottom: 0;
                @include font-icon('\e926', $icon-mini);

                &:before {
                    color: $default;
                    font-size: 12px;
                    line-height: 10px;
                    top: 4px;
                }
            }

            &.active .toggle:before {
                content: '\e929';
            }

            .content {
                background: $white;
                margin-bottom: 0;
            }

            .content .subtitle {
                display: none;
            }

            .list {
                font-size: 14px;
                margin-top: 7px;
                @include make-flex();
                @include flex-wrap(wrap);
                @include flex-direction(row);
                color: $text-dark;

                > dt, > dd {
                    @include flex-basis(50%);
                    font-weight: 400;
                }

                > dd {
                    padding-left: 5px;
                }
            }
        }

        //Actions
        .product.actions {
            font-size: 14px;
            line-height: 1;
            margin-bottom: 8px;
            @include flex-basis(100%);
            text-transform: none;

            .secondary {
                margin-right: 5px;
            }

            .primary,
            .secondary {
                display: inline-block;
                font-size: 14px;

                .action {
                    &:before {
                        content: none;
                    }

                    > span {
                        display: inline;
                        text-decoration: underline;
                    }
                }
            }

            a {
                color: $primary;
                background: transparent;

                &:hover {
                    color: $secondary-color;
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {

    .minicart-wrapper {
        position: relative;
        float: right;
        text-align: center;

        .showcart-label {
           display: none;
        }

        &:hover {
            .showcart-label {
                color: $secondary-color;
            }
        }
    }

    .minicart-wrapper .action.showcart {
        padding-right: 10px;

        .qty {
            top: 20px;
        }
    }

    .block-minicart {
        display: block;
        position: absolute;
        top: 100%;
        right: 0;
        visibility: hidden;
        opacity: 0;
        @include transform(translateY(50px));
        z-index: 919;
    }

    .minicart-wrapper.active .block-minicart {
        visibility: visible;
        opacity: 1;
        @include transform(translateY(0));
    }
}

@media (max-width: $screen-md-min - 1) {

   
    div:not(.opc-estimated-wrapper) {
        .minicart-wrapper {
            padding: 0;
            margin: 0;
        }

        .showcart-label {
            display: none;
        }

        .action.showcart {
            position: fixed;
            z-index: 19;
            top: 25px;
            right: 0;
            width: 60px;
            height: 56px;
            margin-right: 10px;
            padding-bottom: 0;

            .text {
                color: $sm-header-contrast-color;
                width: 60px;
                height: $sm-header-panel-height;

                &:before {
                    background-size: 35px;
                    height: 50px;
                }
            }
        }

        .block-minicart {
            display: none !important;
            position: fixed;
            z-index: 21;
            top: $sm-header-panel-height;
            right: 0;
            bottom: 0;
            width: 320px;
            opacity: 0;
            overflow-y: auto;
            @include transform(translateX(100vw));
        }
        .minicart-wrapper.active .block-minicart {
            opacity: 1;
            @include transform(translateX(0));
        }
    }
}

@media (max-width: $screen-sm-min - 1) {
    div:not(.opc-estimated-wrapper) .action.showcart {
        right: 0;
    }
}
