//
// Customer account orders history
//-----------------------------------
.orders-history__wrapper {
	.order-products-toolbar {
		border-bottom: 0;
		padding-bottom: 0;

		&.top .pager .limiter {
			display: none;
		}

		&.bottom .pager {
			@include make-flex;
			@include justify-content(center);
        	width: 100%;
			margin-bottom: 0;

			@media (min-width: $screen-sm-min) {
				@include justify-content(flex-end);
			}

			.pages {
				display: block;
				margin-bottom: $indent-base;
			}

			.toolbar-amount, .limiter {
				display: none;
			}
		}
	}

	table:not(.totals).table:not(#product-comparison).table-order-items {
		border-top: 0;

        thead th {
            font-size: 16px;
            border-top: solid 1px $dark-border-color;
            border-bottom: solid 1px $dark-border-color;
        }

		td {
            &.actions {
                a {
                    font-weight: 600;
                    text-decoration: underline;
                }
            }
        }

	    @media (min-width: $screen-sm-min) {
	    	margin-bottom: $indent-base;

            tr:last-child {
                border-bottom: solid 1px $dark-border-color;
            }

            th, td {
                border-left: solid 1px $dark-border-color;

                &:first-child {
                	border-left: solid 1px $dark-border-color;
                }

                &:last-child {
                    border-right: solid 1px $dark-border-color;
                }
            }

            tbody tr {
            	&:nth-child(odd){
	                background: $third-color;
	                
	                &:first-child:last-child{
	                    background: transparent;
	                }
	            }
            }

        }

        @media (max-width: $screen-sm-min - 1) {
            thead, th {
                display: none;
            }

            td {
                width: 100%;
                display: block;
                text-align: right;
                background: transparent;

                &:before{
                    content: attr(data-th);
                    font-size: $default-font-size;
                    float: left;
                    border-bottom: 0;
                }

                &.actions {
                    font-size: $default-font-size;

                    &:before {
                        display: none;
                    }
                }

                + td {
                	padding-top: 0;
                }
            }

            tbody {
                display: block;
                width: 100%;

                tr {
                    display: block;
                    width: 100%;
                    padding: $indent-small;
                    margin-bottom: $indent-base;
                    @include account-nav-box-shadow;
                }
            }
        }
	}

    .table-title,
    .table-order-items caption{
        display: none;
    }
}

