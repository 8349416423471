/* 
 * 	Featured Product by TemplateMonster 2016
 *	v1.0.0
 */

// import
@import '_variables.scss';
@import '_mixins.scss';
@import '_products-grid.scss';
@import '_products-list.scss';
@import '_products-sidebar.scss';

/* Main Styles */



.block.widget.featured {
    .product-item{
        position: relative;
        max-width: 100%;

        .product-item-info {
            position: relative;
        }

        // label
        .product-label{
            position: absolute;
            top: 10px;
            right: 15px;
            z-index: 9;
            font-size: $label-size;
            color: $white;
            background-color: $primary;
            padding: 3px 10px;
            font-weight: 700;
            line-height: 19px;
            &:before {
                content: '';
                position: absolute;
                left: -7px;
                top: 0;
                background: url("../images/media/lab.png");
                width: 7px;
                height: 25px;
            }
        }
    }

    .not-found {
        padding: 0;
        margin: 0;

        @media (max-width: 767px) {
            padding: 0 0 15px;
            margin: -30px 0 0;
        }

    }
}


// Carousel
.block.widget.featured .products-grid .owl-carousel .owl-item .product-item {
    height: 100%;
}

.block.featured {
    margin-top: 30px;
    float: none;

    .block-content {
        @media (max-width: $screen-md-min - 1) {
            margin: 0;
        }
    }

    .block-content .owl-carousel {

        .owl-stage {
            @include make-flex();
            @include align-items(stretch)
        }

        .owl-nav {
            .owl-prev,
            .owl-next {

                &.disabled:before {
                    background-color: rgba(18, 18, 18, 0.2);
                }

                &:not(.disabled):hover:before {
                    opacity: 1;
                }

                &:before {
                    color: $white;
                    background: $third-color;
                    border: none;
                    opacity: 0.7;
                    @include transition(all 0.3s ease);
                }
            }
        }

        // controls
        .owl-controls {
            position: absolute;
            top: -30px;
            right: 10px;

            .owl-prev{
                @include font-icon("\f104");
                display: inline-block;
            }
            .owl-next{
                @include font-icon("\f105");
                display: inline-block;
            }
            .owl-prev,
            .owl-next{
                padding: 0 7px;
                cursor: pointer;
                &:before{
                    font-size: 36px;
                    line-height: 20px;
                    color: $accent;
                    transition: $default-transition;
                }
                &:hover{
                    &:before{
                        color: $primary;
                    }
                }
            }
        }
    }
}
