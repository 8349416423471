//
// Create Password
//-----------------------------------

.form.password.reset {
    max-width: 800px;
    margin: 0 auto $indent-base auto;

    @media (min-width: $screen-sm-min) {
        margin-bottom: $indent-large;
    }

    .actions-toolbar{
        margin-top: $indent-base;
        margin-bottom: $indent-base;

        @media (min-width: $screen-sm-min) {
            margin-bottom: $indent-large;
        }

        .action {
            min-width: 150px;
        }
    }
}