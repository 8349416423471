// Brand List

.brand-list {
    @include make-flex;
    @include flex-wrap(wrap);
    margin: 0 -15px 10px;
    padding: 0;
    list-style: none;

    .brand-item {
        @include flex-basis(100%);
        text-align: center;
        margin-bottom: 30px;
        padding: 0 15px;

        @media (min-width: $screen-xs-min){
            @include flex-basis(50%);
        }
        @media (min-width: $screen-md-min){
            @include flex-basis(25%);
        }

        .inner {
            padding: 5px;
            height: 100%;
        }

        .brand-logo {
            display: inline-block;
            margin-bottom: 20px;
        }

        .brand-item-name {
            margin: 0 0 10px;
            font-size: 18px;

            a {
                font-size: 18px;
                line-height: 20px;
                font-weight: 700;
                color: $primary;
                &:hover{
                    color: $accent;
                }
            }
        }
    }
}

