//
// Variables
//----------------------------------------------
$search-padding-hor: 20px;
$search-padding-vert: 13px;

//
//  Search mini form
//  ---------------------------------------------

.block-search {
    .block-title,
    .nested {
        display: none;
    }

    .form .actions {
        display: none;
    }

    #search_mini_form {
        input {
            font-weight: 500;

            @media (max-width: 991px) {
                display: none;
            }
        }
    }

    .amsearch-close {
        top: 31%;
    }

    .search-control_wrapper {
        display: block !important;
    }
}

//
//  Desktop view Search
//-----------------------------------------------
@media (min-width: $screen-md-min) {
    .block-search {
        .search-control_wrapper {
            .ui-dialog-buttonpane {
                display: none;
            }
        }

        #search_mini_form {
            @include make-flex;

            .search .label {
                display: none;
            }

            .field {
                padding: 0;
                width: 100%;
            }

            .control {
                border: 0;
                color: #000;
                padding: 0;
                background: none;
            }

            input {
                padding: 8px;
                background: none;
                border: none;
                height: 42px;
                width: calc(100% - 42px);
                margin-left: 42px;

                @media (min-width: $screen-lg-min) {
                    min-width: 370px;
                }
                @media (min-width: $screen-xl-min) {
                    min-width: 400px;
                }
            }

            button[type="submit"] {
                @include font-icon('\e032', $out-icon-family);
                //background: $primary;
                font-size: 20px;
                line-height: 21px;
                font-weight: 700;
                padding: 11px 13px 10px;
                color: $white;
                border: 1px solid transparent;
                margin-left: -1px;
                border-radius: 4px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                span {
                    //display: none;
                }
                &:hover {
                    background: $accent;
                }
            }
        }
    }

    .page-header__content-search-wrap {
        position: relative;
        width: 100%;
        border: 1px solid $gray_9f;
        height: 42px;

        .search-desktop-trigger {
            position: absolute;
            width: 100%;
            z-index: 1;

            &.active {
                width: 42px;
            }
        }

        .block.block-search {
            position: absolute;
            z-index: 0;
            left: 0;
            top: 0;
            right: 0;
        }
    }

    .search-desktop-trigger-wrapper {
        .search-desktop-trigger {
            display: block;
            font-family: $default-icon-family;
            width: 100%;
            height: 40px;
            @include toggle-icons-via-rotation(42px, 20px, $white, '\ea66', '\ea66');

            > span {
                width: 42px;
                height: 40px;
                line-height: 40px;
                background: $secondary-color;
                border-right: 1px solid $gray_9f;

                &:before,
                &:after {
                    transition: none;
                }
            }
        }
    }
}

//
//  Mobile view Search
//-----------------------------------------------

@media (max-width: $screen-md-min - 1) {
    .block-search {
        #search_mini_form {
            &,
            > .search {
                position: fixed;
                z-index: 51;
                top: 0;
                left: 0;
                right: 0;
                height: 0;
                padding: 0;
                margin: 0;
            }

            .ui-dialog-buttonpane {
                display: none;
            }
        }

        .search .label {
            display: block;
            position: fixed;
            z-index: 11;
            top: 25px;
            right: 110px;
            font-family: $default-icon-family;
            width: $rd-navbar-panel-height;
            height: $rd-navbar-panel-height;
            background: transparent;
            @include toggle-icons-via-rotation($rd-navbar-panel-height, 20px, $gray_7f, '\ea66', '\ea5c');

            > span {
                &:before,
                &:after {
                    color: $secondary-color;
                }
            }

            &.active {
                span {
                    background: $third-color;
                }
            }
        }

        .field.search > .search-control_wrapper {
            position: fixed;
            z-index: 10;
            left: auto;
            top: 81px;
            right: 2*$rd-navbar-panel-height;
            width: 0;
            height: $rd-navbar-panel-height;

            > .control {
                height: 100%;
            }

            input {
                border-radius: 0;
                height: 100%;
                width: 100%;
                display: block;
                background: $white;
                border: none;
                font-size: 16px;
                line-height: 14px;
                padding: 16px 0 16px 0;
            }
        }

        #search_mini_form .search.active .search-control_wrapper {
            right: 0;
            left: 0;
            width: auto;
            z-index: 97;
            border: 3px solid $third-color;

            input {
                padding: 16px 112px 16px 16px;
            }
        }

        &.has-roomvo label.label {
            right: 150px;
        }
    }

    .mobile-roomvo
    {
        display: block !important;

        img.roomvo-header-icon {
            position: fixed;
            z-index: 17;
            right: 125px;
            top: 40px;
            height: 32px;
        }
    }

    .roomvo-button {
        right: 0 !important;
        left: 0;
        bottom: 0 !important;

        img {
            width: 70px;
        }
    }
}

