//
//  Generic Grid/List Item
//  ---------------------------------------------

.catalog-category-view,
.catalogsearch-result-index {
    .page-main {
        margin-top: 0;
    }

    @media (max-width: $screen-md-min - 1) {
        .grid-left {
            margin-top: 0 !important;
            height: 0 !important;
        }
    }

    &.page-layout-2columns-left.catalog-no-result {
        .grid-main {
            @include flex-basis(100%);
            max-width: 100%;
        }
    }
}

.catalog-no-result .search.no-result {
    margin-top: 15px;

    .content {
        text-align: center;

        .text {
            font-size: 20px;
            line-height: 1.2;
        }
    }

    .noresult-search.form {
        @include make-flex();
        @include justify-content(center);
        @include align-items(center);

        .control {
            input {
                width: 240px;
                padding: 12px 20px 11px;
                height: 45px;
            }
        }

        .actions {
            button {
                height: 45px;
                span {
                    @include font-icon("\ea66", $icon-mini);
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    .catalog-no-result .search.no-result {
        margin-top: 0;

        .content {
            margin-bottom: 45px;
            .text {
                font-size: 25px;
            }
        }

        .noresult-search.form {
            .control {
                input {
                    width: 320px;
                }
            }
        }
    }
}

.category-image {
    width: 100%;
    height: auto;
    overflow: hidden;

    @media (max-width: $screen-md-min - 1) {
        padding-left: 0;
        padding-right: 0;
        margin-top: 4px;
    }

    @media (min-width: $screen-md-min) {
        margin-top: 5px;
    }

    img {
        width: 100%;
    }
}

.products-grid,
.products-list {
    .product-item {
        // Image
        .product-image-container {
            display: inline-block;
            max-width: 100%;
        }

        .product-item-photo {
            display: block;
            perspective: 999px;
            text-align: center;
            padding: 20px 0 0 0;

            .replaced {
                bottom: 0;
                height: auto;
                left: 0;
                margin: auto;
                max-width: 100%;
                position: absolute;
                right: 0;
                top: 0;
                display: none;
            }

            .product-image-wrapper {
                > img {
                    transform: scale(1);
                    transition: .3s;
                }
            }

            .tm-featured-switch-image-container,
            .tm-switch-image-container {
                display: inline-block;
                overflow: hidden;

                > img {
                    transform: scale(1);
                    transition: .3s;
                }

                .replaced {
                    > img {
                        transform: scale(0);
                        transition: .3s;
                    }
                }
            }
        }

        .product-image-wrapper {
            display: block;
            height: 0;
            overflow: hidden;
            position: relative;
            z-index: 1;
        }

        .product-image-photo {
            bottom: 0;
            display: block;
            height: auto;
            left: 0;
            margin: auto;
            max-width: 100%;
            position: absolute;
            right: 0;
            top: 0;
        }

        .gallery-placeholder {
            .fotorama-item {
                .fotorama__stage {
                    margin: 0 auto;
                }

                .fotorama__arr {
                    width: 50px;
                }
            }
        }

        // Price

        .price-box {
            @include price-box; // _mixins.scss
            font-size: 18px;
            line-height: 1;
            font-weight: 700;

            .price-as-configured {
                margin-bottom: 0;
                height: 32px;

                .price-container {
                    margin-bottom: 1em;
                }
            }

            .price-per-sqm,
            .special-price-custom {
                display: inline-flex;
            }

            .special-price-custom {
                align-items: flex-end;

                @media (max-width: 767px) {
                    flex-wrap: wrap;

                    .old-price {
                        width: 100%;
                    }
                }

                &.has-sqm {
                    align-items: flex-end;

                    .special-price,
                    .product-item-unit {
                        font-size: 12px;
                    }

                    .special-price {
                        color: #f0b4b4;
                    }

                    .old-price,
                    .product-item-unit {
                        margin-bottom: 0;
                    }

                    .old-price,
                    .product-item-unit {
                        color: #bebebe;
                        text-decoration-color: #bebebe;
                    }
                }
            }

            .price-per-sqm {
                margin-bottom: 5px;
                align-items: flex-end;

                .price-sqm-label {
                    color: #bebebe;
                }

                &.no-special {
                    .price-sqm-label {
                        color: #000;
                        font-weight: 600;
                    }
                }
            }

            .old-price {
                span.price {
                    font-size: 12px;
                }
            }

            .special-price {
                margin: 0 5px 0 0;
            }
        }

        //Brand
        .product-item-brand {
            display: block;
            font-size: 14px;
            text-align: left;
            font-weight: 700;
            color: $black;
            height: 21px;
            margin-bottom: 7px;
            word-break: break-word;
            word-wrap: break-word;
        }

        //Name
        .product-item-name {
            display: block;
            font-size: 15px;
            text-align: left;
            font-weight: 700;
            word-break: break-word;
            word-wrap: break-word;
            line-height: 1.5;
            margin-bottom: 0;

            a {
                color: $text-dark;

                &:hover {
                    color: $primary;
                }
            }
        }

        //Details
        .product-item-description {
            margin-top: 0;
            text-align: left;
            font-size: 14px;
            word-break: break-word;
            word-wrap: break-word;
            color: darkgray;

            p {
                margin-bottom: 0;
            }
        }

        //Reviews
        .product-reviews-summary {
            @include make-flex;
            margin-top: 4px;
        }

        .rating-summary {
            .label {
                display: none;
            }
        }

        .reviews-actions {
            .view {
                margin-right: 12px;
            }
        }

        // Swatches

        .swatch-option {
            margin-bottom: 10px;
            min-width: 26px;

            + .swatch-option {
                margin-left: 5px;
            }

            &.color {
                min-height: 26px;
            }

            &.text {
                padding: 2px;
            }
        }

        //Actions
        .product-item-actions {
            margin-top: 0;
            @include make-flex();
            @include flex-wrap(wrap);

            .actions-primary {
                margin-right: 0;
                margin-bottom: 12px;

                .action span {
                    line-height: 22px;
                }

                .stock.unavailable {
                    color: $primary;
                }
            }

            .actions-secondary {
                @include flex-basis(auto);
                @include make-flex();
                @include justify-content(flex-end);
                margin-bottom: 12px;

                .action {
                    background-color: transparent;
                    font-size: 20px;
                    line-height: 20px;
                    color: $primary;
                    display: block;
                    text-align: center;

                    &:hover {
                        color: $secondary-color;
                    }

                    + .action {
                        margin-left: 8px;
                    }
                }
            }
        }
    }
}

.products-grid {
    .product-item {
        text-align: center;

        .product-item-info {
            @include make-flex();
            @include flex-direction(column);
            @include justify-content(center);
        }
    }

    .product-reviews-summary {
        @include justify-content(flex-start);

        .reviews-actions {
            display: none;
        }
    }
}

.products-list {
    .product-item {
        .product-item-name {
            font-size: 24px;
            margin-bottom: 25px;
        }

        .actions-secondary {
            @include align-items(center);
            margin-left: 15px;
        }

        .product-image-wrapper > img {
            margin: 0 auto;
            padding: 0;
        }

        .product-reviews-summary {
            margin-top: -10px;

            .rating-result,
            .rating-result__stars,
            .rating-result__stars > span {
                @include make-flex;
                @include align-items(center);
            }
        }
    }
}

@media (max-width: $screen-xs-min - 1) {
    .products-grid,
    .products-list {
        .product-item {

            .product-item-name {
                font-size: 14px;
            }

            .product-item-description {
                font-size: 12px;
            }
        }
    }
}

.category-view {
    @include make-container();
    @media (min-width: 600px) {
        @include container-box;
        text-align: center;
        overflow: hidden;
        .category-image {
            float: left;
        }
        .category-description {
            text-align: left;
            font-size: 18px;
            line-height: 26px;
        }
    }
}