/*
*   Social Login
*/

// Local vars

$button-width: 270px;
$button-border-radius: 2px;
$button-padding: 10px;
$button-font-size: 16px;
$icon-font-size: 24px;
$line-height: 24px;

$button-color: #ffffff;
$facbook-bg: #3b5999;
$twitter-bg: #00b6f1;
$instagram-bg: #7c4e3a;
$gplus-bg: #e04a39;

// Main Styles

.block-social-login {
    width: 100%;
    text-align: center;

    @media (min-width: $screen-sm-min){
        text-align: left;
    }
    
    ul {
        margin-top: 20px;

        li {
            text-align: center;

            a {
                display: block;
                text-decoration: none;
                overflow: hidden;
                position: relative;
                transition: 0.2s;
                box-sizing: border-box;
                font-size: $button-font-size;
                line-height: $line-height;
                color: $button-color;
                padding: $button-padding;
                text-align: left;
                @include make-flex;
                @include flex-direction(row);
                @include justify-content(center);

                i.fa {
                    font-style: normal;
                    display: inline-block;
                    vertical-align: middle;
                    text-align: center;
                    font-size: $icon-font-size;
                }

                &.facebook {
                    background: $facbook-bg;
                }
                &.twitter {
                    background: $twitter-bg;
                }
                &.instagram {
                    background: $instagram-bg;
                }
                &.google-plus {
                    background: $gplus-bg;
                }

                .label {
                    display: inline-block;
                    vertical-align: middle;
                    padding-left: 10px;
                }

                &::after {
                    background: #fff;
                    content: "";
                    height: 155px;
                    left: -75px;
                    opacity: .2;
                    position: absolute;
                    top: -50px;
                    transform: rotate(35deg);
                    transition: 0.55s cubic-bezier(0.19, 1, 0.22, 1);
                    width: 50px;
                    z-index: 10;
                }

                &:hover {
                    opacity: .8;
                    &::after {
                        left: 120%;
                    }
                }
                @media (max-width: 375px){
                    font-size: 12px;
                }
            }
        }

        li + li {
            margin-top: 8px;
        }
    }
}