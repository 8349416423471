.block-title {
  .underline {
    border-bottom: 1px solid black;
    width: 100%;
    display: block;
    padding-top: 10px;
  }
}
.block-content{
  .product-item {
    margin-right: 24px;
  }
}

