// Product Brand

.product-brand {
    margin-bottom: 20px;

    .product-brand-name {
        margin-bottom: 15px;
    }
    .product-brand-logo {
        margin-bottom: 15px;
    }
}