/*
** Vars
*/

// Colors
$menu-bg:           #ffffff;
$menu-border:       #c9c9c9;
$menu-color:       #575757;
$menu-line-heigh:   47px;
$label-bg:          #c02a2a;
$label-color:       #ffffff;
$label-font-size:   11px;

$submenu-bg: darken(#fff, 10%);

//Product
$name-color: $menu-color;
$name-color-hover: #b9b9b9;


// Shadows
$menu-shadow:  0 2px 24px rgba(2,68,89,.22);

