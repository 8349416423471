.tile-adhesive-grout-page {
    .top-banner {
        margin-bottom: 37px;

        img {
            width: 100%;
            display: block;
        }
    }
}

.calculate-form {
    .field {
        select,
        .input-text {
            background: #D9D9D9;
            border-radius: 10px;
            height: 45px;
            padding: 0 18px;
        }

        select {
             -webkit-appearance: none;
              appearance: none;
            background-image: url(../../images/icons/select-bg.svg) ;
            background-position: calc(100% - 10px) center;
            background-repeat: no-repeat;
            padding-right: 35px;

            &.mage-error {
                background-image: url(../../images/icons/select-error-bg.svg) ;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }

        .control {
            div.mage-error {
                padding-top: 5px;
            }
        }
    }

    .fields {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;

        .field {
            width: calc(50% - 12px);
            display: flex;
            align-items: flex-start;
            gap: 30px;
            padding: 0;
            margin: 0;

            label {
                text-align: right;
                min-width: 200px;
                min-height: 45px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-size: 20px;
                font-weight: 600;
                color: #000;
            }

            .control {
                width: 100%;
                margin: 0;
            }
        }
    }
    .actions {
        text-align: center;
        margin-top: 30px;

        .action {
            min-width: 213px;
            border-radius: 10px;
            text-transform: unset;
            font-size: 20px;
            font-weight: 600;
        }
    }
}

.field-result {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center;
    justify-content: center;

    .result-number {
        display: block;
        width: 305px;
        height: 45px;
        line-height: 45px;
        background: #D9D9D9;
        border-radius: 10px;
        font-size: 20px;
        font-weight: 600;
        color: #000;
        padding: 0 18px;
    }

    .result-label,
    .result-unit {
        font-size: 20px;
        font-weight: 600;
        color: #000;
    }

    .result-label {
        width: 100%;
    }

    .result-value {
        display: flex;
        align-items: center;
        gap: 30px;
    }
}

.field-results {
    margin: 35px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.bottom-note {
    font-size: 20px;
    font-weight: 500;
    color: #000;
    text-align: center;
    margin: 35px 0 30px;

    span {
        margin-right: 18px;
    }

    img {
        position: relative;
        top: 5px;
    }
}

.adhesive-grout-tabs {
    .title-tabs {
        display: flex;
        gap: 30px;
        margin-bottom: 20px;

        .item {
            width: 100%;
            padding: 12px 20px;
            text-align: center;
            background: $bg-light;
            border-radius: 20px;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .switch {
                display: block;
                font-size: 16px;
                font-weight: 600;
                color: #000;
            }

            &:before {
                display: none;
            }

            &.active {
                background: #E93323;

                &:before {
                    display: none;
                }

                .switch {
                    color: #fff;
                }
            }
        }
    }

    .content-tab {
        .box-content {
            background: $bg-light;
            padding: 20px;
            border-radius: 20px;
            margin-bottom: 35px;

            &:last-child {
                margin-bottom: 0;
            }

            .title {
                font-weight: 700;
                color: #E93323;
                text-align: center;
                margin-bottom: 17px;
                line-height: 1;

                &.large {
                    font-size: 36px;
                }

                &.small {
                    font-size: 24px;
                }
            }

            .note {
                font-size: 12px;
                font-weight: 300;
                color: #000;
                text-align: center;
            }
        }
    }
}

@media (max-width: $screen-md-min - 1) {
    .field-result {
        gap: 5px;
        flex-direction: column;

        .result-label {
            width: 100%;
            max-width: 350px;
            margin-left: auto;
            margin-right: auto;

        }

        .result-value {
            gap: 15px;
            width: 100%;
            max-width: 350px;
            margin-left: auto;
            margin-right: auto;

            .result-number {
                width: calc(100% - 50px);
            }
        }
    }

    .calculate-form {
        .fields {
            flex-direction: column;

            .field {
                width: 100%;
                gap: 5px;
                flex-wrap: wrap;
                max-width: 350px;
                margin: 0 auto;

                label {
                    width: 100%;
                    text-align: left;
                    min-width: unset;
                    justify-content: flex-start;
                    min-height: unset;
                }

                .control {
                    width: 100%;;
                }
            }
        }
    }

    .adhesive-grout-tabs {
        .title-tabs {
            gap: 15px;
        }

        .content-tab {
            .box-content {
                .title {
                    &.large {
                        font-size: 26px;
                    }

                    &.small {
                        font-size: 22px;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    .field-result {
        .result-label{
            width: 35%;
            text-align: right;
        }

        .result-value {
            width: calc(65% - 30px);
        }
    }
}