//
//  Wishlist sidebar
//  ---------------------------------------------

.block-wishlist{
    .wishlist-counter{
        margin-bottom: 16px;

        span{
            font-weight: 700;
            color: $primary;
        }
    }
    .subtitle{
        font-size: 1.15em;
        font-weight: 700;
        color: $text-light;
        margin-bottom: 24px;
        display: inline-block;
    }

    .product-item{
        display: block;
        margin-bottom: 30px;
    }
    .product-item-info{
        @include make-flex;
        @include flex-wrap(wrap);

        @media (min-width: $screen-xl-min){
            @include flex-wrap(nowrap);
        }
    }

    //Image
    .product-image-container{
        display: inline-block;
        max-width: 100%;
        background: $bg-light;
    }
    .product-image-wrapper{
        height: 0;
        display: block;
        position: relative;
        z-index: 1;
        overflow: hidden;
    }
    .product-image-photo{
        text-align: center;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: auto;
        max-width: 100%;
    }

    .product-item-details{
        overflow: hidden;
        padding: 0 0 0 16px;
        position: relative;
        @include flex-basis(100%);
        @include flex-grow(2);

        @media (min-width: $screen-xl-min){
            @include flex-basis(auto);
        }
    }
    .product-item-name{
        display: block;
        font-size: 14px;
        line-height: 23px;
        margin-bottom: 12px;
        padding-right: 24px;

        a{
            color: $text-light;
            &:hover{
                color: $primary;
            }
        }
    }
    .price-box{
        @include price-box;
    }
    .price-as-configured .price-final_price{
        display: none;
    }
    .product-item-actions{
        .tocart span{
            display: none;
        }
        button.action{
            padding: 0;
            border-radius: 50px;
            &:before{
                display: inline-block;
                width: 50px;
                font-size: 23px;
                line-height: 50px;
                vertical-align: baseline;
            }
        }
    }
    .empty{
        margin-top: 16px;
    }

    .actions-secondary{
        position: absolute;
        top: -2px;
        right: 0;

        .btn-remove {
            font-size: 16px;
        }
    }

    .actions-toolbar a.details{
        @include button-block;
        width: 100%;
        text-align: center;
        padding: 14px 33px;

        @media (min-width: $screen-xs-min) and (max-width: $screen-md-min - 1){
            width: auto;
        }
    }
}

//
//  Wishlist page
//  ---------------------------------------------

.products-grid.wishlist{
    // Name
    .product-item-name{
        display: block;
        margin-top: 7px;
        margin-bottom: 10px;
        max-height: 65px;	
        min-height: 65px;
        height: 100%;
        overflow: hidden;
    }

    // Price
    .price-box {
        text-align: left;
    }
    .price-as-configured span[data-price-amount="0"]{
        display: block;
    }

    .product-item-inner{
        text-align: left;
    }

    // Options
    .product-item-tooltip{
        .subtitle,
        .ui-dialog-buttonpane{
            display: none;
        }
        .toggle{
            cursor: pointer;
            color: $text-light;
            font-weight: 700;
            margin-bottom: 8px;
        }
        dl{
            @include make-flex;
            @include justify-content(space-around);
            @include align-items(stretch);
            @include flex-wrap(wrap);

            dt, dd{
                @include flex-basis(50%);
            }
            dt{
                text-align: right;
                padding-right: 8px;
            }
            dd{
                text-align: left;
                padding-left: 8px;
            }
        }
    }

    // Comment
    .comment-box label{
        color: $text-dark;
        font-weight: 700;
        margin-bottom: 4px;
        display: inline-block;
    }
    textarea{
        min-height: 105px;
        resize: none;
    }

    // Product`s action
    .box-tocart{
        margin-bottom: 10px;

        .fieldset{
            width: 100%;

            .label{
                width: 100%;
                color: $text-light;
                font-weight: 700;
                margin-bottom: 4px;
                display: inline-block;
            }
            .wishlist_box-tocart__wrapper{
                @include make-flex;
                @include justify-content(flex-start);
                @include align-items(flex-start);
                @include flex-direction(column);

                @media (min-width: $screen-xl-min){
                    @include justify-content(space-between);
                    @include align-items(stretch);
                    @include flex-direction(row);

                    .unavailable.stock {
                        margin: 0 3px 10px 8px;
                    }
                }
            }

            .field.qty{
                @include flex-basis(auto);
                padding: 0;
                width: 100%;

                @media (min-width: $screen-sm-min){
                    width: 100%;
                }

                @media (min-width: $screen-xl-min){
                    @include flex-basis(40%);
                    max-width: 30%;
                }

                .control,
                input{
                    height: 48px;
                    text-align: center;
                }

                 input {
                    @media (min-width: $screen-xl-min){
                        padding: 10px 0 10px 10px;
                    }
                 }
            }          

            .product-item-actions{
                @include flex-basis(auto);
                @include make-flex;
                @include justify-content(flex-start);
                margin-top: 12px;
                width: 100%;

                @media (min-width: $screen-xl-min){
                    @include flex-basis(60%);
                    @include justify-content(flex-end);
                    margin-top: 0;
                }

                .actions-primary {

                    button.action {
                        padding: 12px 10px 11px;
                        width: 100%;

                        &:lang(en) {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        .unavailable.stock {
            margin: 12px 3px 10px 8px;
            width: 100%;
            height: 46px;
            text-align: center;
            font-size: 15px;
            line-height: 3em;

            @media (max-width: $screen-sm-min - 1) {
                margin: 12px 0;
                text-align: center;                                   
                line-height: 3em;
            }
        }
    }
    .product-item-actions {
        margin: 8px 0;
        padding-top: 1px;
        display: block;
        float: right;

        .actions-primary{
            margin: 0;
            width: 100%
        }
    }

    @media (max-width: $screen-sm-min - 1) {
        .product-item-inner {
            position: relative;

            > .product-item-actions {
                position: relative;
                right: 0;
                bottom: 0;
                @include make-flex;
                @include justify-content(space-between);
                width: 100%;

                .action {
                    background-color: transparent;
                    font-size: 14px;
                    line-height: 36px;
                    color: $primary;
                    display: block;
                    width: 100%;
                    height: 36px;
                    text-align: center;
                    &:hover{
                        color: $accent;
                    }

                    + .action{
                        margin-left: 8px;
                        width: 80px;
                        float: right;
                    }
                }
            }
        }
    }

    a.action{
        &.edit,
        &.delete{
            font-size: 14px;
            line-height: 1.6;
            color: $text-lightest;
            &:hover{
                color: $primary;
            }
        }

        + a.action{
            margin-left: 30px;
            float: right;
        }
    }
}

.form-wishlist-items .actions-toolbar {
    margin-top: 10px;
    padding-top: 40px;
    border-top: 1px solid $border-color;
    @include make-flex;
    @include flex-direction(column);
    @include justify-content(space-between);
    @include align-items(flex-start);

    @media (min-width: $screen-sm-min) {
        @include flex-direction(row-reverse);
        @include justify-content(space-between);
        @include align-items(center);
    }

    .primary{
        @include make-flex;
        @include flex-direction(column-reverse);
        @include align-items(flex-start);
        margin-bottom: 10px;

        @media (max-width: $screen-sm-min - 1) {
            button {
                min-width: 178px;
            }
        }

        @media (min-width: $screen-sm-min) {
            @include flex-direction(row);
            @include align-items(stretch);
            margin-bottom: 0;
        }

        .action{
            display: block;

            @media (min-width: $screen-sm-min) {
                margin-bottom: 0;
                margin-left: 10px;
                height: 46px;
                line-height: 46px;
                text-align: center;
            }

            span {
                display: inline-block;
                vertical-align: top;
                line-height: normal;

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    line-height: 30px;
                }
            }

            +.action{
                margin-bottom: 10px;
                margin-left: 0;

                @media (min-width: $screen-sm-min) {
                    margin-bottom: 0;
                    margin-left: 10px;
                    height: 46px;
                    line-height: 46px;
                    text-align: center;
                }

                span {
                    display: inline-block;
                    vertical-align: top;
                    line-height: normal;

                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        line-height: 30px;
                    }
                }
            }
        }
    }
    .update{
        @include font-icon('\e92d');
    	&:before {
    		padding-right: 3px;
    	}
    }
    .share{
        @include font-icon('\eaa0');
    }
    .tocart{
        @include button-block__primary;
    }

}
