//
//  Main Styles
//  -------------------------------------------
@media (max-width: 767px) {
    .hide-mobile {
        display: none;
    }
}

@media (min-width: 1024px) {
    .hide-desktop {
        display: none;
    }
}

*{
    margin: 0;
}

html, body {
    height: 100%;
    font-family: $default-font-family;
    font-weight: $default-font-weight;
    font-size: $default-font-size;
    line-height: 1.5;
}

body{
    text-align: left;
    -webkit-text-size-adjust: none;
    color: $default;
    background: $white;

    @media (max-width: $screen-md-min - 1) {
        font-size: 12px;
        line-height: 18px;
    }
}

button.action{
    @include button-block; // _mixins.scss
    padding: 12px 15px;
    &.primary{
        @include button-block__primary; // _mixins.scss
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
         &.primary{
            @include button-block__primary; // _mixins.scss
            height: 44px;
            line-height: 32px;
        }
    }

    &.secondary{
        @include button-block__white;
    }

    &.accent,
    &.tocart,
    &#bundle-slide{
        @include button-block__accent;

        &:before{
            font-size: 20px;
            line-height: 20px;
            vertical-align: top;
            font-weight: normal;
            margin-right: 5px;
            @include font-icon__pseudo('\e74d', $secondary-icon-family);

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {                
                margin-right: 5px;
            }
        }
    }

    &.update {
        &:before{
            font-size: 15px;
            line-height: 20px;
            vertical-align: top;
            font-weight: normal;
            margin-right: 2px;        
        }
    }
}

button.action-dismiss{
    @include button-block; // _mixins.scss
}

button.action-accept{
    @include button-block;
    @include button-block__primary; // _mixins.scss
}

.actions-toolbar a.back{
    @include button-block__back; // _mixins.scss
}

::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 15px rgba(0,0,0,0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba($secondary-color,0.8);
    -webkit-box-shadow: inset 0 0 15px rgba($secondary-color,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
    background: rgba($secondary-color, .3);
}

//
//  Icons
//  ---------------------------------------------

.towishlist,
.tocompare{
    position: relative;
    span{
        display: none;
    }
}

.product-social-links .mailto{
    padding: 0 $indent-small/2;
    @include font-icon('\ea3a');
}
.towishlist {
    @include font-icon('\f08a');
    
    &:before {
        content: '\f08a';
        font-family: 'fontAwesome';
    }
}
.tocompare{
    @include font-icon('\e839');
    &:before {
        font-family: $secondary-icon-family;
    }
}

.translate-edit-icon {
    width: auto;
}

// Stack footer
.page-wrapper{
    min-height: 100%;
    overflow: hidden;
    position: relative;
    @include make-flex;
    @include flex-direction(column);
    @include align-items(stretch);
    @include justify-content(space-between);

    .rd-navbar {
        height: auto;
    }
}

//
//  Spinner
//  ---------------------------------------------

.filter,
.ajax-loading,
.checkout-index-index,
.slider-pro-wrapper{
    .loading-mask{
        background: rgba(255, 255, 255, 0.5);
        position: fixed;
        z-index: 999;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        margin: auto;

        img,
        p{
            display: none;
        }

        .loader,
        .loader:before,
        .loader:after {
            position: absolute;
            top: 50%;
            left: 50%;
            border: 1px solid $default;
            border-left-color: $primary;
            border-radius: 100px;
        }

        .loader {
            border-width: 2px;
            margin: -24px 0 0 -24px;
            height: 48px;
            width: 48px;
            animation: loader-spinner 1150ms linear infinite;
        }

        .loader:before {
            content: "";
            margin: -20px 0 0 -20px;
            height: 40px;
            width: 40px;
            animation: loader-spinner 1150ms linear infinite;
        }

        .loader:after {
            content: "";
            margin: -30px 0 0 -30px;
            height: 60px;
            width: 60px;
            animation: loader-spinner 2300ms linear infinite;
        }
    }
}
@keyframes loader-spinner {
    100% {
        transform: rotate(360deg);
    }
}

.slider-pro-wrapper .loading-mask.for-slider{
    position: absolute;
}

//
//  Header
//  ----------------------------------------

@mixin navigation-container(){
    min-width: $container-xs;
    max-width: $screen-xs-min;
    @media (min-width: $screen-sm-min) {
        max-width: $screen-sm-min;
    }
    @media (min-width: $screen-md-min) {
        max-width: $screen-md-min;
    }
    @media (min-width: $screen-lg-min) {
        max-width: $screen-lg-min;
    }
    @media (min-width: $screen-xl-min) {
        max-width: $screen-xl-min;
    }
    @media (min-width: $screen-xxl-min) {
        max-width: $screen-xxl-min;
    }
}

/*=====================================
/*
/*  Mega Menu switch off
/*
=====================================*/

.sm-header-nav-wrap.isStuck{
    top: 0 !important;
    left: 0 !important;
    margin: 0 !important;
}

.sm-header-nav-wrap {
    .close-main-menu {
        display: none;
    }

    &.active {
        .close-main-menu {
            display: block;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 340px;
            background: rgba(0,0,0,0.4);
            cursor: pointer;

            @media (max-width: 767px) {
                display: none;
            }

            @media (min-width: 1400px) {
                left: 400px;
            }
        }
    }
}

nav.navigation > ul:not(.ui-menu){
    display: none;
}

.language-mobile {
    padding-left: 15px;
    text-align: left;
    margin-top: 20px;

    .switcher-language {
        border: 1px solid $gray_7f;
        border-radius: 10px;
        width: auto;
        display: inline-block;
        padding: 10px;
        width: 125px;
        position: relative;

        .toggle{
            cursor: pointer;
            &:after{
                @include font-icon__pseudo('\e926');
                padding: 0 2px;
                font-size: 8px;
            }
            &.active{
                &:after{
                    @include font-icon__pseudo('\e929');
                }
            }
            &.active,
            &:hover{
                color: $primary;
            }
        }

        .switcher-options {
            position: absolute;
            right: 5px;

            .dropdown.switcher-dropdown {
                display: none;
                position: absolute;
                background: #fff;
                top: 100%;
                margin-top: 11px;
                border: 1px solid $gray_7f;
                border-radius: 10px;
                width: 124px;
                right: -5px;

                .switcher-option {
                    font-size: unset;
                    font-weight: normal;

                    a {
                        width: 100%;
                        padding: 5px 10px;
                        display: inline-block;
                    }
                }
            }

            &.active {
                .dropdown.switcher-dropdown {
                    display: block;
                }
            }
        }
    }
}


nav.navigation {
    display: flex;
    flex-direction: column;

    .header-logo {
        order: 1;
        padding: 10px 0 0;
        margin-bottom: 0.5em;

        img {
            max-width: 150px;
        }

        @media (max-width: 991px) {
            display: none;
        }
    }

    .language-mobile {
        order: 3;
    }

    > .ui-menu {
        order: 2;
    }
}

@media (min-width: $screen-md-min) {
    nav.navigation {
        position: fixed;
        top: 0;
        left: -340px;
        width: 340px;
        background: #fff;
        bottom: 0;
        z-index: 99;
        @include transition(left 0.3s ease);
        padding-left: 40px;

        @media (min-width: $screen-xl-min) {
            width: 400px;
            left: -400px;
            padding-left: 100px;
        }

        > .ui-menu {
            > li {
                display: inline-block;
                width: 100%;
                align-items: center;
                position: relative;

                > .ui-menu {
                    position: fixed !important;
                    left: auto !important;
                    top: 0 !important;
                    bottom: 0 !important;
                    overflow-y: auto;
                    margin-left: 300px;
                    z-index: 999;
                }

                > a {
                    line-height: 35px;

                    &.ui-state-focus, &.ui-state-active {
                        background: $third-color;
                    }
                }

                a {
                    &.ui-state-focus, &.ui-state-active {
                        font-weight: 600;
                    }
                }

                &.parent {
                    .ui-menu-icon {

                    }
                }
            }
        }

        li.level-top {

            > a {
                display: block;
                color: $text-dark;
                font-weight: 400;
                padding: 5px 15px 4px;
                font-size: 15px;
                text-transform: uppercase;

                &:lang(th) {
                    font-size: 16px;
                }

                .ui-menu-icon {
                    position: absolute;
                    right: 5px;
                    top: 5px;

                    &:after {
                        @include font-icon__pseudo('\e928');
                    }
                }
            }

            &.active > a,
            > .ui-state-active,
            > .ui-state-focus {
                color: $primary;
            }

            .ui-state-active,
            .ui-state-focus {
                color: $primary;
            }

            > ul:not(.megamenu-wrapper) {
                &,
                ul {
                    width: 270px;
                    background: $white;
                    font-size: 16px;
                    line-height: 24px;
                    padding: 10px 0;
                    box-shadow: none;

                    &:before {
                        content: '';
                        position: fixed;
                        width: 300px;
                        top: 0;
                        bottom: 0;
                        background: #fff;
                        -webkit-box-shadow: 0 2px 24px rgba(41, 41, 41, .22);
                        -moz-box-shadow: 0 2px 24px rgba(41, 41, 41, .22);
                        box-shadow: 0 2px 24px rgba(41, 41, 41, .22);
                    }

                    &.level1.submenu {
                        width: 330px;

                        &:before {
                            margin-left: 20px;
                            width: 310px;
                        }

                        > li {
                            padding-left: 50px;
                        }
                    }

                    ul {
                        position: fixed !important;
                        left: auto !important;
                        top: 0 !important;
                        bottom: 0 !important;
                        overflow-y: auto;
                        margin-left: 270px;
                    }

                    @media (min-width: $screen-lg-min) {
                        padding: 15px 0;
                    }

                    li {
                        display: block;
                        width: 100%;
                        padding: 0 30px;

                        + li {
                            margin-top: 10px;
                        }

                        &.parent > a:after {
                            @include font-icon__pseudo($icon: '\e928');
                            font-size: 8px;
                            line-height: inherit;
                            padding-left: 4px;
                        }

                        > a {
                            padding: 5px 0;
                            color: $primary;
                            &:hover {
                                color: $text-dark;
                            }
                        }
                    }
                }
            }
        }

        .level1 > ul:not(.megamenu-wrapper) {
            position: absolute !important;
            top: -10px !important;
            left: calc(100% + 1px) !important;
        }
    }

    .sm-header-nav-wrap.active {
        nav.navigation {
            left: 0;
        }
    }
}

@media (min-width: $screen-lg-min) {
    nav.navigation {
        .level1 > ul:not(.megamenu-wrapper) {
            top: -15px !important;
        }
    }
}

@media (max-width: $screen-md-min - 1) {
    .page-header {
    }

    .page-header__panel .welcome {
        display: none;
    }
}

@media (min-width: $screen-md-min) {
    .page-header__panel {
        padding: 5px 0 0;
        background: $bg-dark;
        color: $white;

        .container {
            display: flex;
            justify-content: space-between;
            .sm-header_customer-menu-container {
                order: 1
            }
            .greet.welcome {
                order: 3;
            }
            .header-switchers {
                order: 2;
            }
            .page-header__panel-left,
            .page-header__panel-right {
                @include make-flex;
            }
        }
    }

    .page-header__content {
        position: relative;

        .container {
            @media (min-width: $screen-xl-min) {
                max-width: $screen-xl-min;
            }
        }

        .top-container {
            @include make-flex;
            @include justify-content(space-between);
            @include align-items(center);

            .logo {
                margin: 5px 0 0 30px;
                display: block;
            }
        }
        .page-header__content-left {
            width: 150px;
        }

        .page-header__content-center {
            width: calc(100% - 600px);
            padding-right: 30px;
        }

        .page-header__content-logo {
            margin: 0 30px;
            flex: 1 1 auto;
            text-align: center;
            @include make-flex;
            @include justify-content(center);
        }

        .page-header__content-right {
            @include make-flex();
            @include justify-content(flex-end);
            @include align-items(center);
            margin-top: 3px;
            text-transform: uppercase;
        }

        .page-header__content-menu {
            @include make-flex;
            @include justify-content(space-between);
            border-top: 1px solid $dark-border-color;
            @media (min-width: $screen-md-min) {
                .sm-header-panel-canvas {
                    display: none;
                }
            }

            &.container {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .header.links {
            display: flex;
            flex-direction: row-reverse;
            align-items: baseline;
        }

        .desktop-stores-menu {
            @include desktop-top-link-icon(50px, url(../../images/icons/store-locator.png));
        }
    }

    .page-header {
        position: fixed !important;
        top: 0;
        left: 0;
        width: 100%;
        background: #fff;

        .page-header__sticky-wrapper.active {
            position: static;
        }
    }

    .page-wrapper {
        padding-top: 97px;
    }
}

@media (max-width: $screen-md-min - 1) {
    .page-header__content {
        padding: 32px 0;
        text-align: center;

        .page-header__content-left {
            .logo {
                position: fixed;
                top: 32px;
                left: 50px;
                right: 0;
                z-index: 17;
                text-align: left;

                img {
                    height: 42px;
                    width: auto;
                }
            }
        }

        .page-header__content-right {
            .header.links {
                display: none;
            }
        }
    }
}

//
//  Switchers
//  ---------------------------------------------

.header-switchers {
    @include clearfix;
}

.switcher-language {

    .switcher-option {
        font-size: 20px;
        font-weight: 700;

        &.active {
            color: $secondary-color;
        }

        &:not(:last-child) {
            &:after {
                content: '|';
                color: $primary;
                margin: 0 5px;
            }
        }
    }
}

.switcher-options {
    display: inline-block;
    perspective: 999px;
}

.links {
    li {
        display: inline-block;
    }
}

@media (min-width: $screen-md-min) {
    .header-switchers {
        margin-right: 40px;
    }
}

//
//  Navigation
//  ---------------------------------------------

.page-top{
    margin-bottom: $indent-base;
}

.page-header {
    position: relative;
    z-index: 100;
}

.page-header__sticky-wrapper {
    &.active {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;

        .page-header__content-menu-wrapper {
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
            -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
        }

        .page-header__content-menu {
            @include transition(all 0.5s ease);
            background: $white;
            border-top: none;
        }
    }
}

//
//  Breadcrumbs
//  ---------------------------------------------

.breadcrumbs {

    .container {
        @media (max-width: $screen-md-min - 1) {
            padding-left: 0;
            padding-right: 0;
        }

        @media (min-width: $screen-md-min) {
            margin-top: 5px;
        }
    }

    .items {
        background: $bg-light;
        padding: $indent-small;
        margin: 0 0 1.2rem;
        text-transform: uppercase;
        font-weight: 500;

        .item {
            display: inline-block;
            font-size: $default-font-size;
            margin-right: $indent-small;
            padding-right: $indent-small;
            position: relative;

            &:after {
                content: '>';
                position: absolute;
                right: -4px;
                top: 0;
                color: $primary;
            }

            &:last-child {
                margin-right: 0;
                padding-right: 0;

                &:after {
                    display: none;
                }
            }
        }
    }
}

//
//  Main content
//  --------------------------------------------

.page-main{
    margin-top: 40px;
    margin-bottom: 32px;
    @media (min-width: $screen-mob-min){
        margin-top: 11px;
        margin-bottom: 4px;
    }
}

//Page title
.page-title-wrapper{
    margin-bottom: 16px;
    @media (min-width: $screen-sm-min){
        margin-bottom: 24px;
    }
    @media (min-width: $screen-md-min){
        margin-bottom: 32px;
        margin-top: 15px;
    }

    .page-title{
        margin-bottom: 0;
    }
}

//
//  Page layouts
//  ---------------------------------------------

.columns > .row{
    @include make-flex();
    @include flex(0 1 auto);
    @include flex-direction(row);
    @include flex-wrap(wrap);
}

//Three columns
.page-layout-3columns{
    .grid-left{
        @include order(1);
    }
    .grid-main{
        @include order(2);
    }
    .grid-right{
        @include order(3);
    }
}

//Two columns with right aside
.page-layout-2columns-right{
    .grid-main{
        @include order(1);
    }
    .grid-right{
        @include order(2);
    }
}

//Two columns with left aside
.page-layout-2columns-left{
    .grid-left{
        @include order(1);
    }
    .grid-main{
        @include order(2);
    }
}
.page-layout-1column{

}

@media (max-width: $screen-mob-min - 1){
    .page-layout-3columns,
    .page-layout-2columns-right,
    .page-layout-2columns-left{
        .grid-left,
        .grid-main{
            @include flex-basis(100%);
        }.grid-main{
            @include order(1);
        }
        .grid-left{
            @include order(2);
            margin-top: $gutter-width;
        }
        .grid-right{
            @include order(3);
            margin-top: $gutter-width;
        }
    }
}

//
//  Blocks
//  ---------------------------------------------

.block-title{
    color: $text-dark;
    font-size: 20px;
    margin-bottom: 20px;
    letter-spacing: .04em;
    text-transform: uppercase;
    font-weight: 600;
    b {
        font-weight: 300;
    }
    @media (min-width: $screen-sm-min){
        font-size: 24px;
    }
    @media (min-width: $screen-md-min){
        font-size: 28px;
    }
    strong{
        width: 100%;
        display: inline-block;
    }
    .action{
        float: right;
        padding: 0;
        background: none;
        line-height: inherit;
        &.edit{
            padding: 5px;
        }
    }
    .counter{
        width: 100%;
        font-size: .8rem;
        line-height: 1;
        margin-top: 0;
    }
}
.block-subtitle{
    @extend .heading-4;
    display: block;
}

//
//  Boxes
//  ---------------------------------------------

.box{
    margin-bottom: $indent-small;
}
.box-title{
    margin-bottom: 16px;
    display: block;
    @extend .heading-4;
}

//
//  Footer
//  ---------------------------------------------

.page-footer{
    padding: 10px 0 0;
    line-height: 20px;
    .about-footer-text {
        margin-right: 11px;
    }
    ul.social-links.position-footer {
        display: block;
        text-align: left;

        li {
            display: inline-block;
            width: auto;
        }

        li + li {
            margin-left: 5px;

            @media (min-width: $screen-md-min) and (max-width: $screen-lg-min - 1) {
                margin-left: 2px;
            }
        }

        a {
            display: inline-block;
            width: 40px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            border-radius: 50px;
            color: $text-dark;
            background: $border-color;
            transition: .3s all ease;

            &:hover,
            &:focus {
                background: $primary;
                color: $white;
            }

            @media (min-width: $screen-lg-min) {
                width: 40px;
                height: 40px;
                line-height: 40px;
            }
            @media (min-width: $screen-md-min) and (max-width: $screen-lg-min - 1) {
                width: 35px;
                height: 35px;
                line-height: 35px;

                > .icon {
                    line-height: 35px !important;
                }
            }

        }
    }

    * + .social-links {
        margin-top: 13px;
    }
    > .footer {
        padding-bottom: 50px;
        border-top: 7px solid $secondary-color;
        padding-top: 15px;

        @media (min-width: $screen-md-min) {
            .row > div {
                position: relative;
                padding: 0;
            }
        }
        .subscribe-box {
            margin-bottom: 55px;
        }
    }
    .footer-main-row {
        margin-top: 30px;
        margin-left: 0;
        margin-right: 0;

        @media (min-width: $screen-md-min) {
            > div {
                &:not(:first-child) {
                padding-left: 20px;
            }

                &:not(:last-child) {
                    padding-right: 20px;
                    border-right: 1px solid $dark-border-color;
                }
            }
        }
    }
    h4, .newsletter .title{
        font-size: 16px;
        line-height: 24px;
        color: $secondary-color;
        text-transform: uppercase;
        letter-spacing: .04em;
    }
    h4{
        margin-bottom: 13px;
    }
    .newsletter .title{
        margin-bottom: 21px;
    }
    .facebook {
        .block-title {
            font-size: 16px;
            @media (max-width: $screen-md-min - 1) {
                font-size: 20px;
            }
        }
    }
    @media (max-width: $screen-lg-min - 1) {
        @media (min-width: $screen-md-min) {
            .footer-col {
                margin-bottom: 40px;
            }
        }
    }
    @media (max-width: $screen-lg-min - 1) {
        .order-md-4 {
            order: 5;
        }
        .order-md-5 {
            order: 4;
        }
    }
    .copyright {
        font-size: 14px;
    }
}

.footer-panel{
    padding: 15px 0;
    color: $text-dark;
    .container{
        @include make-flex;
        @include justify-content(space-between);
        @include align-items(center);
    }
    .list-payment {
        @include make-flex;
        li {
            margin: 0 5px;
            font-size: 30px;
            color: #bebebe;
        }
    }
}
.footer-contact-info {
    font-size: 14px;
    color: $text-dark;
    letter-spacing: .04em;
    li {
        margin-bottom: 30px;
    }
    .contact-info-title {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: .04em;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    span[class^="icon-"] {
        color: $primary;
        font-size: 18px;
        margin-right: 7px;
        position: relative;
        top: 2px;
    }
}
@media (min-width: $screen-md-min) {
    .footer-panel {
        .social-links {
            margin-left: 4.2vw;
            @include clearfix;

            li {
                float: left;
            }

            li + li {
                margin-left: 10px;
            }
        }
    }
}
@media (max-width: $screen-md-min - 1) {
    .footer-panel {
        .container {
            @include flex-direction(column);
            @include justify-content(flex-start);
            @include align-items(center);
        }

        .logo,
        .social-links {
            margin-bottom: 12px;
        }
    }
}

.footer-col{
    .footer-col-content{
        display: block;
    }


    .footer-col-about-wrapper & {
        h4 {
            font-size: 23px;
        }
        .footer-col-content {
            font-size: 19px;
        }
    }

    li{
        width: 100%;
        margin-bottom: 5px;
        font-size: 14px;
        a {
            color: $primary;
            &:hover {
                color: $text-dark;
            }
        }
    }

    [class*="store-info_"]{
        position: relative;
        padding-left: 24px;
        line-height: 14px;
        margin-bottom: 12px;
        &:before{
            position: absolute;
            top: 50%;
            left: 0;
            color: $primary;
            margin-top: -8px;
            font-size: 14px;
            line-height: 12px;
        }
    }
    .store-info_address{
        @include font-icon('\ec70');
        a{
            color: $default;
            &:hover{
                color: $primary;
            }
        }
    }
    .store-info_tel{
        @include font-icon('\ee89');
    }
    .store-info_time{
        @include font-icon('\eaae');
    }
    .store-info_email{
        @include font-icon('\ea3a');
    }
}

@media (max-width: $screen-md-min - 1) {
    .footer-col {
        padding: 16px 0;

        h4 {
            @include font-icon('\ea5a');
            cursor: pointer;
            font-size: 20px;
            position: relative;
            font-weight: 700;
            padding-right: 26px;
            margin-bottom: 0;

            &:before {
                position: absolute;
                top: 50%;
                right: 0;
                width: 26px;
                height: 26px;
                margin-top: -13px;
                border-radius: 2px;
                text-align: center;
                font-size: 16px;
                line-height: 1;
                padding: 6px 0;
                color: $primary;
            }

            &.active:before {
                content: '\ea5b';
            }
        }

        .footer-col-content {
            display: none;
            margin-top: 24px;
        }

        .footer-col-about-wrapper & {
            cursor: default;

            .footer-col-content {
                display: block;
                font-size: 16px;
            }

            h4:before {
                content: unset;
                display: none;
            }
        }
    }
}

//
//  To Top Button
//  ---------------------------------------------
.scrollToTop{
    position: fixed;
    z-index: 90;
    bottom: 25px;
    right: 55px;
    height: 65px;
    width: 65px;
    display: none;
    cursor: pointer;
    text-indent: 100vw;
    white-space: nowrap;
    overflow: hidden;
    transition: .3s;
    border: 0 solid transparent;
    background: $third-color;
    opacity: 0.6;
    border-radius: 50%;

    &.sticky {
        position: absolute;
        bottom: initial;
    }

    &:before{
        @include font-icon__pseudo('\e5ce', $material-icons);
        font-size: 60px;
        line-height: 1;
        text-indent: 0;
        width: 60px;
        height: 60px;
        position: absolute;
        top: 30%;
        left: 30%;
        margin-top: -17px;
        margin-left: -17px;
        color: $black;
        transition: $default-transition;
    }
    &:hover {
        opacity: 1;
    }
}

.roomvo-button {
    position: fixed;
    right: 42px;
    bottom: 90px;
    z-index: 999;
}

.product.media button.roomvo-stimr {
    background-color: white !important;
    font-size: 0 !important;
    height: 110px;
    margin-top: 25px;
}

.product.media button.roomvo-stimr:before {
    content: "";
    background: url("../../Extension_Roomvo/images/product-roomvo.png");
    width: 140px;
    height: 110px;
    position: absolute;
    vertical-align: top;
    bottom: 0;
    left: calc(50% - 70px);
}

.anycard-mobile {
    display: none;
}

@media only screen and (max-width: 767px) {
    .anycard-mobile {
        display: block;
        width: 83%;
        margin: 0 auto;
    }
}


//
//  Helpers
//  ---------------------------------------------
.no-display{
    display: none;
}
.fotorama--fullscreen {
    z-index: 99999 !important;
}

//
//  Section Divider OR
//  ---------------------------------------------

.section-divider-or {
    display: block;
    width: 100%;
    margin: $indent-base auto;
    text-align: center;
    overflow: hidden;

    &:before, &:after {
        content: "";
        width: 50%;
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px 0 -55%;
        border-bottom: 2px solid $border-color;
    }

    &:after {
        margin: 0 -55% 0 10px;
    }

    span {
        font-size: 18px;
        color: $text-lightest;
        display: inline-block;
        vertical-align: middle;
    }
}

//
//  Datepicker 
//  ---------------------------------------------

.ui-datepicker {
    background-color: $white;
    -webkit-box-shadow: 0 2px 24px rgba(41, 41, 41, .22);
    -moz-box-shadow: 0 2px 24px rgba(41, 41, 41, .22);
    box-shadow: 0 2px 24px rgba(41, 41, 41, .22);
    min-width: 260px;

    .ui-datepicker-title {
        .ui-datepicker-month,
        .ui-datepicker-year {
            display: inline-block;
            vertical-align: top;
            padding: 10px;
        }
    }

    .ui-datepicker-next {
        span {
            border-color: transparent transparent transparent $secondary-color;
        }
    }

    .ui-datepicker-prev {
        span {
            border-color: transparent $secondary-color transparent transparent;
        }
    }

    .ui-datepicker-calendar {
        th {
            background-color: $bg-light;
            border-color: $dark-border-color;
        }

        td {
            border-color: $dark-border-color;

            &.ui-state-disabled {
                background-color: $gray;

                span {
                    color: $unactive;
                }
            }

            .ui-state-active, .ui-state-hover {
                background-color: $third-color;
            }
        }
    }
}

.ui-datepicker-trigger {
    @include font-icon('\e789',$secondary-icon-family);
    font-size: 20px;

    span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}

//
//  Insufficient Stock Popup
//  ---------------------------------------------

.modal-popup.isp {
    .modal-header {
        padding: 0;
    }

    .modal-inner-wrap {
        width: 320px;
        max-width: calc(100% - 40px);

        .error-message {
            text-align: center;
        }
    }

    &._show .modal-inner-wrap {
        top: 22vh;
        position: absolute !important;
    }
}

//
//  Total Sold and Stock Available
//  ---------------------------------------------

.total-sold-and-stock {
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: left;
    font-size: 0.8em;
}

.total-sold-and-stock.one-item .available-stock {
    width: 100%;
}

.total-sold-and-stock.one-item .total-sold {
    width: 100%;
}

.total-sold-and-stock {
    .total-sold {
        width: 50%;
    }
}

.total-sold-and-stock {
    .available-stock {
        width: 50%;
    }
}


@import "_pages.scss";
@import "_ctis-package.scss";
@import "_look-marker.scss";